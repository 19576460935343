import React, { useEffect, useState } from 'react';
import FormField from './FormField';
import { FaFutbol, FaFileAlt, FaGlobe, FaLock, FaChevronUp, FaChevronDown, FaTimesCircle } from 'react-icons/fa';
import { Game } from '../models/games';
import LoadingButton from './LoadingButton';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import LocationPicker from './LocationPicker';
import TimePicker from './TimePicker';
import CoverImageSelector from './CoverImageSelector';

interface GameFormProps {
	initialGameData?: Game; // Optional for create, used for edit
	gameData: Partial<Game>;
	onInputChange: (field: keyof Game, value: any) => void;
	onSubmit: (gameData: Partial<Game>) => Promise<void>;
	onCancel: () => void;
	hideSubmit?: boolean; // Optional prop to hide the submit button
	hideTitle?: boolean; // Optional prop to hide the title field
	coverImage?: string;
	onCoverImageChange?: (url: string) => void;
}

const GameForm: React.FC<GameFormProps> = ({
	initialGameData,
	gameData,
	onInputChange,
	onSubmit,
	onCancel,
	hideSubmit,
	hideTitle,
	coverImage,
	onCoverImageChange,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitError, setSubmitError] = useState('');
	const [selectedTime, setSelectedTime] = useState<Date | null>(initialGameData ? new Date(initialGameData.scheduled_at) : new Date());
	const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

	const handleFormSubmit = async () => {
		setIsSubmitting(true);
		try {
			let combinedDate = gameData.scheduled_at ? new Date(gameData.scheduled_at) : new Date();
			if (gameData.scheduled_at && selectedTime) {
				combinedDate = new Date(
					Date.UTC(
						combinedDate.getFullYear(),
						combinedDate.getMonth(),
						combinedDate.getDate(),
						selectedTime.getHours(),
						selectedTime.getMinutes(),
						0,
						0
					)
				);
			}

			const updatedGameData: Partial<Game> = {
				...gameData,
				scheduled_at: combinedDate,
				cover_image: coverImage || gameData.cover_image,
			};

			await onSubmit(updatedGameData);
		} catch (error) {
			console.error('Failed to submit game:', error);
			setSubmitError('Failed to submit game. Please try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	// whenever selected time changes, update the game data
	useEffect(() => {
		if (!selectedTime) {
			return;
		}

		let combinedDate = gameData.scheduled_at ? new Date(gameData.scheduled_at) : new Date();
		if (selectedTime) {
			combinedDate = new Date(
				combinedDate.getFullYear(),
				combinedDate.getMonth(),
				combinedDate.getDate(),
				selectedTime.getHours(),
				selectedTime.getMinutes(),
				0,
				0
			);
		}

		onInputChange('scheduled_at', combinedDate);
	}, [selectedTime]);

	const css = `
    .rdp {
      --rdp-cell-size: 40px;
      --rdp-accent-color: rgb(79 70 229);
      --rdp-background-color: rgb(243 244 246);
      margin: 0;
      border: none !important;
    }
    .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
      background-color: rgb(243 244 246);
    }
    .rdp-day_selected {
      background-color: var(--rdp-accent-color);
    }
    .rdp-day_selected:hover {
      background-color: var(--rdp-accent-color);
    }
    .rdp-head_cell {
      font-size: 0.875rem;
      font-weight: 500;
      color: rgb(107 114 128);
      height: 40px;
    }
    .rdp-day {
      font-size: 0.875rem;
      color: rgb(55 65 81);
    }
    .rdp-nav_button {
      color: rgb(107 114 128);
    }
    .rdp-caption {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .rdp-caption_label {
      font-size: 1rem;
      font-weight: 600;
      color: rgb(31 41 55);
    }
  `;

	return (
		<div className="space-y-6">
			<style>{css}</style>

			{/* Cover Image */}
			<div className="space-y-3">
				<label className="block text-sm font-medium text-gray-900">Cover Image</label>
				<CoverImageSelector currentImage={coverImage} onChange={onCoverImageChange || (() => {})} />
			</div>

			{/* Game Name - Only show if not hidden */}
			{!hideTitle && (
				<div className="space-y-2">
					<label className="block text-sm font-medium text-gray-900">Title</label>
					<input
						type="text"
						className="block w-full px-4 py-3 text-base border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500"
						value={gameData.name || ''}
						onChange={(e) => onInputChange('name', e.target.value)}
						placeholder="Game Title"
					/>
				</div>
			)}

			{/* Location */}
			<div className="space-y-2">
				<label className="block text-sm font-medium text-gray-900">Location</label>
				<LocationPicker
					location={gameData.location || ''}
					setLocation={(loc) => onInputChange('location', loc)}
					setCoordinates={(coords) => {
						onInputChange('latitude', coords?.lat);
						onInputChange('longitude', coords?.lng);
					}}
				/>
			</div>

			{/* Game Description */}
			<div className="space-y-2">
				<label className="block text-sm font-medium text-gray-900">Description</label>
				<textarea
					className="block w-full px-4 py-3 text-base border-gray-300 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 resize-none"
					value={gameData.description || ''}
					onChange={(e) => onInputChange('description', e.target.value)}
					placeholder="Add a description for your game..."
					rows={4}
				/>
			</div>

			{/* Date and Time */}
			<div className="space-y-6">
				<div className="space-y-2">
					<label className="block text-sm font-medium text-gray-900">Date</label>
					<div className="bg-gray-50 rounded-lg p-4 flex justify-center">
						<DayPicker
							mode="single"
							selected={gameData.scheduled_at ? new Date(gameData.scheduled_at) : new Date()}
							onSelect={(date: Date) => {
								let combinedDate = new Date(date);
								if (selectedTime) {
									combinedDate = new Date(
										combinedDate.getFullYear(),
										combinedDate.getMonth(),
										combinedDate.getDate(),
										selectedTime.getHours(),
										selectedTime.getMinutes(),
										0,
										0
									);
								}
								onInputChange('scheduled_at', combinedDate);
							}}
							showOutsideDays
							className="w-full max-w-sm"
						/>
					</div>
				</div>

				<div className="space-y-2">
					<label className="block text-sm font-medium text-gray-900">Time</label>
					<TimePicker selectedTime={selectedTime} onChange={setSelectedTime} />
				</div>
			</div>

			{/* Error Message */}
			{submitError && <div className="px-4 py-3 text-sm text-red-600 bg-red-50 rounded-lg">{submitError}</div>}

			{/* Submit and Cancel Buttons */}
			{!hideSubmit && (
				<div className="flex flex-col space-y-4 mt-6">
					<LoadingButton
						isLoading={isSubmitting}
						onClick={handleFormSubmit}
						aria-label="Submitting..."
						disabled={isSubmitting}
						buttonClass={`w-full ${
							isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
						} text-white px-6 py-3 rounded-xl font-medium transition-colors shadow-sm flex items-center justify-center gap-2`}
					>
						{initialGameData ? 'Save Changes' : 'Create Game'}
					</LoadingButton>

					{initialGameData && (
						<button
							onClick={() => setShowCancelConfirm(true)}
							className="w-full bg-white text-rose-500 px-6 py-3 rounded-xl font-medium hover:bg-rose-50 transition-colors border border-gray-200 shadow-sm flex items-center justify-center gap-2"
						>
							<FaTimesCircle className="w-5 h-5" />
							Cancel Game
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default GameForm;
