import React, { useState } from 'react';
import { FaEnvelope, FaCheck, FaCopy } from 'react-icons/fa';

const CopyableEmail: React.FC<{ email: string }> = ({ email }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(email);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="bg-gray-50 p-4 rounded-lg group">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <FaEnvelope className="text-gray-600 mr-2" />
          <span className="text-gray-800">{email}</span>
        </div>
        <button
          onClick={handleCopy}
          className="ml-2 p-2 rounded-md hover:bg-gray-200 transition-all duration-200 relative"
          title="Copy email"
        >
          <div className={`transform transition-all duration-200 ${copied ? 'scale-0' : 'scale-100'}`}>
            <FaCopy className="text-gray-500" />
          </div>
          <div className={`absolute inset-0 flex items-center justify-center transform transition-all duration-200 ${copied ? 'scale-100' : 'scale-0'}`}>
            <FaCheck className="text-green-500" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default CopyableEmail;
