import React, { useState } from 'react';
import { FaUpload } from 'react-icons/fa';

interface ImageUploaderProps {
  onUploadSuccess: (url: string) => void;
  onUploadError: (error: string) => void;
  uploadFunction: (file: File) => Promise<string>;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onUploadSuccess, onUploadError, uploadFunction }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setIsUploading(true);
    try {
      const url = await uploadFunction(selectedFile);
      onUploadSuccess(url);
    } catch (error: any) {
      onUploadError(error.message || 'Failed to upload image. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="flex items-center">
      <label className="flex items-center cursor-pointer">
        <FaUpload className="text-gray-400 mr-2" />
        <span className="text-indigo-600 hover:underline">
          {selectedFile ? selectedFile.name : 'Choose Image'}
        </span>
        <input type="file" accept="image/*" onChange={handleFileChange} className="hidden" />
      </label>
      <button
        onClick={handleUpload}
        disabled={!selectedFile || isUploading}
        className={`ml-4 bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-full transition duration-300 ${
          (!selectedFile || isUploading) && 'opacity-50 cursor-not-allowed'
        }`}
      >
        {isUploading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  );
};

export default ImageUploader;