import api from './api';
import { NotificationType } from './notification_websocket';

export interface NotificationResponse {
    notifications: Notification[];
    next_page: string | null;
    has_more: boolean;
}

export interface Notification {
    notification_id: string;
    user_id: string;
    notification_time: Date;
    type: NotificationType;
    source_id: string;
    content: string;
    read: boolean;
    priority: number;
    expires_at: Date;
}

export class NotificationAPI {
    static async fetchUserNotifications(options: { 
        page_state?: string; 
        limit?: number;
    } = {}): Promise<NotificationResponse> {
        try {
            const { page_state, limit = 20 } = options;
            const queryParams = new URLSearchParams();
            
            if (page_state) {
                queryParams.append('page_state', page_state);
            }
            queryParams.append('limit', limit.toString());

            const response = await api.get(`/api/v1/notifications/user?${queryParams}`);
            return this.transformNotificationResponse(response.data);
        } catch (error) {
            console.error('Failed to fetch user notifications:', error);
            throw new Error('Failed to fetch user notifications');
        }
    }

    static async fetchUnreadNotifications(options: { 
        page_state?: string; 
        limit?: number;
    } = {}): Promise<NotificationResponse> {
        try {
            const { page_state, limit = 20 } = options;
            const queryParams = new URLSearchParams();
            
            if (page_state) {
                queryParams.append('page_state', page_state);
            }
            queryParams.append('limit', limit.toString());

            const response = await api.get(`/api/v1/notifications/unread?${queryParams}`);
            return this.transformNotificationResponse(response.data);
        } catch (error) {
            console.error('Failed to fetch unread notifications:', error);
            throw new Error('Failed to fetch unread notifications');
        }
    }

    static async markAsRead(notificationId: string): Promise<void> {
        try {
            await api.post(`/api/v1/notifications/read?notification_id=${notificationId}`);
        } catch (error) {
            console.error('Failed to mark notification as read:', error);
            throw new Error('Failed to mark notification as read');
        }
    }

    static async deleteNotification(notificationId: string): Promise<void> {
        try {
            await api.delete(`/api/v1/notifications?notification_id=${notificationId}`);
        } catch (error) {
            console.error('Failed to delete notification:', error);
            throw new Error('Failed to delete notification');
        }
    }

    private static transformNotificationResponse(data: any): NotificationResponse {
        return {
            notifications: data.notifications.map((n: any) => ({
                ...n,
                notification_time: new Date(n.notification_time),
                expires_at: new Date(n.expires_at)
            })),
            next_page: data.next_page,
            has_more: data.has_more
        };
    }
}
