import { useEffect, useState, useCallback, useRef } from 'react';
import { User, FriendRequest } from '../models/user';
import { retryWithBackoff } from '../utils/retry';
import { useAuth } from './useAuth';

interface FriendRequestWithUser extends FriendRequest {
  user: User;
}

const CACHE_KEY = 'friendRequestsCache';
const CACHE_DURATION = 300000; // 5 minutes cache

interface CachedRequests {
  incomingRequests: FriendRequestWithUser[];
  sentRequests: FriendRequestWithUser[];
  timestamp: number;
}

interface RequestsState {
  incomingRequests: FriendRequestWithUser[];
  sentRequests: FriendRequestWithUser[];
}

// Global state to ensure consistency across hook instances
let globalRequestsState: RequestsState | null = null;

const loadFromCache = (): CachedRequests | null => {
  try {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const cache = JSON.parse(cachedData);
      if (Date.now() - cache.timestamp < CACHE_DURATION) {
        // Update global state when loading from cache
        globalRequestsState = {
          incomingRequests: cache.incomingRequests,
          sentRequests: cache.sentRequests
        };
        return cache;
      }
    }
  } catch (error) {
    console.error('Failed to load friend requests from cache:', error);
  }
  return null;
};

const saveToCache = (data: CachedRequests) => {
  try {
    const cacheData = {
      ...data,
      timestamp: Date.now()
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
    // Update global state when saving to cache
    globalRequestsState = {
      incomingRequests: data.incomingRequests,
      sentRequests: data.sentRequests
    };
  } catch (error) {
    console.error('Failed to save friend requests to cache:', error);
  }
};

export function useFriendRequests() {
  const { isAuthenticated } = useAuth();
  // Initialize from global state first, then cache
  const cachedData = globalRequestsState ? { ...globalRequestsState, timestamp: Date.now() } : loadFromCache();
  const [requestsState, setRequestsState] = useState<RequestsState>({
    incomingRequests: cachedData?.incomingRequests || [],
    sentRequests: cachedData?.sentRequests || []
  });
  const [loading, setLoading] = useState<boolean>(!cachedData);
  const [error, setError] = useState<string | null>(null);
  const lastFetchTimestampRef = useRef<number | null>(cachedData?.timestamp || null);

  const loadUserInfo = useCallback(async (userId: string): Promise<User> => {
    return retryWithBackoff(async () => {
      try {
        return await User.getUserById(userId, false);
      } catch (error) {
        console.error('Failed to load user info:', error);
        throw error;
      }
    });
  }, []);

  const updateRequestsState = useCallback((newState: RequestsState) => {
    // Update both local and global state
    globalRequestsState = newState;
    setRequestsState(newState);
  }, []);

  const fetchFriendRequests = useCallback(async (force: boolean = false) => {
    // Skip if not authenticated
    if (!isAuthenticated) {
      setLoading(false);
      setError(null);
      updateRequestsState({ incomingRequests: [], sentRequests: [] });
      return;
    }

    const now = Date.now();
    if (!force && lastFetchTimestampRef.current && (now - lastFetchTimestampRef.current) < CACHE_DURATION) {
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const [requests, sentRequestsList] = await retryWithBackoff(async () => {
        const [reqs, sentReqs] = await Promise.all([
          User.getFriendRequests(),
          User.getSentFriendRequests()
        ]);
        return [reqs || [], sentReqs || []];
      });

      // If both lists are empty, update state and cache immediately
      if (requests.length === 0 && sentRequestsList.length === 0) {
        const newState = { incomingRequests: [], sentRequests: [] };
        updateRequestsState(newState);
        saveToCache({
          ...newState,
          timestamp: now
        });
        lastFetchTimestampRef.current = now;
        return;
      }

      // Load user info for all requests
      const userPromises = new Set<string>();
      requests.forEach(req => userPromises.add(req.requester_id));
      sentRequestsList.forEach(req => userPromises.add(req.recipient_id));

      const userInfoPromises = Array.from(userPromises).map(userId => loadUserInfo(userId));
      const users = await Promise.all(userInfoPromises);
      const userMap = new Map(users.map(user => [user.user_id, user]));

      // Prepare both states together
      const incomingWithUsers = requests.map(req => ({
        ...req,
        user: userMap.get(req.requester_id)!
      }));

      const sentWithUsers = sentRequestsList.map(req => ({
        ...req,
        user: userMap.get(req.recipient_id)!
      }));

      // Update both states atomically
      const newState = {
        incomingRequests: incomingWithUsers,
        sentRequests: sentWithUsers
      };

      updateRequestsState(newState);
      saveToCache({
        ...newState,
        timestamp: now
      });
      lastFetchTimestampRef.current = now;
      setError(null);
    } catch (error) {
      console.error('Error fetching friend requests:', error);
      setError('Failed to load friend requests');
    } finally {
      setLoading(false);
    }
  }, [loadUserInfo, updateRequestsState, isAuthenticated]);

  useEffect(() => {
    let isSubscribed = true;

    const updateRequests = async () => {
      if (!isSubscribed) return;
      await fetchFriendRequests(!lastFetchTimestampRef.current);
    };

    // Sync with global state if it exists and is different
    if (globalRequestsState && 
        (globalRequestsState.incomingRequests !== requestsState.incomingRequests ||
         globalRequestsState.sentRequests !== requestsState.sentRequests)) {
      updateRequestsState(globalRequestsState);
    } else {
      updateRequests();
    }

    return () => {
      isSubscribed = false;
    };
  }, [fetchFriendRequests, updateRequestsState, requestsState]);

  const refresh = useCallback(() => {
    return fetchFriendRequests(true);
  }, [fetchFriendRequests]);

  return {
    incomingRequests: requestsState.incomingRequests,
    sentRequests: requestsState.sentRequests,
    loading,
    error,
    refresh
  };
}