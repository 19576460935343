import React, { useEffect, useRef, useState } from 'react';

interface FrostedImageContainerProps {
  src: string;
  alt: string;
  className?: string;
}

const FrostedImageContainer: React.FC<FrostedImageContainerProps> = ({ src, alt, className = '' }) => {
  const [aspectRatio, setAspectRatio] = useState(1);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setAspectRatio(img.width / img.height);
    };
    img.src = src;
  }, [src]);

  const containerStyle = {
    '--image-bg': `url(${src})`,
  } as React.CSSProperties;

  return (
    <div 
      className={`relative w-full h-full select-none ${className}`}
      style={containerStyle}
    >
      {/* Left background extension */}
      {aspectRatio < 1 && (
        <div 
          className="absolute inset-y-0 right-full w-full select-none"
          style={{
            backgroundImage: 'var(--image-bg)',
            backgroundPosition: 'right center',
            backgroundSize: 'cover',
            filter: 'blur(20px) brightness(0.9)',
            transform: 'scale(1.1)',
            userSelect: 'none',
          }}
        />
      )}

      {/* Right background extension */}
      {aspectRatio < 1 && (
        <div 
          className="absolute inset-y-0 left-full w-full select-none"
          style={{
            backgroundImage: 'var(--image-bg)',
            backgroundPosition: 'left center',
            backgroundSize: 'cover',
            filter: 'blur(20px) brightness(0.9)',
            transform: 'scale(1.1)',
            userSelect: 'none',
          }}
        />
      )}

      {/* Main image container */}
      <div className="relative w-full h-full overflow-hidden">
        {/* Background blur */}
        <div 
          className="absolute inset-0 scale-110 select-none"
          style={{
            backgroundImage: 'var(--image-bg)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            filter: 'blur(20px) brightness(0.9)',
            userSelect: 'none',
          }}
        />
        
        {/* Main image */}
        <img
          ref={imgRef}
          src={src}
          alt={alt}
          className="relative w-full h-full object-contain"
        />
      </div>
    </div>
  );
};

export default FrostedImageContainer;
