import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ChatList, { ChatListProps } from '../ChatList';
import ChatInterface from '../ChatInterface';
import { User } from '../../models/user';
import { FaArrowLeft } from 'react-icons/fa';

interface MobileChatLayoutProps {
  chatrooms: any[];
  loading: boolean;
  error: Error | null;
  currentUserProfile: User;
  otherProfiles: { [key: string]: User };
  chatroom: any;
  chatroomLoading: boolean;
  chatroomError: Error | null;
}

const MobileChatLayout: React.FC<MobileChatLayoutProps> = ({
  chatrooms,
  loading,
  error,
  currentUserProfile,
  otherProfiles,
  chatroom,
  chatroomLoading,
  chatroomError,
}) => {
  const { chatroomId } = useParams<{ chatroomId?: string }>();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col bg-white" style={{ height: 'calc(100vh - 64px)' }}>
      {chatroomId ? (
        <div className="flex flex-col h-full">
          <div className="fixed top-0 left-0 right-0 z-10 flex items-center p-4 bg-white border-b border-gray-200 shadow-sm">
            <button
              onClick={() => navigate('/chat')}
              className="p-2 -ml-2 mr-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
              aria-label="Back to chat list"
            >
              <FaArrowLeft className="text-gray-600 w-5 h-5" />
            </button>
            <h1 className="text-lg font-semibold text-gray-900 truncate flex-1">
              {chatroom?.name || 'Chat'}
            </h1>
          </div>
          <div className="h-16"></div>
          <div className="flex-1 min-h-0 bg-gray-50">
            {chatroomLoading ? (
              <div className="flex justify-center items-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
              </div>
            ) : chatroomError ? (
              <div className="text-red-500 text-center p-4">
                {chatroomError.message}
              </div>
            ) : (
              chatroom && (
                <ChatInterface
                  chatRoomId={chatroom.chatroom_id}
                  chatRoomName={chatroom.name}
                  currentUser={{
                    id: currentUserProfile.user_id,
                    name: currentUserProfile.username,
                  }}
                  otherProfiles={otherProfiles}
                  chatroomType={chatroom.chatroom_type}
                  participants={Object.keys(chatroom.members)}
                />
              )
            )}
          </div>
        </div>
      ) : (
        <div className="flex-1 overflow-hidden">
          <ChatList
            chatrooms={chatrooms}
            loading={loading}
            error={error}
            currentUserProfile={currentUserProfile}
            otherProfiles={otherProfiles}
            className="w-full overflow-y-auto"
            style={{ height: 'calc(100vh - 64px)' }}
          />
        </div>
      )}
    </div>
  );
};

export default MobileChatLayout;
