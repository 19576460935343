/**
 * useOtherUserProfile Hook
 *
 * This hook manages other users' profile data with caching and automatic updates.
 * Similar to useUserProfile but for viewing other users' profiles.
 *
 * Functionality:
 * 1. Caches user profile data in localStorage with a 24-hour expiry
 * 2. Automatically loads user profile on initial mount
 * 3. Provides a function to manually reload the user profile
 * 4. Manages loading state during profile fetching
 *
 * Usage:
 * const { userProfile, isLoading, error } = useOtherUserProfile(userId);
 *
 * - userProfile: The user profile data (null if not loaded)
 * - isLoading: Boolean indicating if the profile is being fetched
 * - error: Error object if the fetch failed
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, a new profile is fetched from the server
 */

import { useState, useEffect, useCallback, useRef } from 'react';
import { User } from '../models/user';

export const useOtherUserProfile = (userId: string | undefined | null, isAuthenticated?: boolean) => {
  const CACHE_KEY = userId ? `userProfileCache_${userId}` : null;
  const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours
  const BACKGROUND_UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes

  const loadFromLocalStorage = (userId?: string | null): { data: User | null; lastUpdated: number } => {
    if (!userId) return { data: null, lastUpdated: 0 };
    
    const CACHE_KEY = `userProfileCache_${userId}`;
    try {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const { data, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_EXPIRY_TIME) {
          return { data: data as User, lastUpdated: timestamp };
        } else {
          localStorage.removeItem(CACHE_KEY);
        }
      }
    } catch (error) {
      console.error('Failed to load user profile from localStorage:', error);
    }
    return { data: null, lastUpdated: 0 };
  };

  const [userProfileData, setUserProfileData] = useState<{ data: User | null; lastUpdated: number }>(() => {
    // Try to load from localStorage first
    return loadFromLocalStorage(userId);
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { data: userProfile, lastUpdated } = userProfileData;

  // Add a ref to track if the component is mounted
  const isMounted = useRef(true);
  // Add a ref to track the last fetch time
  const lastFetchTime = useRef<number>(0);
  // Add debounce timeout ref
  const debounceTimeout = useRef<NodeJS.Timeout>();

  // Clear the timeout on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  const saveToLocalStorage = (data: User) => {
    if (!CACHE_KEY) return;
    
    try {
      localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: Date.now() }));
    } catch (error) {
      console.error('Failed to save user profile to localStorage:', error);
    }
  };

  const fetchUserProfile = useCallback(async (showLoading = true) => {
    if (!userId) return null;
    
    // Implement rate limiting - only fetch if it's been more than 5 seconds since the last fetch
    const now = Date.now();
    if (now - lastFetchTime.current < 5000) {
      return userProfile;
    }
    
    if (showLoading) {
      setIsLoading(true);
    }
    
    try {
      lastFetchTime.current = now;
      const userData = isAuthenticated 
        ? await User.getUserById(userId)
        : await User.getUserByIdPublic(userId);
      
      if (!isMounted.current) return null;
      
      saveToLocalStorage(userData);
      setUserProfileData({ data: userData, lastUpdated: now });
      setError(null);
      return userData;
    } catch (error) {
      if (!isMounted.current) return null;
      
      console.error('Error fetching user profile:', error);
      setError(error as Error);
      throw error;
    } finally {
      if (showLoading && isMounted.current) {
        setIsLoading(false);
      }
    }
  }, [userId, userProfile, isAuthenticated]);

  // Initial load and background updates
  useEffect(() => {
    if (!userId) {
      setUserProfileData({ data: null, lastUpdated: 0 });
      setIsLoading(false);
      return;
    }

    // Clear any existing debounce timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // If we don't have cached data or it's stale, fetch after a debounce delay
    if (!userProfile || Date.now() - lastUpdated > CACHE_EXPIRY_TIME) {
      debounceTimeout.current = setTimeout(() => {
        if (isMounted.current) {
          fetchUserProfile();
        }
      }, 300); // 300ms debounce
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [userId, userProfile, lastUpdated, fetchUserProfile]);

  return {
    userProfile,
    isLoading,
    error,
    refreshProfile: () => fetchUserProfile(true),
    lastUpdated
  };
};
