import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPaperPlane, FaSearch, FaTimes } from 'react-icons/fa';
import PrivacyPolicy from '../constants/PrivacyPolicy';
import TermsAndConditions from '../constants/TermsAndConditions';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-[90%] md:max-w-[80%] lg:max-w-[70%] xl:max-w-[60%] mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="text-gray-600">
          {children}
        </div>
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public';
  }, []);

  return (
    <div className="fixed inset-0 select-none">
      <div className="relative h-full w-full bg-gray-900">
        {/* Loading background */}
        <div className={`absolute inset-0 bg-gray-900 transition-opacity duration-500 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
        
        {/* Main image */}
        <img
          src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public"
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'} select-none pointer-events-none`}
          alt="Night soccer game under floodlights"
          draggable="false"
          onContextMenu={(e) => e.preventDefault()}
        />
        <div className="absolute inset-0 bg-black/30" />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white p-6">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 drop-shadow-lg select-none">
            Play More, Search Less
          </h1>
          <p className="text-xl mb-8 max-w-2xl text-white drop-shadow-lg select-none">
            Join pickup soccer games in your area
          </p>
          <button
            onClick={() => navigate('/dashboard')}
            className="bg-rose-500 text-white md:py-3 md:px-6 py-3 px-4 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 md:text-lg text-base font-medium flex items-center space-x-2 select-none"
          >
            <FaPaperPlane className="text-lg font-medium" height={22} width={22} />
            <span className="select-none">Find Games Near You</span>
          </button>
        </div>

        {/* Footer Links */}
        <div className="absolute bottom-4 right-4 text-xs text-gray-500 space-x-4">
          <button 
            onClick={() => setPrivacyModalOpen(true)}
            className="hover:text-white/80 transition-colors"
          >
            Privacy Policy
          </button>
          <button 
            onClick={() => setTermsModalOpen(true)}
            className="hover:text-white/80 transition-colors"
          >
            Terms and Conditions
          </button>
        </div>
      </div>

      {/* Modals */}
      <Modal
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        title="Privacy Policy"
      >
        <div className="max-h-[70vh] overflow-y-auto">
          <PrivacyPolicy />
        </div>
      </Modal>

      <Modal
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        title="Terms & Conditions"
      >
        <div className="max-h-[70vh] overflow-y-auto">
          <TermsAndConditions />
        </div>
      </Modal>
    </div>
  );
};

export default Home;
