import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig, AxiosRequestHeaders } from 'axios';

class ApiClient {
  public api: AxiosInstance;
  private refreshApi: AxiosInstance;
  private accessToken: string | null;
  private refreshToken: string | null;
  private refreshTokenPromise: Promise<string | null> | null = null;
  private tokenExpirationTime: number | null = null;

  constructor() {
    // Initialize tokens from localStorage
    this.accessToken = localStorage.getItem('accessToken');
    this.refreshToken = localStorage.getItem('refreshToken');
    this.initializeTokenExpiration();

    // Create Axios instances
    this.api = axios.create({
      baseURL: 'https://kickoff.game',
    });

    this.refreshApi = axios.create({
      baseURL: 'https://kickoff.game',
    });

    // Set up request interceptor for auth
    this.api.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        // Always ensure headers exist
        if (!config.headers) {
          config.headers = new axios.AxiosHeaders();
        }
        
        // Check if token is expired before making request
        if (this.isTokenExpired() && this.refreshToken && !config.url?.includes('/api/auth/refresh')) {
          const newToken = await this.refreshTokenRequest();
          if (newToken) {
            config.headers['Authorization'] = `Bearer ${newToken}`;
          }
        } else if (this.accessToken && !config.url?.includes('/api/auth/refresh')) {
          config.headers['Authorization'] = `Bearer ${this.accessToken}`;
        }
        
        return config;
      },
      (error) => Promise.reject(error)
    );
    
    // Response interceptor for token refresh
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // If error is 401 and we haven't retried and it's not a refresh request
        if (
          error.response?.status === 401 && 
          !originalRequest._retry && 
          !originalRequest.url?.includes('/api/auth/refresh') &&
          this.refreshToken
        ) {
          originalRequest._retry = true;

          try {
            // Get a new token using the refresh queue
            const newToken = await this.refreshTokenRequest();
            if (newToken) {
              // Update the Authorization header with the new token
              originalRequest.headers = originalRequest.headers || {};
              originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
              // Retry the original request
              return this.api(originalRequest);
            }
          } catch (refreshError) {
            this.handleAuthFailure();
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private initializeTokenExpiration() {
    try {
      const token = this.accessToken;
      if (token) {
        const payload = JSON.parse(atob(token.split('.')[1]));
        if (payload.exp) {
          this.tokenExpirationTime = payload.exp * 1000; // Convert to milliseconds
        }
      }
    } catch (error) {
      console.error('Error parsing token:', error);
      this.tokenExpirationTime = null;
    }
  }

  private isTokenExpired(): boolean {
    if (!this.tokenExpirationTime) return true;
    // Refresh token if it expires in less than 30 seconds
    return this.tokenExpirationTime - Date.now() < 30000;
  }

  private async refreshTokenRequest(): Promise<string | null> {
    // If there's already a refresh in progress, return that promise
    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise;
    }

    // Create a new refresh promise
    this.refreshTokenPromise = (async () => {
      try {
        const response = await this.refreshApi.post('/api/auth/refresh', null, {
          headers: { 
            'X-Refresh-Token': this.refreshToken || '',
          },
        });

        if (response.status === 200) {
          // Get headers case-insensitively
          const headers = response.headers;
          const newAccessToken = headers['x-access-token'] || headers['X-Access-Token'];
          const newRefreshToken = headers['x-refresh-token'] || headers['X-Refresh-Token'];

          if (!newAccessToken || !newRefreshToken) {
            throw new Error('Invalid token response from server');
          }

          this.setAccessToken(newAccessToken);
          this.setRefreshToken(newRefreshToken);
          this.initializeTokenExpiration();

          return newAccessToken;
        } else {
          this.handleAuthFailure();
          return null;
        }
      } catch (error) {
        // Only clear tokens and redirect if refresh token is invalid
        if (error.response?.status === 401) {
          this.handleAuthFailure();
        }
        throw error;
      } finally {
        // Clear the promise so future refreshes can occur
        this.refreshTokenPromise = null;
      }
    })();

    return this.refreshTokenPromise;
  }

  public setAccessToken(token: string | null) {
    this.accessToken = token;
    if (token) {
      localStorage.setItem('accessToken', token);
      this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('accessToken');
      delete this.api.defaults.headers.common['Authorization'];
    }
  }

  public setRefreshToken(token: string | null) {
    this.refreshToken = token;
    if (token) {
      localStorage.setItem('refreshToken', token);
    } else {
      localStorage.removeItem('refreshToken');
    }
  }

  private handleAuthFailure() {
    this.setAccessToken(null);
    this.setRefreshToken(null);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }

  public get instance(): AxiosInstance {
    return this.api;
  }
}

const apiClient = new ApiClient();

export default apiClient.api;
export { apiClient };
