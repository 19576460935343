import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaTimes, FaHome } from 'react-icons/fa';
import Turnstile from '../components/Turnstile';
import RedirectIfAuthenticated from '../components/RedirectIfAuthenticated';
import { clearAllState } from '../utils/clearState';
import api, { apiClient } from '../models/api';

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
			<div className="bg-white rounded-lg p-6 w-full max-w-[90%] md:max-w-[80%] lg:max-w-[70%] xl:max-w-[60%] mx-4">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-semibold">{title}</h2>
					<button onClick={onClose} className="text-gray-500 hover:text-gray-700">
						<FaTimes />
					</button>
				</div>
				<div className="text-gray-600">{children}</div>
			</div>
		</div>
	);
};

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [turnstileResetKey, setTurnstileResetKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  // Get the reset code from URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const resetCode = searchParams.get('code');

  // Show error message if no reset code
  useEffect(() => {
    if (!resetCode) {
      setErrorMessage('Invalid password reset link. Please request a new one.');
    } else {
      setErrorMessage('');
    }
  }, [resetCode]);

  // Disable scrolling when component mounts
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public';
  }, []);

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return 'Password must contain at least one special character (!@#$%^&*)';
    }
    return null;
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (isSubmitting) return;
    
    setErrorMessage('');
    setPasswordError('');
    setConfirmPasswordError('');

    if (!turnstileToken) {
      setErrorMessage('Please complete the Turnstile challenge.');
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      return;
    }

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await api.post(
        '/api/auth/reset-password',
        {
          token: resetCode,
          password,
        },
        {
          headers: {
            'cf-turnstile-response': turnstileToken,
          },
        }
      );

      if (!response.data) {
        throw new Error('No response from server.');
      }

      // Clear any existing tokens/session
      clearAllState();
      apiClient.setAccessToken(null);
      apiClient.setRefreshToken(null);

      setSuccessMessage('Password has been reset successfully. Redirecting to login...');
      setTimeout(() => {
        window.location.href = '/signin';
      }, 3000);
    } catch (error: any) {
      if (error.response?.status === 429) {
        setErrorMessage('Too many attempts. Please try again in a minute.');
      } else if (error.response?.status === 400) {
        setErrorMessage('Invalid or expired reset link. Please request a new one.');
      } else if (error.response?.status === 401) {
        setErrorMessage('Invalid reset link. Please request a new one.');
        clearAllState();
        apiClient.setAccessToken(null);
        apiClient.setRefreshToken(null);
      } else {
        setErrorMessage(error.message || 'An unexpected error occurred. Please try again.');
      }
      setTurnstileToken(null);
      setTurnstileResetKey((prevKey) => prevKey + 1);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };

  return (
    <RedirectIfAuthenticated>
      <div className="h-screen w-screen flex flex-col overflow-hidden bg-gray-900">
        <div className="flex-1 overflow-y-auto">
          {/* Page Background */}
          <div className="h-screen overflow-hidden bg-gray-900 flex items-center justify-center px-4 sm:px-6 lg:px-8 relative">
            {/* Home Icon */}
            <button
              onClick={() => window.location.href = '/'}
              className="absolute left-4 bottom-4 text-gray-500 opacity-50 hover:opacity-100 transition-opacity z-20 p-2 rounded-full hover:bg-white/10"
              aria-label="Home"
            >
              <FaHome size={24} />
            </button>

            {/* Background Image */}
            <div className="absolute inset-0 bg-gray-900">
              {/* Loading background */}
              <div className={`absolute inset-0 bg-gray-900 transition-opacity duration-500 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
              
              {/* Main image */}
              <img
                src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public"
                alt="Night soccer game under floodlights"
                className={`w-full h-full object-cover transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'} select-none pointer-events-none`}
                draggable="false"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>

            {/* Black overlay */}
            <div className="absolute inset-0 bg-black/30" />

            {/* Card Container */}
            <div className="bg-gray-50 rounded-lg shadow-xl p-6 max-w-md w-full relative z-10">
              {/* Page Title */}
              <h1 className="text-4xl font-bold tracking-tight mb-10 text-center text-neutral-900 select-none">
                Reset Password
              </h1>

              {/* Success Message */}
              {successMessage && (
                <div className="mb-6 text-center">
                  <p className="text-green-600">{successMessage}</p>
                </div>
              )}

              {/* Reset Password Form */}
              <form onSubmit={handleResetPassword}>
                <div className="mb-1">
                  <label htmlFor="password" className="sr-only">
                    New Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    disabled={!resetCode}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError('');
                      if (confirmPassword) {
                        setConfirmPasswordError('');
                      }
                    }}
                    className={`w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100 
                      ${passwordError ? 'ring-2 ring-red-500' : 'focus:ring-2 focus:ring-indigo-500'}`}
                    placeholder="New Password"
                    autoComplete="new-password"
                  />
                  <div className="h-5 mt-1">
                    {passwordError && (
                      <p className="text-sm text-red-500">{passwordError}</p>
                    )}
                  </div>
                </div>

                <div className="mb-1">
                  <label htmlFor="confirmPassword" className="sr-only">
                    Confirm Password
                  </label>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    disabled={!resetCode}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordError('');
                    }}
                    className={`w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100 
                      ${confirmPasswordError ? 'ring-2 ring-red-500' : 'focus:ring-2 focus:ring-indigo-500'}`}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                  />
                  <div className="h-5 mt-1">
                    {confirmPasswordError && (
                      <p className="text-sm text-red-500">{confirmPasswordError}</p>
                    )}
                  </div>
                </div>

                {/* Turnstile container matches input styling to avoid layout shift */}
                <div className="mb-1 min-h-[72px]">
                  <Turnstile 
                    siteKey="0x4AAAAAAAjuZz4sYx8SxbrU" 
                    onTokenChange={handleTurnstileVerify} 
                    resetKey={turnstileResetKey} 
                  />
                </div>

                {/* Error Message Container - Always present */}
                <div className="h-4 mb-1">
                  {errorMessage && <div className="text-xs text-red-500 select-none">{errorMessage}</div>}
                </div>

                <button
                  type="submit"
                  disabled={!turnstileToken || !resetCode || isSubmitting}
                  className={`w-full px-6 py-2 font-medium rounded-lg transition-all duration-500 ease-in-out text-medium select-none
                    ${
                      turnstileToken && resetCode && !isSubmitting
                        ? 'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-300'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                >
                  {isSubmitting ? 'Resetting Password...' : 'Reset Password'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </RedirectIfAuthenticated>
  );
};

export default ResetPassword;
