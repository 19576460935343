import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Rules from './pages/Rules';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Contact from './pages/Contact';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import UnderConstruction from './pages/UnderConstruction';
import Dashboard from './pages/Dashboard';
import EditProfilePage from './pages/EditProfilePage';
import Verify from './pages/Verify';
import CreateGame from './pages/CreateGame';
import CreateTeamPage from './pages/CreateTeam';
import UserProfilePage from './pages/UserProfilePage';
import NotificationsPage from './pages/NotificationsPage';
import FriendsPage from './pages/FriendsPage';
import GameDetails from './pages/GameDetails';
import ChatPage from './pages/ChatPage';
import NotFoundPage from 'pages/NotFoundPage';
import AuthGuard from './components/AuthGuard';
import ScrollToTop from './components/ScrollToTop';
import { UserProvider } from './contexts/UserContext';
import { ViewModeProvider } from './contexts/ViewModeContext';
import { DebugProvider } from './contexts/DebugContext';
import ForgotPassword from 'pages/ForgotPassword';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ResetPassword from './pages/ResetPassword';
import { HelmetProvider } from 'react-helmet-async';

config.autoAddCss = false;

function App() {
  // if address is championships-3pl.pages.dev, redirect to https://championships.kickoff.game
  if (window.location.hostname === 'championships-3pl.pages.dev') {
    window.location.href = 'https://kickoff.game';
  }
  if (window.location.hostname === 'championships.kickoff.game') {
    window.location.href = 'https://kickoff.game';
  }
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <DebugProvider>
          <ViewModeProvider>
            <UserProvider>
              <div className="font-sans bg-gray-900 min-h-screen pb-[calc(env(safe-area-inset-bottom))] overflow-x-hidden">
                <Routes>
                  {/* Public Routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/verify-email" element={<Verify />} />
                  <Route path="/u/:username" element={<UserProfilePage />} />
                  
                  {/* Protected Routes */}
                  <Route path="/dashboard" element={
                    <AuthGuard>
                      <Dashboard />
                    </AuthGuard>
                  } />
                  <Route path="/editprofile" element={
                    <AuthGuard>
                      <EditProfilePage />
                    </AuthGuard>
                  } />
                  <Route path="/create-game" element={
                    <AuthGuard>
                      <CreateGame />
                    </AuthGuard>
                  } />
                  <Route path="/create-team" element={
                    <AuthGuard>
                      <CreateTeamPage />
                    </AuthGuard>
                  } />
                  <Route path="/game/:gameId" element={
                      <GameDetails />
                  } />
                  <Route path="/chat" element={
                    <AuthGuard>
                      <ChatPage />
                    </AuthGuard>
                  } />
                  <Route path="/chat/:chatroomId" element={
                    <AuthGuard>
                      <ChatPage />
                    </AuthGuard>
                  } />
                  <Route path="/notifications" element={
                    <AuthGuard>
                      <NotificationsPage />
                    </AuthGuard>
                  } />
                  <Route path="/friends" element={
                    <AuthGuard>
                      <FriendsPage />
                    </AuthGuard>
                  } />
                  
                  {/* Fallback Route */}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </div>
            </UserProvider>
          </ViewModeProvider>
        </DebugProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
