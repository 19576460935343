import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface RedirectIfAuthenticatedProps {
    children: React.ReactNode;
    to?: string;
}

const RedirectIfAuthenticated: React.FC<RedirectIfAuthenticatedProps> = ({ 
    children, 
    to = '/dashboard' 
}) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isAuthenticated) {
        return <Navigate to={to} replace />;
    }

    return <>{children}</>;
};

export default RedirectIfAuthenticated;
