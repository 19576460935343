import React, { useState, useEffect } from 'react';
import type { DebugEvent } from '../contexts/DebugContext';

interface DebugOverlayProps {
  events: DebugEvent[];
  clearEvents: () => void;
  isVisible: boolean;
  toggleVisibility: () => void;
  showToggleButton: boolean;
}

const DebugOverlay: React.FC<DebugOverlayProps> = ({
  events,
  clearEvents,
  isVisible,
  toggleVisibility,
  showToggleButton
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isVisible && showToggleButton) {
    return (
      <button
        onClick={toggleVisibility}
        className={`fixed ${isMobile ? 'top-4 right-4' : 'bottom-4 right-4'} bg-gray-800 text-white px-3 py-1 rounded-full opacity-50 hover:opacity-100 text-sm z-[9999] select-none`}
        style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
      >
        Debug
      </button>
    );
  }

  if (!isVisible) {
    return null;
  }

  const overlayClasses = isMobile
    ? 'fixed inset-0 bg-gray-800 text-white p-4 overflow-auto z-[9999]'
    : 'fixed bottom-0 right-0 w-96 max-h-96 bg-gray-800 text-white p-4 overflow-auto z-[9999]';

  return (
    <div className={overlayClasses}>
      <div className="flex justify-between items-center mb-4 select-none">
        <h3 className="text-lg font-semibold">Event Log</h3>
        <div className="space-x-2">
          <button
            onClick={clearEvents}
            className="px-2 py-1 bg-red-600 text-white rounded text-sm select-none"
            style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
          >
            Clear
          </button>
          <button
            onClick={toggleVisibility}
            className="px-2 py-1 bg-gray-600 text-white rounded text-sm select-none"
            style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="space-y-2">
        {events.map((event, index) => (
          <div key={index} className="text-sm bg-gray-700 p-2 rounded">
            <div className="flex justify-between text-xs text-gray-400">
              <span>{event.type}</span>
              <span>{new Date(event.timestamp).toLocaleTimeString()}</span>
            </div>
            <div className="mt-1">
              <span className="text-gray-300">{event.target}</span>
              {event.handler && (
                <span className="text-xs text-gray-400 ml-2">({event.handler})</span>
              )}
            </div>
            {event.elementInfo && (
              <div className="mt-1 text-xs text-gray-400">
                {event.elementInfo.id && <span>id: {event.elementInfo.id} </span>}
                {event.elementInfo.className && (
                  <span>class: {event.elementInfo.className} </span>
                )}
                <span>tag: {event.elementInfo.tagName}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DebugOverlay;
