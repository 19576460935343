import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes, FaHome } from 'react-icons/fa';
import Turnstile from '../components/Turnstile';
import RedirectIfAuthenticated from '../components/RedirectIfAuthenticated';
import TermsAndConditions from '../constants/TermsAndConditions';
// import your custom utilities and/or api client, if needed
import { clearAllState } from '../utils/clearState';
import { apiClient } from '../models/api';

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
			<div className="bg-white rounded-lg p-6 w-full max-w-[90%] md:max-w-[80%] lg:max-w-[70%] xl:max-w-[60%] mx-4">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-semibold">{title}</h2>
					<button onClick={onClose} className="text-gray-500 hover:text-gray-700">
						<FaTimes />
					</button>
				</div>
				<div className="text-gray-600">{children}</div>
			</div>
		</div>
	);
};

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [turnstileResetKey, setTurnstileResetKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  // Disable scrolling when component mounts
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (!turnstileToken) {
      setErrorMessage('Please complete the Turnstile challenge.');
      return;
    }

    try {
      const response = await fetch('https://championships.kickoff.game/api/auth/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'cf-turnstile-response': turnstileToken,
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to submit password reset request.');
      }

      // Clear any existing tokens/session
      clearAllState();
      apiClient.setAccessToken(null);
      apiClient.setRefreshToken(null);

      setSuccessMessage('A password reset link has been sent to your email.');
    } catch (error: any) {
      if (error.response?.status === 429) {
        setErrorMessage('Too many attempts. Please try again later.');
      } else if (error.response?.status === 404) {
        setErrorMessage('Email address not found.');
      } else {
        setErrorMessage(error.message || 'An unexpected error occurred. Please try again.');
      }
      // Reset turnstile only on error
      setTurnstileToken(null);
      setTurnstileResetKey((prevKey) => prevKey + 1);
    }
  };

  return (
    <RedirectIfAuthenticated>
      <div className="h-screen w-screen flex flex-col overflow-hidden bg-gray-900">
        <div className="flex-1 overflow-y-auto">
          {/* Page Background */}
          <div className="h-screen overflow-hidden bg-gray-900 flex items-center justify-center px-4 sm:px-6 lg:px-8 relative">
            {/* Home Icon */}
            <button
              onClick={() => navigate('/')}
              className="absolute left-4 bottom-4 text-gray-500 opacity-50 hover:opacity-100 transition-opacity z-20 p-2 rounded-full hover:bg-white/10"
              aria-label="Home"
            >
              <FaHome size={24} />
            </button>

            {/* Background Image */}
            <div className="absolute inset-0 bg-gray-900">
              {/* Loading background */}
              <div className={`absolute inset-0 bg-gray-900 transition-opacity duration-500 ${true ? 'opacity-0' : 'opacity-100'}`} />
              
              {/* Main image */}
              <img
                src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public"
                alt="Night soccer game under floodlights"
                className={`w-full h-full object-cover transition-opacity duration-500 ${true ? 'opacity-100' : 'opacity-0'} select-none pointer-events-none`}
                draggable="false"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>

            {/* Black overlay */}
            <div className="absolute inset-0 bg-black/30" />

            {/* Card Container */}
            <div className="bg-gray-50 rounded-lg shadow-xl p-6 max-w-md w-full relative z-10">
              {/* Page Title */}
              <h1 className="text-4xl font-bold tracking-tight mb-10 text-center text-neutral-900 select-none">
                Forgot Your Password?
              </h1>
              
              {/* Forgot Password Form */}
              <form onSubmit={handleForgotPassword} className="mb-6">
                <div className="mb-4">
                  <label htmlFor="email" className="sr-only select-none">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100"
                    placeholder="Email"
                  />
                </div>

                <div className="mb-1 min-h-[72px]">
                  <Turnstile
                    siteKey="0x4AAAAAAAjuZz4sYx8SxbrU"
                    onTokenChange={setTurnstileToken}
                    resetKey={turnstileResetKey}
                  />
                </div>

                {/* Error & Success Messages */}
                <div className="h-4 mb-1">
                  {errorMessage && (
                    <div className="text-xs text-gray-500 select-none">
                      {errorMessage}
                    </div>
                  )}
                  {successMessage && (
                    <div className="text-xs text-green-600 select-none">
                      {successMessage}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  disabled={!turnstileToken}
                  className={`w-full px-6 py-2 font-medium rounded-lg transition-all duration-500 ease-in-out text-medium select-none
                    ${
                      turnstileToken
                        ? 'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-300'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                >
                  Send Reset Link
                </button>
              </form>

              {/* Back to Sign In */}
              <div className="text-center mb-4">
                <p className="text-sm select-none">
                  Remembered your password?{' '}
                  <button
                    onClick={() => navigate('/signin')}
                    className="text-indigo-600 hover:text-indigo-800 hover:underline font-semibold select-none transition-colors"
                  >
                    Sign In
                  </button>
                </p>
              </div>

              {/* Terms and Conditions */}
              <div className="text-center text-xs text-gray-500 select-none">
                By using this feature, you agree to our{' '}
                <button
                  onClick={() => setShowTerms(true)}
                  className="text-indigo-600 hover:text-indigo-800 hover:underline select-none transition-colors"
                >
                  Terms and Conditions
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Terms Modal */}
      <Modal isOpen={showTerms} onClose={() => setShowTerms(false)} title="Terms & Conditions">
				<div className="max-h-[70vh] overflow-y-auto">
					<TermsAndConditions />
				</div>
			</Modal>
    </RedirectIfAuthenticated>
  );
};

export default ForgotPassword;
