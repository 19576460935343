import React from 'react';
import CopyableEmail from '../components/CopyableEmail';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="p-6 md:p-8 max-w-4xl mx-auto text-gray-800">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900">Kickoff Privacy Policy</h1>
      <p className="mb-8 text-gray-600"><strong>Last Updated:</strong> 31st December 2024</p>

      <section className="mb-8">
        <p className="mb-4 leading-relaxed">
          This Privacy Policy describes our policies and procedures regarding the collection, use, and disclosure
          of your information when you use the Kickoff platform (“<strong>Service</strong>”), and it explains
          your privacy rights and how the law protects you. By using the Service, you agree to the collection and
          use of information in accordance with this Privacy Policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">1. Interpretation and Definitions</h2>
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">1.1 Interpretation</h3>
        <p className="mb-4 leading-relaxed">
          Words where the initial letter is capitalized have meanings defined under the following conditions.
          The following definitions shall have the same meaning regardless of whether they appear in singular
          or in plural.
        </p>

        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">1.2 Definitions</h3>
        <p className="mb-4 leading-relaxed">For the purposes of this Privacy Policy:</p>
        <ul className="space-y-4 mb-4 list-disc pl-5">
          <li className="leading-relaxed">
            <strong>Account</strong> means a unique account created for you to access our Service or parts of our Service.
          </li>
          <li className="leading-relaxed">
            <strong>Company</strong> (referred to as “the Company,” “We,” “Us,” or “Our”) refers to Kickoff,
            [Company Address].
          </li>
          <li className="leading-relaxed">
            <strong>Cookies</strong> are small files that are placed on your computer, mobile device, or any other
            device by a website, containing the details of your browsing history on that website, among its many uses.
          </li>
          <li className="leading-relaxed">
            <strong>Country</strong> refers to the jurisdiction(s) where Kickoff operates or any other jurisdictions
            as required by law.
          </li>
          <li className="leading-relaxed">
            <strong>Device</strong> means any device that can access the Service, such as a computer, cellphone,
            or digital tablet.
          </li>
          <li className="leading-relaxed">
            <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
          </li>
          <li className="leading-relaxed">
            <strong>Service</strong> refers to the Kickoff website, mobile applications, and any other related
            products or services we operate.
          </li>
          <li className="leading-relaxed">
            <strong>Service Provider</strong> means any natural or legal person who processes data on behalf
            of the Company. It refers to third-party companies or individuals employed by the Company to facilitate
            the Service, to provide the Service on behalf of the Company, to perform services related to the Service,
            or to assist the Company in analyzing how the Service is used.
          </li>
          <li className="leading-relaxed">
            <strong>Third-party Social Media Service</strong> refers to any website or social network website
            through which a User can log in or create an account to use the Service.
          </li>
          <li className="leading-relaxed">
            <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
            the Service or from the Service infrastructure itself.
          </li>
          <li className="leading-relaxed">
            <strong>You</strong> means the individual accessing or using the Service, or the company or other
            legal entity on behalf of which such individual is accessing or using the Service, as applicable.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">2. Collecting and Using Your Personal Data</h2>

        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">2.1 Types of Data Collected</h3>
        <h4 className="font-semibold mb-1">2.1.1 Personal Data</h4>
        <p className="mb-4 leading-relaxed">
          While using our Service, we may ask you to provide us with certain personally identifiable information
          that can be used to contact or identify you. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul className="space-y-4 mb-4 list-disc pl-5">
          <li className="leading-relaxed">Email address</li>
          <li className="leading-relaxed">First and last name</li>
          <li className="leading-relaxed">Phone number (if you choose to provide it)</li>
          <li className="leading-relaxed">Usage Data</li>
        </ul>

        <h4 className="font-semibold mb-1">2.1.2 Usage Data</h4>
        <p className="mb-4 leading-relaxed">
          Usage Data is collected automatically when using the Service. It may include information such as your
          Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our
          Service that you visit, the time and date of your visit, the time spent on those pages, unique device
          identifiers, and other diagnostic data. When you access the Service by or through a mobile device, we
          may collect certain information automatically, including, but not limited to, the type of mobile device
          you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating
          system, the type of mobile Internet browser you use, unique device identifiers, and other diagnostic
          data.
        </p>

        <h4 className="font-semibold mb-1">2.1.3 Information from Third-Party Social Media Services</h4>
        <p className="mb-4 leading-relaxed">
          The Company allows you to create an account and log in through various Third-Party Social Media Services
          (e.g., Google, Facebook, Twitter, LinkedIn). If you decide to register or log in via a Third-Party Social
          Media Service, we may collect Personal Data associated with that account (such as your name, email
          address, activities, or contacts). You may also choose to provide additional information to the Company
          through such accounts, which we will treat in accordance with this Privacy Policy.
        </p>

        <h4 className="font-semibold mb-1">2.1.4 Tracking Technologies and Cookies</h4>
        <p className="mb-4 leading-relaxed">
          We use Cookies and similar tracking technologies (e.g., beacons, tags, scripts) to track the activity
          on our Service and store certain information. Cookies can be “Persistent” or “Session” Cookies:
        </p>
        <ul className="space-y-4 mb-4 list-disc pl-5">
          <li className="leading-relaxed">
            <strong>Session Cookies:</strong> These Cookies are temporary and deleted once you close your browser.
          </li>
          <li className="leading-relaxed">
            <strong>Persistent Cookies:</strong> These Cookies remain on your device until you delete them or
            until they expire.
          </li>
        </ul>
        <p className="mb-4 leading-relaxed">
          You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However,
          if you do not accept Cookies, you may not be able to use some parts of our Service.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">3. Use of Your Personal Data</h3>
        <p className="mb-4 leading-relaxed">We may use your Personal Data for the following purposes:</p>
        <ul className="space-y-4 mb-4 list-disc pl-5">
          <li className="leading-relaxed">
            <strong>To provide and maintain our Service:</strong> including monitoring the usage of our Service
            and troubleshooting technical issues.
          </li>
          <li className="leading-relaxed">
            <strong>To manage your Account:</strong> register or manage you as a user of the Service, offering
            different functionalities available to you as a registered user.
          </li>
          <li className="leading-relaxed">
            <strong>For the performance of a contract:</strong> developing, complying with, and undertaking the
            purchase contract or any other contract you have entered into with us.
          </li>
          <li className="leading-relaxed">
            <strong>To contact you:</strong> via email, phone calls, SMS, or other forms of electronic communication
            regarding updates or informational communications related to functionalities, products, services,
            or security matters.
          </li>
          <li className="leading-relaxed">
            <strong>To provide you with news, special offers, and general information:</strong> related to other
            goods, services, and events which we offer that are similar to those that you have already purchased
            or enquired about, unless you have opted not to receive such information.
          </li>
          <li className="leading-relaxed">
            <strong>To manage your requests:</strong> attend to and handle any inquiries or requests submitted by you.
          </li>
          <li className="leading-relaxed">
            <strong>For business transfers:</strong> evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or all of our assets, in which Personal
            Data may be among the assets transferred.
          </li>
          <li className="leading-relaxed">
            <strong>For other purposes:</strong> data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns, and improving our Service, products, services, marketing,
            and user experience.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">4. Sharing Your Personal Information</h3>
        <ul className="space-y-4 mb-4 list-disc pl-5">
          <li className="leading-relaxed">
            <strong>With Service Providers:</strong> we may share your personal information with Service Providers
            to monitor and analyze the use of our Service or to contact you.
          </li>
          <li className="leading-relaxed">
            <strong>For business transfers:</strong> we may share or transfer your personal information in
            connection with, or during negotiations of, any merger, sale of Company assets, financing, or
            acquisition of all or a portion of our business.
          </li>
          <li className="leading-relaxed">
            <strong>With affiliates:</strong> we may share your information with our affiliates, in which case
            we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company
            and any other subsidiaries, joint venture partners, or other companies that we control or that are
            under common control with us.
          </li>
          <li className="leading-relaxed">
            <strong>With business partners:</strong> we may share your information with our business partners
            to offer you certain products, services, or promotions.
          </li>
          <li className="leading-relaxed">
            <strong>With other users:</strong> when you share personal information or otherwise interact in
            public areas with other users, such information may be viewed by all users and may be publicly
            distributed outside. If you interact with other users or register through a Third-Party Social
            Media Service, your contacts on that service may see your profile, activity, and related information.
          </li>
          <li className="leading-relaxed">
            <strong>With your consent:</strong> we may disclose your personal information for any other purpose
            with your explicit consent.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">5. Retention of Your Personal Data</h3>
        <p className="mb-4 leading-relaxed">
          We will retain your Personal Data only for as long as is necessary for the purposes set out in this
          Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our
          legal obligations (e.g., if we are required to retain your data to comply with applicable laws),
          resolve disputes, and enforce our legal agreements and policies. We also retain Usage Data for internal
          analysis purposes. Usage Data is generally retained for a shorter period, unless used to strengthen
          security or to improve the functionality of our Service, or we are legally obligated to retain it for
          longer periods.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">6. Transfer of Your Personal Data</h3>
        <p className="mb-4 leading-relaxed">
          Your information, including Personal Data, may be processed at the Company’s operating offices and
          in any other places where the parties involved in processing are located. It means that this information
          may be transferred to—and maintained on—computers located outside of your state, province, country, or
          other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.
        </p>
        <p className="mb-4 leading-relaxed">
          Your consent to this Privacy Policy followed by your submission of such information represents your
          agreement to that transfer. We will take all steps reasonably necessary to ensure that your data is
          treated securely and in accordance with this Privacy Policy, and no transfer of your Personal Data
          will take place to an organization or a country unless there are adequate controls in place, including
          the security of your data and other personal information.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">7. Your Rights to Delete or Modify Personal Data</h3>
        <p className="mb-4 leading-relaxed">
          You have the right to delete or request that we assist in deleting the Personal Data that we have
          collected about you. You may update, amend, or delete your information at any time by logging into your
          Account and visiting the account settings section, or by contacting us directly. Please note that we
          may retain certain information when we have a legal obligation or lawful basis to do so.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">8. Disclosure of Your Personal Data</h3>
        <h4 className="font-semibold mb-1">8.1 Business Transactions</h4>
        <p className="mb-4 leading-relaxed">
          If the Company is involved in a merger, acquisition, or asset sale, your Personal Data may be transferred.
          We will provide notice before your Personal Data is transferred and becomes subject to a different
          privacy policy.
        </p>

        <h4 className="font-semibold mb-1">8.2 Law Enforcement</h4>
        <p className="mb-4 leading-relaxed">
          Under certain circumstances, the Company may be required to disclose your Personal Data if required
          to do so by law or in response to valid requests by public authorities (e.g., a court or government
          agency).
        </p>

        <h4 className="font-semibold mb-1">8.3 Other Legal Requirements</h4>
        <p className="mb-4 leading-relaxed">
          We may disclose your Personal Data in the good faith belief that such action is necessary to:
        </p>
        <ul className="space-y-4 mb-4 list-disc pl-5">
          <li className="leading-relaxed">Comply with a legal obligation</li>
          <li className="leading-relaxed">
            Protect and defend the rights or property of the Company
          </li>
          <li className="leading-relaxed">
            Prevent or investigate possible wrongdoing in connection with the Service
          </li>
          <li className="leading-relaxed">
            Protect the personal safety of users of the Service or the public
          </li>
          <li className="leading-relaxed">Protect against legal liability</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">9. Security of Your Personal Data</h3>
        <p className="mb-4 leading-relaxed">
          The security of your Personal Data is important to us, but remember that no method of transmission
          over the Internet or method of electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">10. Children’s Privacy</h3>
        <p className="mb-4 leading-relaxed">
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally
          identifiable information from anyone under 13. If you are a parent or guardian and you are aware that
          your child has provided us with Personal Data, please contact us. If we become aware that we have
          collected Personal Data from anyone under the age of 13 without verification of parental consent, we
          will take steps to remove that information from our servers.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">11. Links to Other Websites</h3>
        <p className="mb-4 leading-relaxed">
          Our Service may contain links to other websites that are not operated by us. If you click on a
          third-party link, you will be directed to that third party’s site. We strongly advise you to review
          the Privacy Policy of every site you visit. We have no control over and assume no responsibility for
          the content, privacy policies, or practices of any third-party sites or services.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">12. Changes to This Privacy Policy</h3>
        <p className="mb-4 leading-relaxed">
          We may update our Privacy Policy from time to time. We will notify you of any material changes by
          posting the new Privacy Policy on this page and updating the “Last Updated” date at the top. Where
          appropriate or required by law, we will provide notice via email and/or a prominent notice on our
          Service prior to the change becoming effective. Your continued use of the Service after such changes
          go into effect indicates your acceptance of the revised Privacy Policy.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-900">13. Contact Us</h3>
        <div className="mt-4">
          <p className="mb-4">
            If you have any questions about this Privacy Policy, please contact us at:
          </p>
          <CopyableEmail email="info@kickoff.game" />
        </div>
      </section>

      <hr />
      {/* <p style={{ fontStyle: 'italic' }}>
        <strong>NOTE:</strong> This document is intended as a comprehensive template. It should be reviewed
        and customized by qualified legal counsel to ensure compliance with all applicable laws and regulations.
      </p> */}
    </div>
  );
};

export default PrivacyPolicy;
