import api from './api';

export interface GameMember {
    game_id: string;
    member_type: string;
    member_id: string;
    role: string;
    joined_at: Date;
    left_at?: Date;
    left_reason?: string;
    status: string;
    checked_in: boolean;
    performance_metrics: Record<string, number>;
}

export interface GameSearchParams {
    latitude?: number;
    longitude?: number;
    radius?: number;
    start_date?: Date;
    end_date?: Date;
    sport?: string;
    skill_level?: string;
}

interface PaginatedResponse<T> {
    games: T[];
    next_page: string | null;
    has_more: boolean;
}

interface TimeParams {
    year: number;
}

export class Game {
    game_id: string;
    name: string;
    description: string;
    cover_image: string;
    scheduled_at: Date;
    location: string;
    visibility: string;
    creator_id: string;
    created_at: Date;
    updated_at: Date;
    creator_type: string;
    admin_id: string;
    require_payment: boolean;
    payment_amount: number;
    joined_member_count: number;
    left_member_count: number;
    waitlisted_member_count: number;
    members: Map<string, GameMember>;
    max_members: number;
    team_size: number;
    reserve_count: number;
    status: string;
    location_id: string;
    visibility_settings_id: string;
    performance_id: string;
    payment_id: string;
    communication_settings_id: string;
    latitude: number;
    longitude: number;
    geohash: string;
    deleted_at: Date | null;

    constructor(data: any) {
        Object.assign(this, data);
        // Ensure dates are properly handled as UTC
        if (data.scheduled_at) {
            const d = new Date(data.scheduled_at);
            this.scheduled_at = new Date(Date.UTC(
                d.getUTCFullYear(),
                d.getUTCMonth(),
                d.getUTCDate(),
                d.getUTCHours(),
                d.getUTCMinutes(),
                0,
                0
            ));
        }
        this.created_at = new Date(this.created_at);
        this.updated_at = new Date(this.updated_at);
        this.cover_image = data.cover_image || '';
        this.creator_type = data.creator_type || 'user';
        this.admin_id = data.admin_id || '';
        this.require_payment = data.require_payment || false;
        this.payment_amount = data.payment_amount || 0;
        this.joined_member_count = data.joined_member_count || 0;
        this.left_member_count = data.left_member_count || 0;
        this.waitlisted_member_count = data.waitlisted_member_count || 0;
        this.members = new Map(
            Object.entries(data.members || {}).map(([userId, memberData]: [string, GameMember]) => [
                userId,
                {
                    ...memberData,
                    joined_at: memberData.joined_at ? new Date(memberData.joined_at) : new Date(),
                    left_at: memberData.left_at ? new Date(memberData.left_at) : undefined,
                } as GameMember
            ])
        );
        this.max_members = data.max_members || 0;
        this.team_size = data.team_size || 1;
        this.reserve_count = data.reserve_count || 0;
        this.status = data.status || 'scheduled';
        this.location_id = data.location_id || '';
        this.visibility_settings_id = data.visibility_settings_id || '';
        this.performance_id = data.performance_id || '';
        this.payment_id = data.payment_id || '';
        this.communication_settings_id = data.communication_settings_id || '';
        this.latitude = data.latitude || 0;
        this.longitude = data.longitude || 0;
        this.geohash = data.geohash || '';
        this.deleted_at = data.deleted_at || null;
    }

    static async fetchAll(): Promise<Game[]> {
        try {
            const response = await api.get('/api/v1/games');
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            throw new Error('Failed to fetch games');
        }
    }

    static async fetchById(gameId: string): Promise<Game> {
        try {
            const response = await api.get(`/api/v1/games`, {
                params: { game_id: gameId }
            });
            // Handle both array and object responses
            const gameData = Array.isArray(response.data) ? response.data[0] : response.data;
            if (!gameData) {
                throw new Error('Game not found');
            }
            return new Game(gameData);
        } catch (error) {
            console.error('Failed to fetch game:', error);
            throw new Error('Failed to fetch game');
        }
    }

    static async search(params: GameSearchParams): Promise<Game[]> {
        try {
            const response = await api.post('/api/v1/games/search', { params });
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            throw new Error('Failed to search games');
        }
    }

    static async getByUserId(
        userId: string, 
        options: { 
            limit?: number; 
            pageState?: string; 
            year?: number;
        } = {}
    ): Promise<{ games: Game[]; nextPage: string; hasMore: boolean }> {
        const { limit = 10, pageState, year } = options;

        try {
            const queryParams = new URLSearchParams({
                user_id: userId,
                limit: limit.toString()
            });

            if (year !== undefined) {
                queryParams.append('year', year.toString());
            }

            if (pageState) {
                queryParams.append('page_state', pageState);
            }

            const response = await api.get<any>(`/api/v1/users/games?${queryParams}`);

            // Handle both array and object responses
            const gamesData = Array.isArray(response.data) ? response.data : (response.data.games || []);
            const games = gamesData.map((gameData: any) => new Game(gameData));

            return {
                games,
                nextPage: response.data.next_page || '',
                hasMore: response.data.has_more || false
            };
        } catch (error) {
            throw new Error(`Failed to fetch games by user: ${error}`);
        }
    }

    private static async fetchGamesByYear(
        userId: string, 
        year: number | undefined, 
        limit: number,
        pageState?: string
    ): Promise<PaginatedResponse<Game>> {
        try {
            const queryParams = new URLSearchParams({
                user_id: userId,
                limit: limit.toString()
            });

            if (year !== undefined) {
                queryParams.append('year', year.toString());
            }

            if (pageState) {
                queryParams.append('page_state', pageState);
            }

            const response = await api.get<PaginatedResponse<any>>(`/api/v1/users/games?${queryParams.toString()}`);
            return {
                games: response.data.games.map(gameData => new Game(gameData)),
                next_page: response.data.next_page,
                has_more: response.data.has_more
            };
        } catch (error) {
            console.error('Failed to fetch games:', error);
            return {
                games: [],
                next_page: null,
                has_more: false
            };
        }
    }

    static async getByFriends(userId: string): Promise<Game[]> {
        try {
            const response = await api.get(`/api/v1/games/friends?user_id=${userId}`);
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            console.error('Failed to fetch friend games:', error);
            return []; // Return empty array instead of throwing
        }
    }

    static async getNearby(userId: string): Promise<Game[]> {
        try {
            // Use search with empty params to get default nearby games
            const response = await api.post(`/api/v1/games/search`, {
                params: {
                    limit: 20,
                    offset: 0
                }
            });
            return response.data.map((gameData: any) => new Game(gameData));
        } catch (error) {
            console.error('Failed to fetch nearby games:', error);
            return []; // Return empty array instead of throwing
        }
    }

    async create(): Promise<Game> {
        try {
            // Ensure latitude and longitude are included in the request
            if (!this.latitude || !this.longitude) {
                throw new Error('Location coordinates are required');
            }
            
            const gameData = {
                ...this,
                latitude: this.latitude,
                longitude: this.longitude,
            };
            
            const response = await api.post('/api/v1/games', gameData);
            return new Game(response.data);
        } catch (error) {
            throw new Error('Failed to create game');
        }
    }

    async update(updateData?: Partial<Game>): Promise<Game> {
        try {
            const dataToUpdate = updateData ? { ...this, ...updateData } : this;
            const response = await api.put(`/api/v1/games`, dataToUpdate);
            return new Game(response.data);
        } catch (error) {
            throw new Error('Failed to update game');
        }
    }

    async delete(): Promise<void> {
        try {
            await api.delete(`/api/v1/games?game_id=${this.game_id}`);
        } catch (error) {
            throw new Error('Failed to delete game');
        }
    }

    static async getGameEvents(gameId: string): Promise<string> {
        try {
            const response = await api.get(`/api/v1/games/events?game_id=${gameId}`);
            return response.data;
        } catch (error) {
            throw new Error('Failed to fetch game events');
        }
    }

    static async updateGameEvents(gameId: string, events: string): Promise<void> {
        try {
            await api.post(`/api/v1/games/events`, { game_id: gameId, events });
        } catch (error) {
            throw new Error('Failed to update game events');
        }
    }

    async joinGame(): Promise<void> {
        try {
            await api.post(`/api/v1/games/join?game_id=${this.game_id}`);
        } catch (error) {
            throw new Error('Failed to join game');
        }
    }

    async leaveGame(): Promise<void> {
        try {
            await api.post(`/api/v1/games/leave?game_id=${this.game_id}`);
        } catch (error) {
            throw new Error('Failed to leave game');
        }
    }

    async joinWaitlist(): Promise<Game> {
        try {
            const response = await api.post(`/api/v1/games/waitlist/join?game_id=${this.game_id}`);
            return new Game(response.data);
        } catch (error) {
            throw new Error('Failed to join waitlist');
        }
    }

    async leaveWaitlist(): Promise<Game> {
        try {
            const response = await api.post(`/api/v1/games/waitlist/leave?game_id=${this.game_id}`);
            return new Game(response.data);
        } catch (error) {
            throw new Error('Failed to leave waitlist');
        }
    }

    isUserJoined(userId: string): boolean {
        const member = this.members.get(userId);
        // Check if member exists, has joined status, and hasn't left
        let result = member !== undefined
        return result;
    }

    isUserWaitlisted(userId: string): boolean {
        const member = this.members.get(userId);
        return member !== undefined && member.status === 'waitlisted';
    }

    isFull(): boolean {
        return this.joined_member_count >= this.max_members;
    }

    isValid(): boolean {
        // Required string fields
        const requiredStrings = [
            'game_id',
            'name',
            'description',
            'location',
            'visibility',
            'creator_id',
            'creator_type',
            'status'
        ];
        
        for (const field of requiredStrings) {
            if (typeof this[field as keyof Game] !== 'string' || !this[field as keyof Game]) {
                console.error(`Invalid or missing string field: ${field}`);
                return false;
            }
        }

        // Required Date fields
        const requiredDates = ['scheduled_at', 'created_at', 'updated_at'];
        for (const field of requiredDates) {
            if (!(this[field as keyof Game] instanceof Date)) {
                console.error(`Invalid or missing date field: ${field}`);
                return false;
            }
        }

        // Required number fields
        const requiredNumbers = [
            'max_members',
            'team_size',
            'reserve_count',
            'joined_member_count',
            'left_member_count',
            'waitlisted_member_count',
            'latitude',
            'longitude'
        ];
        
        for (const field of requiredNumbers) {
            if (typeof this[field as keyof Game] !== 'number') {
                console.error(`Invalid or missing number field: ${field}`);
                return false;
            }
        }

        // Required boolean fields
        if (typeof this.require_payment !== 'boolean') {
            console.error('Invalid or missing boolean field: require_payment');
            return false;
        }

        // Validate members Map
        if (!(this.members instanceof Map)) {
            console.error('Invalid or missing members Map');
            return false;
        }

        // Validate each member in the Map
        for (const [userId, member] of this.members) {
            if (!this.isValidMember(member)) {
                console.error(`Invalid member data for user ${userId}`);
                return false;
            }
        }

        // Game should not be deleted
        if (this.deleted_at !== null && this.deleted_at instanceof Date) {
            console.error('Game is marked as deleted');
            return false;
        }

        return true;
    }

    private isValidMember(member: GameMember): boolean {
        return (
            typeof member.game_id === 'string' &&
            typeof member.member_type === 'string' &&
            typeof member.member_id === 'string' &&
            typeof member.role === 'string' &&
            typeof member.status === 'string' &&
            typeof member.checked_in === 'boolean' &&
            member.joined_at instanceof Date &&
            (member.left_at === undefined || member.left_at instanceof Date) &&
            (member.left_reason === undefined || typeof member.left_reason === 'string') &&
            typeof member.performance_metrics === 'object'
        );
    }

    static async uploadGameImage(file: File): Promise<string> {
        // generate upload url
        const uploadResponse = await api.post('/api/v1/games/image');
        const { upload_url } = uploadResponse.data;

        // upload file to cloudflare
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(upload_url, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to upload game image');
        }

        const data = (await response.json()) as {
            success: boolean;
            messages: { code: number; message: string }[];
            errors: { code: number; message: string }[];
            result: {
                id: string;
                filename: string;
                meta: {
                    user_id: string;
                };
                uploaded: string;
                variants: string[];
                requireSignedURLs: boolean;
            };
        };

        if (!data.success) {
            throw new Error('Failed to upload game image');
        }
        if (data.errors.length > 0) {
            throw new Error(`Failed to upload game image: ${data.errors}`);
        }

        // If no variants available, throw error
        if (!data.result.variants.length) {
            throw new Error('No image variants returned');
        }

        // Try to find the public variant first
        const publicVariant = data.result.variants.find(url => url.endsWith('/public'));
        // If no public variant found, use the first variant
        return publicVariant || data.result.variants[0];
    }
}

// Get the week number (1-53) for a given date
export const getWeekOfYear = (date: Date): number => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil((days + startOfYear.getDay() + 1) / 7);
    return weekNumber;
};

// Get current week parameters
const getCurrentWeek = (): { year: number; month: number; week: number } => {
    const now = new Date();
    return {
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        week: getWeekOfYear(now)
    };
};

// Get previous week parameters
const getPreviousWeek = (current: { year: number; month: number; week: number }): { year: number; month: number; week: number } => {
    const { year, month, week } = current;
    
    if (week > 1) {
        return { year, month, week: week - 1 };
    }
    
    // If first week of year, go to last week of previous year
    return { year: year - 1, month: 12, week: 52 };
};

// Get next week parameters
const getNextWeek = (current: { year: number; month: number; week: number }): { year: number; month: number; week: number } => {
    const { year, month, week } = current;
    
    if (week < 52) {
        return { year, month, week: week + 1 };
    }
    
    // If last week of year, go to first week of next year
    return { year: year + 1, month: 1, week: 1 };
};

// Get previous N weeks parameters
const getPreviousNWeeks = (current: { year: number; month: number; week: number }, n: number): { year: number; month: number; week: number } => {
    let result = { ...current };
    for (let i = 0; i < n; i++) {
        result = getPreviousWeek(result);
    }
    return result;
};
