import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Turnstile from '../components/Turnstile';
import RedirectIfAuthenticated from '../components/RedirectIfAuthenticated';
import api, { apiClient } from '../models/api';
import { clearAllState } from '../utils/clearState';
import { useUser } from '../contexts/UserContext';
import TermsAndConditions from '../constants/TermsAndConditions';
import { FaTimes, FaHome } from 'react-icons/fa';

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
			<div className="bg-white rounded-lg p-6 w-full max-w-[90%] md:max-w-[80%] lg:max-w-[70%] xl:max-w-[60%] mx-4">
				<div className="flex justify-between items-center mb-4">
					<h2 className="text-xl font-semibold">{title}</h2>
					<button onClick={onClose} className="text-gray-500 hover:text-gray-700">
						<FaTimes />
					</button>
				</div>
				<div className="text-gray-600">{children}</div>
			</div>
		</div>
	);
};

const SignIn: React.FC = () => {
	const [usernameOrEmail, setUsernameOrEmail] = useState('');
	const [password, setPassword] = useState('');
	const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
	const [turnstileResetKey, setTurnstileResetKey] = useState(0);
	const [errorMessage, setErrorMessage] = useState('');
	const [showTerms, setShowTerms] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);
	const [lastAttemptedCredentials, setLastAttemptedCredentials] = useState<{ usernameOrEmail: string; password: string } | null>(null);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { refreshUser } = useUser();

	React.useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = 'unset';
		};
	}, []);

	useEffect(() => {
		const img = new Image();
		img.onload = () => {
			setImageLoaded(true);
		};
		img.src = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public';
	}, []);

	const isEmail = (input: string): boolean => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(input);
	};

	const handleSignIn = async (e: React.FormEvent) => {
		e.preventDefault();
		setErrorMessage('');

		if (!usernameOrEmail || !password) {
			setErrorMessage('Please fill in all fields');
			return;
		}

		// Check if credentials are different from last attempt
		const credentialsChanged =
			!lastAttemptedCredentials ||
			lastAttemptedCredentials.usernameOrEmail !== usernameOrEmail ||
			lastAttemptedCredentials.password !== password;

		if (!credentialsChanged) {
			setErrorMessage('Please change your credentials before trying again');
			return;
		}

		if (!turnstileToken) {
			setErrorMessage('Please complete the Turnstile challenge.');
			return;
		}

		// Update last attempted credentials
		setLastAttemptedCredentials({ usernameOrEmail, password });

		try {
			// Sign in and get tokens
			const response = await api.post(
				'/api/auth/signin',
				{
					...(isEmail(usernameOrEmail) ? { email: usernameOrEmail } : { username: usernameOrEmail }),
					password,
				},
				{
					headers: {
						'cf-turnstile-response': turnstileToken,
					},
				}
			);

			const { access_token, refresh_token } = response.data;

			if (!access_token || !refresh_token) {
				throw new Error('Tokens not received from server.');
			}

			// Clear all existing state before setting new tokens
			clearAllState();

			// Set tokens
			apiClient.setAccessToken(access_token);
			apiClient.setRefreshToken(refresh_token);

			// Refresh user context to get latest user data
			await refreshUser();

			// Get redirect path from URL query parameter
			const redirectPath = searchParams.get('redirectAfterSignIn') || '/dashboard';

			// Navigate to dashboard or redirect path
			// navigate(redirectPath);
			window.location.href = redirectPath;
		} catch (error: any) {
			if (error.response?.status === 401) {
				apiClient.setAccessToken(null);
				apiClient.setRefreshToken(null);
				setErrorMessage('Incorrect username or password');
			} else if (error.response?.status === 429) {
				setErrorMessage('Too many attempts. Please try again later.');
			} else if (error.response?.data?.message) {
				setErrorMessage(error.response.data.message);
			} else {
				setErrorMessage('An unexpected error occurred. Please try again.');
				console.error('Sign-in error:', error.message || error);
			}
			// Reset turnstile since the token can only be used once
			setTurnstileToken(null);
		}
	};

	// Add effect to reset Turnstile when credentials change after a failed attempt
	useEffect(() => {
		if (
			lastAttemptedCredentials &&
			(usernameOrEmail !== lastAttemptedCredentials.usernameOrEmail || password !== lastAttemptedCredentials.password) &&
			usernameOrEmail &&
			password &&
			!turnstileToken
		) {
			setTurnstileResetKey((prevKey) => prevKey + 1);
		}
	}, [usernameOrEmail, password, lastAttemptedCredentials, turnstileToken]);

	const handleTurnstileVerify = (token: string | null) => {
		setTurnstileToken(token);
	};

	return (
		<RedirectIfAuthenticated>
			<div className="h-screen w-screen flex flex-col overflow-hidden bg-gray-900">
				<div className="flex-1 overflow-y-auto">
					{/* Page Background */}
					<div className="h-screen overflow-hidden bg-gray-900 flex items-center justify-center px-4 sm:px-6 lg:px-8 relative">
						{/* Home Icon */}
						<button
							onClick={() => navigate('/')}
							className="absolute left-4 bottom-4 text-gray-500 opacity-50 hover:opacity-100 transition-opacity z-20 p-2 rounded-full hover:bg-white/10"
							aria-label="Home"
						>
							<FaHome size={24} />
						</button>
						{/* Background Image */}
						<div className="absolute inset-0 bg-gray-900">
							{/* Loading background */}
							<div
								className={`absolute inset-0 bg-gray-900 transition-opacity duration-500 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`}
							/>

							{/* Main image */}
							<img
								src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public"
								alt="Night soccer game under floodlights"
								className={`w-full h-full object-cover transition-opacity duration-500 ${
									imageLoaded ? 'opacity-100' : 'opacity-0'
								} select-none pointer-events-none`}
								draggable="false"
								onContextMenu={(e) => e.preventDefault()}
							/>
						</div>
						{/* Black overlay */}
						<div className="absolute inset-0 bg-black/30" />
						{/* Card Container */}
						<div className="bg-gray-50 rounded-lg shadow-xl p-6 max-w-md w-full relative z-10">
							{/* Page Title */}
							<h1 className="text-4xl font-bold tracking-tight mb-10 text-center text-neutral-900 select-none">Welcome to Kickoff</h1>

							{/* Sign In Form */}
							<form onSubmit={handleSignIn} className="">
								<div className="mb-4">
									<label htmlFor="usernameOrEmail" className="sr-only select-none">
										Username or Email
									</label>
									<input
										id="usernameOrEmail"
										name="usernameOrEmail"
										type="text"
										autoComplete="username"
										required
										value={usernameOrEmail}
										onChange={(e) => setUsernameOrEmail(e.target.value)}
										className="w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100"
										placeholder="Username or Email"
									/>
								</div>
								<div className="mb-4">
									<label htmlFor="password" className="sr-only select-none">
										Password
									</label>
									<input
										id="password"
										name="password"
										type="password"
										autoComplete="current-password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										className="w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100"
										required
										placeholder="Password"
									/>
								</div>

								{/* Turnstile container matches input styling to avoid layout shift */}
								<div className="mb-1 min-h-[72px]">
									<Turnstile siteKey="0x4AAAAAAAjuZz4sYx8SxbrU" onTokenChange={handleTurnstileVerify} resetKey={turnstileResetKey} />
								</div>

								{/* Error Message Container - Always present */}
								<div className="h-4 mb-1">{errorMessage && <div className="text-xs font-semibold text-rose-600 select-none">{errorMessage}</div>}</div>

								<button
									type="submit"
									disabled={!turnstileToken}
									className={`w-full px-6 py-2 font-medium rounded-lg transition-all duration-500 ease-in-out text-medium select-none
                					${
									turnstileToken
										? 'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-300'
										: 'bg-gray-300 text-gray-500 cursor-not-allowed'
									}`}
								>
									Sign In
								</button>
							</form>

							{/* Forgot Password Button */}
							<div className="text-center mb-6">
								<button
									onClick={() => navigate('/forgot-password')}
									className="text-xs text-indigo-600 hover:text-indigo-800 hover:underline font-light select-none transition-colors"
								>
									Forgot your password?
								</button>
							</div>

							{/* Sign Up Section */}
							<div className="text-center mb-4">
								<p className="text-sm select-none">
									Don&apos;t have an account?{' '}
									<button
										onClick={() => navigate('/signup')}
										className="text-indigo-600 hover:text-indigo-800 hover:underline font-semibold select-none transition-colors"
									>
										Sign Up
									</button>
								</p>
							</div>

							{/* Terms and Conditions */}
							<div className="text-center text-xs text-gray-500 select-none">
								By signing in, you agree to our{' '}
								<button
									onClick={() => setShowTerms(true)}
									className="text-indigo-600 hover:text-indigo-800 hover:underline select-none transition-colors"
								>
									Terms and Conditions
								</button>
							</div>
						</div>
					</div>

					{/* Terms Modal */}
					<Modal isOpen={showTerms} onClose={() => setShowTerms(false)} title="Terms & Conditions">
						<div className="max-h-[70vh] overflow-y-auto">
							<TermsAndConditions />
						</div>
					</Modal>
				</div>
			</div>
		</RedirectIfAuthenticated>
	);
};

export default SignIn;
