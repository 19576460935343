import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import { User } from '../models/user';
import { Link } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

const SearchBar: React.FC<{ className?: string; dropUp?: boolean }> = ({ className = '', dropUp = false }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Array<User & { resultType: string }>>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults([]);
      setShowDropdown(false);
      return;
    }

    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      User.searchUsers(searchQuery).then((results: User[]) => {
        const resultsWithType = results.map(result => ({
          ...result,
          resultType: 'user'
        })) as Array<User & { resultType: string }>;
        
        setSearchResults(dropUp ? [...resultsWithType].reverse() : resultsWithType);
        setLoading(false);
        setShowDropdown(true);
      }).catch(() => {
        setLoading(false);
      });
    }, 300);

    return () => {
      clearTimeout(delayDebounceFn);
      setLoading(false);
    };
  }, [searchQuery, dropUp]);

  return (
    <div className={`relative isolate ${className}`}>
      <div className="relative">
        <input
          type="search"
          inputMode="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          className="w-full py-1.5 sm:py-1 px-3.5 sm:px-3 lg:px-6 pl-10 focus:outline-none rounded-xl sm:rounded-md text-[16px] sm:text-base font-sans text-gray-900 bg-gray-200 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => {
            e.stopPropagation();
            setSearchQuery(e.target.value);
          }}
          onFocus={(e) => {
            e.stopPropagation();
            if (searchQuery.trim() !== '') {
              setShowDropdown(true);
            }
          }}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
          style={{
            WebkitAppearance: 'none',
            touchAction: 'manipulation'
          }}
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none md:hidden lg:hidden" />
      </div>
      {showDropdown && (
        <div className="relative">
          <div 
            className={`absolute left-0 right-0 ${dropUp ? 'bottom-full mb-12' : 'mt-2'} bg-white border border-gray-200 rounded-lg shadow-lg z-50 max-h-[60vh] overflow-y-auto`}
            style={{ 
              maxHeight: dropUp ? 'calc(100vh - 250px)' : '60vh'
            }}
          >
            {loading ? (
              <div className="flex items-center justify-center py-4">
                <LoadingSpinner size={32} colorClass="text-indigo-600" />
              </div>
            ) : searchResults.length > 0 ? (
              searchResults.map((result, index) => (
                <Link
                  to={`/u/${result.username}`}
                  key={index}
                  className="px-4 py-3 hover:bg-gray-50 cursor-pointer flex items-center gap-3 border-b border-gray-100 last:border-b-0"
                  onClick={() => setShowDropdown(false)}
                >
                  <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center flex-shrink-0">
                    {result.profile_picture_url ? (
                      <img
                        src={result.profile_picture_url}
                        alt={result.username}
                        className="w-10 h-10 rounded-full object-cover"
                      />
                    ) : (
                      <span className="text-gray-500 text-sm">
                        {result.username?.charAt(0).toUpperCase()}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col min-w-0">
                    <span className="text-gray-800 font-medium truncate">{result.username}</span>
                    {(result.first_name || result.last_name) && (
                      <span className="text-gray-500 text-sm truncate">
                        {[result.first_name, result.last_name].filter(Boolean).join(' ')}
                      </span>
                    )}
                  </div>
                </Link>
              ))
            ) : (
              <div className="px-4 py-3 text-gray-500 text-center">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
