/**
 * UserFriendsCard Component
 * 
 * This component displays the user's friends list.
 * 
 * Behavior:
 * 1. Shows a skeleton loader (UserFriendsCardSkeleton) if loading is true.
 * 2. Displays the full profile card when all data is available.
 * 3. Automatically updates when friends list changes.
 */

import React, { memo, useMemo, useState } from 'react';
import { FaUserCircle, FaSearch } from 'react-icons/fa';
import { User } from '../models/user';
import UserFriendsCardSkeleton from './UserFriendsCardSkeleton';
import { useImageLoader } from '../hooks/useImageLoader';
import { Link } from 'react-router-dom';

interface UserFriendsCardProps {
  friends: User[];
  loading?: boolean;
  emptyTitle?: string;
  emptyMessage?: string;
}

const FriendListItem: React.FC<{ friend: User }> = memo(({ friend }) => {
  const profileImage = useImageLoader(friend.profile_picture_url || null);

  return (
    <li className="space-x-4 space-y-2">
      <Link
        to={`/u/${friend.username}`}
        className="flex items-center py-2 rounded-lg w-full transition-colors duration-200 touch-manipulation min-h-[72px] space-x-4"
      >
        {profileImage ? (
          <img
            src={profileImage}
            alt={`${friend.first_name} ${friend.last_name}`}
            className="w-12 h-12 rounded-full object-cover"
          />
        ) : (
          <FaUserCircle className="w-12 h-12 text-gray-400 bg-white rounded-full" />
        )}
        <div className="min-w-0 flex-1">
          <p
            className="text-base font-medium text-gray-700 truncate"
            title={`${friend.first_name} ${friend.last_name}`}
          >
            {friend.first_name == "" ? friend.username : `${friend.first_name} ${friend.last_name}`}
          </p>
          <p
            className="text-xs text-gray-400 truncate mt-1"
            title={`@${friend.username}`}
          >
            @{friend.username}
          </p>
        </div>
      </Link>
    </li>
  );
});

FriendListItem.displayName = 'FriendListItem';

const SearchBar: React.FC<{
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}> = memo(({ value, onChange, disabled }) => (
  <div className="relative py-1">
    <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center pointer-events-none">
      <FaSearch className={`h-4 w-4 md:hidden lg:hidden ${disabled ? 'text-gray-300' : 'text-gray-400'}`} />
    </div>
    <input
      type="text"
      placeholder="Search friends..."
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      className={`w-full py-1.5 sm:py-1 px-3.5 sm:px-3 pl-10 
                focus:outline-none rounded-xl sm:rounded-md 
                text-[16px] sm:text-base font-sans
                ${disabled 
                  ? 'bg-gray-50 text-gray-400 cursor-not-allowed' 
                  : 'text-gray-900 bg-gray-200 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500'
                }`}
    />
  </div>
));

SearchBar.displayName = 'SearchBar';

const EmptyFriendsList: React.FC<{ isFiltered?: boolean; title?: string; message?: string }> = ({ isFiltered, title, message }) => (
  <div className="flex flex-col items-center justify-center py-8 px-4 text-center">
    <FaUserCircle className="w-16 h-16 text-gray-300 mb-4" />
    <h3 className="text-gray-700 font-medium mb-2">
      {isFiltered ? 'No Matching Friends' : title || 'No Friends Yet'}
    </h3>
    <p className="text-gray-500 text-sm max-w-xs">
      {isFiltered 
        ? 'Try adjusting your search terms'
        : message || 'Start connecting with other players to build your friend list!'}
    </p>
  </div>
);

const FriendsList: React.FC<{ friends: User[]; searchQuery: string; emptyTitle?: string; emptyMessage?: string }> = memo(({ friends, searchQuery, emptyTitle, emptyMessage }) => {
  const filteredFriends = useMemo(() => {
    if (!searchQuery) return friends;
    
    const query = searchQuery.toLowerCase();
    return friends.filter(friend => 
      friend.first_name.toLowerCase().includes(query) ||
      friend.last_name.toLowerCase().includes(query) ||
      friend.username.toLowerCase().includes(query)
    );
  }, [friends, searchQuery]);

  if (friends.length === 0) {
    return <EmptyFriendsList title={emptyTitle} message={emptyMessage} />;
  }

  if (filteredFriends.length === 0) {
    return <EmptyFriendsList isFiltered />;
  }

  return (
    <ul className="py-2">
      {filteredFriends.map((friend) => (
        friend && <FriendListItem key={friend.user_id} friend={friend} />
      ))}
    </ul>
  );
});

FriendsList.displayName = 'FriendsList';

const UserFriendsCard: React.FC<UserFriendsCardProps> = memo(({ friends, loading, emptyTitle, emptyMessage }) => {
  const [searchQuery, setSearchQuery] = useState('');

  if (loading) {
    return <UserFriendsCardSkeleton />;
  }

  const hasFriends = friends.length > 0;

  return (
    <div className="sm:bg-white mg:bg-gray-50 lg:bg-gray-50 sm:overflow-hidden">
      <div className="space-y-1 sm:space-y-4">
        {hasFriends && <SearchBar value={searchQuery} onChange={setSearchQuery} />}
      </div>
      <div className="space-y-2">
        <FriendsList 
          friends={friends} 
          searchQuery={searchQuery} 
          emptyTitle={emptyTitle} 
          emptyMessage={emptyMessage} 
        />
      </div>
    </div>
  );
});

export default UserFriendsCard;
