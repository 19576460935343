import { useEffect, useState, useMemo, useCallback } from 'react';
import { User, Friend } from '../models/user';
import { retryWithBackoff } from '../utils/retry';

export function useFriends(targetUserId?: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [friendStatus, setFriendStatus] = useState<Friend | null>(null);

  const getFriendStatus = useCallback(async () => {
    if (!targetUserId) {
      setFriendStatus(null);
      setError(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const status = await retryWithBackoff(async () => {
        return await User.getFriendStatus(targetUserId);
      });
      setFriendStatus(status);
      setError(null);
    } catch (err) {
      setError('Failed to get friend status');
      setFriendStatus(null);
    } finally {
      setLoading(false);
    }
  }, [targetUserId]);

  useEffect(() => {
    getFriendStatus();
  }, [getFriendStatus]);

  return useMemo(() => ({
    loading,
    error,
    friendStatus,
    refresh: getFriendStatus
  }), [loading, error, friendStatus, getFriendStatus]);
}
