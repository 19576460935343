import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';
import LoadingButton from '../components/LoadingButton';
import FormField from '../components/FormField';
import {
  FaUsers,
  FaMapMarkerAlt,
  FaGlobe,
  FaLock,
  FaUserFriends,
  FaInfoCircle,
  FaUpload,
  FaCheckCircle,
  FaTimesCircle,
} from 'react-icons/fa';
import 'react-day-picker/dist/style.css';
import LocationPicker from '../components/LocationPicker';
import { Team } from '../models/teams';
import { useUser } from '../contexts/UserContext';
import ImageUploader from '../components/ImageUploader';

const CreateTeamPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [teamName, setTeamName] = useState('');
  const [description, setDescription] = useState('');
  const [homeCity, setHomeCity] = useState('');
  const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(null);
  const [maxMembers, setMaxMembers] = useState<number>(20);
  const [isPublic, setIsPublic] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoUploadError, setLogoUploadError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      if (!user) {
        throw new Error('User not authenticated');
      }

      const team = await Team.create({
        name: teamName,
        description,
        home_city: homeCity,
        coordinates,
        max_members: maxMembers,
        is_public: isPublic,
        owner_id: user.user_id,
        logo_url: logoUrl,
      });

      navigate(`/team/${team.team_id}`);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create team');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadTeamImage = async (file: File): Promise<string> => {
    try {
      const url = await Team.uploadTeamImage(file);
      setLogoUrl(url);
      setLogoUploadError('');
      return url;
    } catch (error) {
      setLogoUploadError(error.message);
      throw error;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <TopNavBar />

      <div className="container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Create Your Team</h2>
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              {/* Team Name */}
              <FormField label="Team Name" icon={<FaUsers className="text-gray-400" />}>
                <input
                  type="text"
                  className="w-full focus:outline-none rounded-md p-2 border border-gray-300"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  placeholder="Enter team name"
                />
              </FormField>

              {/* Team Logo */}
              <FormField label="Team Logo" icon={<FaUpload className="text-gray-400" />}>
                <ImageUploader
                  onUploadSuccess={(url) => {
                    setLogoUrl(url);
                    setLogoUploadError('');
                  }}
                  onUploadError={(error) => setLogoUploadError(error)}
                  uploadFunction={handleUploadTeamImage}
                />
                {logoUrl && (
                  <div className="mt-2 flex items-center text-green-600">
                    <FaCheckCircle className="mr-2" />
                    <span>Logo uploaded successfully!</span>
                  </div>
                )}
                {logoUploadError && (
                  <div className="mt-2 flex items-center text-red-600">
                    <FaTimesCircle className="mr-2" />
                    <span>{logoUploadError}</span>
                  </div>
                )}
              </FormField>

              {/* Description */}
              <FormField label="Description" icon={<FaInfoCircle className="text-gray-400" />}>
                <textarea
                  className="w-full p-3 focus:outline-none rounded-md border border-gray-300"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                  placeholder="Tell us about your team"
                />
              </FormField>

              {/* Home City */}
              <FormField label="Home City" icon={<FaMapMarkerAlt className="text-gray-400" />}>
                <input
                  type="text"
                  className="w-full focus:outline-none rounded-md p-2 border border-gray-300"
                  value={homeCity}
                  onChange={(e) => setHomeCity(e.target.value)}
                  placeholder="Enter home city"
                />
              </FormField>

              {/* Max Members */}
              <FormField label="Maximum Members" icon={<FaUserFriends className="text-gray-400" />}>
                <input
                  type="number"
                  className="w-full focus:outline-none rounded-md p-2 border border-gray-300"
                  value={maxMembers}
                  onChange={(e) => setMaxMembers(parseInt(e.target.value) || 1)}
                  placeholder="Enter maximum number of members"
                  min={1}
                />
              </FormField>

              {/* Visibility */}
              <div className="mt-6">
                <label className="block text-gray-700 font-medium mb-2">Team Visibility</label>
                <div className="flex items-center space-x-6">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="visibility"
                      value="true"
                      checked={isPublic}
                      onChange={() => setIsPublic(true)}
                      className="form-radio text-indigo-600"
                    />
                    <FaGlobe className="ml-2 text-gray-400" />
                    <span className="ml-1">Public</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="visibility"
                      value="false"
                      checked={!isPublic}
                      onChange={() => setIsPublic(false)}
                      className="form-radio text-indigo-600"
                    />
                    <FaLock className="ml-2 text-gray-400" />
                    <span className="ml-1">Unlisted</span>
                  </label>
                </div>
                <p className="text-gray-500 mt-2">
                  Public teams are visible to everyone. Unlisted teams are hidden from search results.
                </p>
              </div>

              {/* Error Message */}
              {error && <p className="text-red-500 mt-2">{error}</p>}

              {/* Create Button */}
              <div className="mt-6">
                <LoadingButton
                  isLoading={isLoading}
                  onClick={() => handleSubmit}
                  aria-label="Creating..."
                  buttonClass={`w-full ${
                    isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                  } text-white py-3 rounded-full transition duration-300`}
                  disabled={isLoading}
                >
                  Create Team
                </LoadingButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateTeamPage;