/**
 * useImageLoader Hook with Persistent Cache
 *
 * This hook manages the loading and caching of images, providing optimized
 * performance and offline capabilities.
 *
 * Functionality:
 * 1. Immediately returns cached image data if available and not expired.
 * 2. Asynchronously loads and caches new images when needed.
 * 3. Caches images in-memory as base64-encoded strings.
 * 4. Implements a cache expiration mechanism (24 hours by default).
 *
 * Usage:
 * const loadedUrl = useImageLoader(url);
 *
 * - url: The source URL of the image to load
 * - loadedUrl: The loaded image data as a base64-encoded string, or null if not loaded
 *
 * The hook will cause a re-render when:
 * - The initial cached image is retrieved
 * - A new image is loaded and cached
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, a new image is fetched and cached
 * - Cache expiration is set to 24 hours by default
 *
 * Note: This hook uses an in-memory cache for caching, so it's subject
 * to storage limitations and should be used for appropriately sized images.
 */

import { useState, useEffect } from 'react';

// Constants for cache management
const MAX_CACHE_SIZE = 4.5 * 1024 * 1024; // 4.5MB max cache size
const MAX_IMAGE_SIZE = 100 * 1024; // 100KB per image
const CACHE_PREFIX = 'img_cache_';
const CACHE_META_KEY = 'img_cache_meta';

interface CacheMetadata {
    size: number;
    lastAccessed: number;
    urls: string[];
}

// Helper functions to interact with localStorage
const loadFromLocalStorage = (key: string): string | null => {
    try {
        const data = localStorage.getItem(CACHE_PREFIX + key);
        if (data) {
            updateCacheMetadata(key, true);
        }
        return data;
    } catch (error) {
        console.error('Failed to load from localStorage:', error);
        return null;
    }
};

const getCacheMetadata = (): CacheMetadata => {
    try {
        const meta = localStorage.getItem(CACHE_META_KEY);
        return meta ? JSON.parse(meta) : { size: 0, lastAccessed: Date.now(), urls: [] };
    } catch {
        return { size: 0, lastAccessed: Date.now(), urls: [] };
    }
};

const updateCacheMetadata = (url: string, access = false) => {
    const meta = getCacheMetadata();
    if (access) {
        meta.lastAccessed = Date.now();
        if (!meta.urls.includes(url)) {
            meta.urls.push(url);
        }
    }
    localStorage.setItem(CACHE_META_KEY, JSON.stringify(meta));
};

const evictCache = (requiredSpace: number) => {
    const meta = getCacheMetadata();
    let freedSpace = 0;
    
    // Sort URLs by last accessed time
    meta.urls.sort((a, b) => {
        const aData = localStorage.getItem(CACHE_PREFIX + a);
        const bData = localStorage.getItem(CACHE_PREFIX + b);
        return (aData?.length || 0) - (bData?.length || 0);
    });

    // Remove items until we have enough space
    while (freedSpace < requiredSpace && meta.urls.length > 0) {
        const url = meta.urls.shift();
        if (url) {
            const item = localStorage.getItem(CACHE_PREFIX + url);
            if (item) {
                freedSpace += item.length;
                localStorage.removeItem(CACHE_PREFIX + url);
            }
        }
    }

    meta.size = Math.max(0, meta.size - freedSpace);
    localStorage.setItem(CACHE_META_KEY, JSON.stringify(meta));
    return freedSpace;
};

const compressImage = (canvas: HTMLCanvasElement, maxSize: number): string => {
    let quality = 0.9;
    let base64Data = canvas.toDataURL('image/jpeg', quality);
    
    // Gradually reduce quality until the size is under maxSize
    while (base64Data.length > maxSize && quality > 0.1) {
        quality -= 0.1;
        base64Data = canvas.toDataURL('image/jpeg', quality);
    }
    
    return base64Data;
};

const saveToLocalStorage = (key: string, data: string) => {
    try {
        const size = data.length;
        
        // Check if we need to evict some items
        if (size > MAX_IMAGE_SIZE) {
            console.warn('Image too large, compressing');
            return false;
        }
        
        const meta = getCacheMetadata();
        const requiredSpace = size - (MAX_CACHE_SIZE - meta.size);
        
        if (requiredSpace > 0) {
            const freedSpace = evictCache(requiredSpace);
            if (freedSpace < requiredSpace) {
                console.warn('Not enough space available in cache');
                return false;
            }
        }
        
        localStorage.setItem(CACHE_PREFIX + key, data);
        meta.size += size;
        meta.urls.push(key);
        localStorage.setItem(CACHE_META_KEY, JSON.stringify(meta));
        return true;
    } catch (error) {
        console.error('Failed to save to localStorage:', error);
        return false;
    }
};

export const useImageLoader = (imageUrl: string | null) => {
    const [loadedImage, setLoadedImage] = useState<string | null>(loadFromLocalStorage(imageUrl));

    useEffect(() => {
        if (!imageUrl) {
            setLoadedImage(null);
            return;
        }

        // Check if the image is cached in localStorage
        const cachedImage = loadFromLocalStorage(imageUrl);
        if (cachedImage) {
            if (cachedImage !== loadedImage) {
                setLoadedImage(cachedImage);
            }
            return;
        }

        // Fetch and cache the image
        const img = new Image();
        img.crossOrigin = 'anonymous'; // Enable CORS
        img.src = imageUrl;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            
            try {
                let base64Data = compressImage(canvas, MAX_IMAGE_SIZE);
                
                // Save to localStorage
                if (base64Data !== loadFromLocalStorage(imageUrl)) {
                    if (saveToLocalStorage(imageUrl, base64Data)) {
                        setLoadedImage(base64Data);
                    } else {
                        // If storage failed, still show the image but don't cache it
                        setLoadedImage(base64Data);
                    }
                }
            } catch (error) {
                console.error('Error processing image:', error);
                setLoadedImage(null);
            }
        };
        
        img.onerror = () => {
            setLoadedImage(null);
        };
    }, [imageUrl]);

    return loadedImage;
};
