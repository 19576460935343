export async function retryWithBackoff<T>(
    fn: () => Promise<T>,
    maxRetries: number = 5,
    maxBackoff: number = 30000, // 30 seconds
    initialBackoff: number = 1000 // 1 second
): Promise<T> {
    let retries = 0;
    let backoff = initialBackoff;

    while (true) {
        try {
            return await fn();
        } catch (error) {
            retries++;
            if (retries >= maxRetries) {
                throw error;
            }

            // Calculate next backoff with exponential increase, but cap at maxBackoff
            backoff = Math.min(backoff * 2, maxBackoff);

            // Wait for the backoff period
            await new Promise(resolve => setTimeout(resolve, backoff));
        }
    }
}
