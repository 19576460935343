/**
 * Utility function to clear all application state
 * This includes localStorage, sessionStorage, and any other client-side storage
 */
export const clearAllState = () => {
    // Clear localStorage
    localStorage.clear();
    
    // Clear sessionStorage
    sessionStorage.clear();
    
    // Clear any cookies (except those marked httpOnly)
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
        const name = cookie.split('=')[0].trim();
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });

    // Reset API client state
    try {
        const { apiClient } = require('../models/api');
        apiClient.setAccessToken(null);
        apiClient.setRefreshToken(null);
    } catch (error) {
        console.error('Failed to reset API client state:', error);
    }
};
