import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { User } from '../models/user';
import { clearAllState } from '../utils/clearState';
import { FaCheckCircle, FaExclamationTriangle, FaHome } from 'react-icons/fa';
import Turnstile from '../components/Turnstile';

const Verify: React.FC = () => {
  const [verificationStatus, setVerificationStatus] = useState<'pending' | 'loading' | 'success' | 'error'>('pending');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isVisible, setIsVisible] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [turnstileResetKey, setTurnstileResetKey] = useState(0);
  const location = useLocation();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleVerification = async () => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    if (!code) {
      setVerificationStatus('error');
      setErrorMessage('Invalid verification code.');
      return;
    }

    if (!turnstileToken) {
      setErrorMessage('Please complete the Turnstile challenge.');
      return;
    }

    setVerificationStatus('loading');
    setErrorMessage('');

    try {
      const response = await User.verifyEmail(code, turnstileToken);
      clearAllState();
      setVerificationStatus('success');
      setTimeout(() => {
        window.location.href = '/signin';
      }, 3000);
    } catch (error: any) {
      setVerificationStatus('error');
      if (error.response?.status === 400) {
        setErrorMessage('Invalid or expired verification code. Please request a new one.');
      } else if (error.response?.status === 409) {
        setErrorMessage('Email already verified. Please sign in.');
        setTimeout(() => {
          window.location.href = '/signin';
        }, 3000);
      } else if (error.response?.status === 429) {
        setErrorMessage('Too many attempts. Please try again later.');
      } else {
        console.error('Verification error:', error.message || error);
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
      // Reset turnstile only on error
      setTurnstileToken(null);
      setTurnstileResetKey((prevKey) => prevKey + 1);
    }
  };

  // Start verification when turnstile token is available
  useEffect(() => {
    if (turnstileToken && verificationStatus === 'pending') {
      handleVerification();
    }
  }, [turnstileToken]);

  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden bg-gray-900">
      <div className="flex-1 overflow-y-auto">
        {/* Page Background */}
        <div className="h-screen overflow-hidden bg-gray-900 flex items-center justify-center px-4 sm:px-6 lg:px-8 relative">
          {/* Home Icon */}
          <button
            onClick={() => window.location.href = '/'}
            className="absolute left-4 bottom-4 text-gray-500 opacity-50 hover:opacity-100 transition-opacity z-20 p-2 rounded-full hover:bg-white/10"
            aria-label="Home"
          >
            <FaHome size={24} />
          </button>

          {/* Background Image */}
          <div className="absolute inset-0 bg-gray-900">
            {/* Loading background */}
            <div className={`absolute inset-0 bg-gray-900 transition-opacity duration-500 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
            
            {/* Main image */}
            <img
              src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public"
              alt="Night soccer game under floodlights"
              className={`w-full h-full object-cover transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'} select-none pointer-events-none`}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
              onLoad={() => setImageLoaded(true)}
            />
          </div>

          {/* Black overlay */}
          <div className="absolute inset-0 bg-black/30" />

          {/* Card Container */}
          <div className="bg-gray-50 rounded-lg shadow-xl p-6 max-w-md w-full relative z-10">
            <h1 className="text-4xl font-bold tracking-tight mb-10 text-center text-neutral-900 select-none">Email Verification</h1>
            
            {/* Content container with fixed height to prevent layout shifts */}
            <div className="h-[200px] flex items-center justify-center">
              <div className={`transition-opacity duration-500 w-full ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                {verificationStatus === 'pending' && (
                  <div className="flex flex-col items-center">
                    <div className="mb-4">
                      <Turnstile 
                        siteKey="0x4AAAAAAAjuZz4sYx8SxbrU" 
                        onTokenChange={(token) => setTurnstileToken(token)}
                        resetKey={turnstileResetKey}
                      />
                    </div>
                    {errorMessage && (
                      <p className="text-sm text-red-500 text-center mb-4 select-none">{errorMessage}</p>
                    )}
                    <p className="text-base text-gray-600 select-none">Please complete the verification challenge.</p>
                  </div>
                )}

                {verificationStatus === 'loading' && (
                  <div className="flex flex-col items-center">
                    <div className="w-16 h-16 mb-4 relative">
                      <div className="absolute inset-0 rounded-full border-4 border-indigo-100"></div>
                      <div className="absolute inset-0 rounded-full border-4 border-indigo-500 border-t-transparent animate-spin"></div>
                    </div>
                    <p className="text-base text-gray-600 select-none">Verifying your email...</p>
                  </div>
                )}

                {verificationStatus === 'success' && (
                  <div className="flex flex-col items-center">
                    <FaCheckCircle className="text-5xl text-indigo-500 mb-4" />
                    <h2 className="text-xl font-semibold mb-2 text-gray-800 select-none">Email Verified Successfully!</h2>
                    <p className="text-base text-gray-600 text-center select-none">
                      Your email has been verified
                    </p>
                  </div>
                )}

                {verificationStatus === 'error' && (
                  <div className="flex flex-col items-center">
                    <FaExclamationTriangle className="text-5xl text-red-500 mb-4" />
                    <h2 className="text-xl font-semibold mb-2 text-gray-800 select-none">Verification Failed</h2>
                    <p className="text-xs text-gray-500 text-center mb-4 select-none">{errorMessage}</p>
                    <button
                      onClick={() => window.location.href = '/signin'}
                      className="w-full px-6 py-2 font-medium rounded-lg bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-300 transition-all duration-500 ease-in-out select-none"
                    >
                      Go to Sign In
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
