import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FaComments,
  FaChevronLeft,
  FaChevronRight,
  FaSpinner,
  FaUserCircle,
  FaSearch
} from 'react-icons/fa';
import { Chatroom } from '../models/chatroom';
import { User } from '../models/user';
import { useIsMobile } from '../hooks/useIsMobile';

interface ChatListProps {
  chatrooms: Chatroom[];
  loading?: boolean;
  error?: Error;
  currentUserProfile: User;
  otherProfiles: { [key: string]: User };
  className?: string;
  style?: React.CSSProperties;
}

const SearchBar: React.FC<{
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}> = memo(({ value, onChange, disabled }) => (
  <div className="relative py-2">
    <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center pointer-events-none">
      <FaSearch className={`md:hidden lg:hidden h-4 w-4 ${disabled ? 'text-gray-300' : 'text-gray-400'}`} />
    </div>
    <input
      type="text"
      placeholder="Search chats..."
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      className={`w-full py-1.5 sm:py-2 px-3.5 sm:px-3 pl-10 
                focus:outline-none rounded-xl sm:rounded-md 
                text-[16px] sm:text-base font-sans
                ${disabled 
                  ? 'bg-gray-50 text-gray-400 cursor-not-allowed' 
                  : 'text-gray-900 bg-gray-200 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500'
                }`}
    />
  </div>
));

SearchBar.displayName = 'SearchBar';

const ChatList: React.FC<ChatListProps> = ({
  chatrooms,
  loading,
  error,
  currentUserProfile,
  otherProfiles,
  className = '',
  style
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const isMobile = useIsMobile();

  const getChatDisplayName = (chatroom: Chatroom): string => {
    if (
      chatroom.chatroom_type === 'direct_message' &&
      currentUserProfile?.user_id
    ) {
      const otherUserId = Object.keys(chatroom.members).find(
        (id) => id !== currentUserProfile?.user_id
      );
      if (!otherUserId) return 'Unknown User';
      const otherProfile = otherProfiles[otherUserId];
      return otherProfile?.username || 'Unknown User';
    }
    return chatroom.name || 'Unnamed Chat';
  };

  const filteredChatrooms = useMemo(() => {
    if (!chatrooms) return [];
    if (!searchQuery.trim()) {
      return [...chatrooms].sort((a, b) => {
        const timeA = a.last_message_timestamp || '0';
        const timeB = b.last_message_timestamp || '0';
        return timeB.localeCompare(timeA);
      });
    }
    
    const query = searchQuery.toLowerCase().trim();
    return chatrooms
      .filter(chatroom => {
        const displayName = getChatDisplayName(chatroom).toLowerCase();
        return displayName.includes(query);
      })
      .sort((a, b) => {
        const timeA = a.last_message_timestamp || '0';
        const timeB = b.last_message_timestamp || '0';
        return timeB.localeCompare(timeA);
      });
  }, [chatrooms, searchQuery, getChatDisplayName, currentUserProfile?.user_id, otherProfiles]);

  if (!currentUserProfile) return null;

  const areAllProfilesLoaded = (): boolean => {
    if (!chatrooms) return true;
    return chatrooms.every(chatroom => {
      if (chatroom.chatroom_type === 'direct_message') {
        const otherUserId = Object.keys(chatroom.members).find(
          (id) => id !== currentUserProfile.user_id
        );
        return otherUserId ? !!otherProfiles[otherUserId] : true;
      }
      return true;
    });
  };

  const hasUnreadMessages = (chatroom: Chatroom): boolean => {
    if (!currentUserProfile?.user_id) return false;
    
    // If we're currently viewing this chat, consider it read
    if (location.pathname === `/chat/${chatroom.chatroom_id}`) {
      return false;
    }
    
    const currentUserMember = chatroom.members[currentUserProfile.user_id];
    const lastReadMessage = currentUserMember?.last_read_message || '';
    return Object.values(chatroom.members).some(
      (member) =>
        member.member_id !== currentUserProfile.user_id &&
        member.last_read_message > lastReadMessage
    );
  };

  const getChatAvatar = (chatroom: Chatroom): string | null => {
    if (
      chatroom.chatroom_type === 'direct_message' &&
      currentUserProfile?.user_id
    ) {
      const otherUserId = Object.keys(chatroom.members).find(
        (id) => id !== currentUserProfile.user_id
      );
      if (!otherUserId) return null;
      const otherProfile = otherProfiles[otherUserId];
      return otherProfile?.profile_picture_url || null;
    }
    return null;
  };

  const timeAgo = (timestamp: string | undefined): string => {
    if (!timestamp?.trim()) return '';
    if (timestamp === '0') return '';
    if (timestamp === '1970-01-01T00:00:00.000Z') return '';
    if (timestamp === '1970-01-01T00:00:00Z') return '';
    if (timestamp === '1970-01-01T00:00:00') return '';
    if (timestamp === '0001-01-01T00:00:00Z') return '';
    if (timestamp === '') return '';

    try {
      const timestampMs = Date.parse(timestamp);
      if (isNaN(timestampMs)) return '';
      
      const now = Date.now();
      const diff = Math.floor((now - timestampMs) / 1000);

      if (diff < 60) {
        return `${diff}s ago`;
      } else if (diff < 3600) {
        const minutes = Math.floor(diff / 60);
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      } else if (diff < 86400) {
        const hours = Math.floor(diff / 3600);
        return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      } else if (diff < 604800) {
        const days = Math.floor(diff / 86400);
        return `${days} day${days !== 1 ? 's' : ''} ago`;
      } else if (diff < 2419200) {
        const weeks = Math.floor(diff / 604800);
        return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
      } else if (diff < 29030400) {
        const months = Math.floor(diff / 2419200);
        return `${months} month${months !== 1 ? 's' : ''} ago`;
      } else {
        const years = Math.floor(diff / 29030400);
        return `${years} year${years !== 1 ? 's' : ''} ago`;
      }
    } catch {
      return '';
    }
  };

  return (
    <div
      className={`bg-gray-50 flex flex-col h-full ${
        isCollapsed ? 'w-16' : isMobile ? 'w-full' : 'w-80'
      } ${className} sm:bg-white sm:rounded-lg sm:shadow sm:overflow-hidden transition-all duration-300 ease-in-out`}
      style={style}
    >
      {/* Header and Search Section */}
      <div className="px-4 pt-6 space-y-1">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold text-gray-800 flex items-center">
            Messages
            {/* {chatrooms?.length > 0 && (
              <span className="ml-2 text-sm font-medium text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full">
                {chatrooms.length}
              </span>
            )} */}
          </h2>
          {/* {!isMobile && (
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
              className="ml-auto p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
            >
              {isCollapsed ? <FaChevronRight className="w-4 h-4" /> : <FaChevronLeft className="w-4 h-4" />}
            </button>
          )} */}
        </div>
        <div>
          <SearchBar value={searchQuery} onChange={setSearchQuery} disabled={false} />
        </div>
      </div>

      {/* Chat List Section */}
      {(!isCollapsed || isMobile) && (
        <div className="flex-1 overflow-y-auto">
          <div className="space-y-1 px-4 py-2">
            {(loading || !areAllProfilesLoaded()) ? (
              <div className="flex justify-center items-center h-20">
                <FaSpinner className="animate-spin text-blue-500" size={24} />
              </div>
            ) : error ? (
              <div className="text-red-500 p-4">{error.message}</div>
            ) : chatrooms?.length === 0 ? (
              <div className="text-gray-500 text-center p-4">
                No chats available
              </div>
            ) : filteredChatrooms.length === 0 ? (
              <div className="text-gray-500 text-center p-4">
                No chats match your search
              </div>
            ) : (
              <ul className="space-y-2">
                {filteredChatrooms
                  .filter((chatroom) => timeAgo(chatroom.last_message_timestamp))
                  .map((chatroom) => (
                    <li key={chatroom.chatroom_id}>
                      <Link
                        to={`/chat/${chatroom.chatroom_id}`}
                        className={`flex items-center py-2 rounded-lg relative ${
                          location.pathname === `/chat/${chatroom.chatroom_id}`
                            ? 'bg-blue-50 border border-blue-100'
                            : 'hover:bg-indigo-50 active:bg-indigo-100'
                        } ${isMobile ? 'min-h-[72px]' : ''}`}
                      >
                        {hasUnreadMessages(chatroom) && (
                          <div className="absolute w-2.5 h-2.5 bg-red-500 rounded-full top-4 right-3" />
                        )}
                        <div className="flex items-center space-x-4 flex-1 min-w-0">
                          <div
                            className={`${
                              isMobile ? 'w-12 h-12' : 'w-10 h-10'
                            } rounded-full bg-gray-100 flex items-center justify-center overflow-hidden flex-shrink-0`}
                          >
                            {getChatAvatar(chatroom) ? (
                              <img
                                src={getChatAvatar(chatroom) || ''}
                                alt={getChatDisplayName(chatroom)}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <FaUserCircle className="w-full h-full text-gray-400" />
                            )}
                          </div>
                          <div className="flex-1 min-w-0">
                            <div className="font-medium text-gray-700 truncate">
                              {getChatDisplayName(chatroom)}
                            </div>
                            {chatroom.last_message_timestamp && (
                              <div className="text-xs text-gray-400 mt-1">
                                {timeAgo(chatroom.last_message_timestamp)}
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export type { ChatListProps };
export default ChatList;
