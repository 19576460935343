import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import DebugOverlay from '../components/DebugOverlay';
import { useLocation } from 'react-router-dom';

export interface DebugEvent {
  type: string;
  target: string;
  timestamp: number;
  handler?: string;
  elementInfo?: {
    id?: string;
    className?: string;
    tagName: string;
  };
}

interface DebugContextType {
  events: DebugEvent[];
  logEvent: (event: DebugEvent) => void;
  clearEvents: () => void;
  isDebugEnabled: boolean;
}

const DebugContext = createContext<DebugContextType | null>(null);

export const useDebug = () => {
  const context = useContext(DebugContext);
  if (!context) {
    throw new Error('useDebug must be used within a DebugProvider');
  }
  return context;
};

export const DebugProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [events, setEvents] = useState<DebugEvent[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isDebugEnabled = searchParams.get('debug') === 'true';

  const logEvent = useCallback((event: DebugEvent) => {
    if (!isDebugEnabled) {
      return;
    }
    setEvents(prev => [...prev, event]);
  }, [isDebugEnabled]);

  const clearEvents = useCallback(() => {
    setEvents([]);
  }, []);

  const toggleVisibility = useCallback(() => {
    if (!isDebugEnabled) return;
    setIsVisible(prev => !prev);
  }, [isDebugEnabled]);

  useEffect(() => {
    if (!isDebugEnabled) {
      setIsVisible(false);
      setEvents([]);
      return;
    }

    const eventTypes = [
      'click', 'submit', 'change', 'input', 'focus', 'blur',
      'mouseenter', 'mouseleave', 'touchstart', 'touchend'
    ];

    const getElementInfo = (element: Element | null) => {
      if (!element) return {
        id: '',
        className: '',
        tagName: 'unknown'
      };
      return {
        id: element.id || '',
        className: element.className || '',
        tagName: element.tagName?.toLowerCase() || 'unknown',
      };
    };

    const findEventHandlers = (element: Element | null): string[] => {
      if (!element) return [];
      const handlers: string[] = [];
      try {
        const prototype = Object.getPrototypeOf(element);
        if (prototype) {
          const propertyNames = Object.getOwnPropertyNames(prototype);
          propertyNames.forEach(name => {
            if (name.startsWith('on')) {
              const handler = (element as any)[name];
              if (typeof handler === 'function') {
                handlers.push(name.slice(2));
              }
            }
          });
        }
      } catch (e) {
        console.error('Error finding event handlers:', e);
      }
      return handlers;
    };

    const handleEvent = (e: Event) => {
      if (!isDebugEnabled) return;

      try {
        const target = e.target as Element | null;
        const currentTarget = e.currentTarget as Element | null;
        if (!target || !currentTarget) return;

        const handlers = findEventHandlers(target);
        const elementInfo = getElementInfo(target);
        
        const targetTag = target.tagName?.toLowerCase() || 'unknown';
        const targetClass = target.className && typeof target.className === 'string' 
          ? '.' + target.className.split(' ')[0]
          : '';
        const currentTargetTag = currentTarget.tagName?.toLowerCase() || 'unknown';
        
        const eventData = {
          type: e.type,
          target: `${targetTag}${targetClass} inside ${currentTargetTag}`,
          timestamp: Date.now(),
          handler: handlers.length > 0 ? handlers.join(', ') : undefined,
          elementInfo
        };
        
        logEvent(eventData);
      } catch (e) {
        console.error('Error handling event:', e);
      }
    };

    eventTypes.forEach(type => {
      document.addEventListener(type, handleEvent, true);
    });

    return () => {
      eventTypes.forEach(type => {
        document.removeEventListener(type, handleEvent, true);
      });
    };
  }, [logEvent, isDebugEnabled]);

  return (
    <DebugContext.Provider value={{ events, logEvent, clearEvents, isDebugEnabled }}>
      {children}
      {isDebugEnabled && (
        <DebugOverlay 
          events={events}
          clearEvents={clearEvents}
          isVisible={isVisible}
          toggleVisibility={toggleVisibility}
          showToggleButton={isDebugEnabled}
        />
      )}
    </DebugContext.Provider>
  );
};

// Higher-order component to wrap event handlers with debug logging
export const withDebugEvents = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  componentName: string
) => {
  return (props: P) => {
    const { logEvent } = useDebug();

    const debugProps = { ...props };
    Object.keys(props).forEach(key => {
      if (key.startsWith('on') && typeof props[key as keyof P] === 'function') {
        const originalHandler = props[key as keyof P] as Function;
        debugProps[key as keyof P] = ((e: any) => {
          logEvent({
            type: key.slice(2).toLowerCase(),
            target: `${componentName} - ${e.target.tagName.toLowerCase()}${e.target.className ? '.' + e.target.className.split(' ')[0] : ''}`,
            timestamp: Date.now(),
            handler: key.slice(2)
          });
          return originalHandler(e);
        }) as any;
      }
    });

    return <WrappedComponent {...debugProps} />;
  };
};
