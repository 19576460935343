import React from 'react';

interface FormFieldProps {
  label: string;
  icon?: React.ReactNode; 
  error?: string;
  children: React.ReactNode;
}

const FormField: React.FC<FormFieldProps> = ({ label, icon, error, children }) => {
  return (
    <div className="mb-4 sm:mb-2">
      <label className="block text-[13px] sm:text-sm font-normal text-gray-400 mb-1 sm:mb-0.5 px-0.5">
        {label}
      </label>
      <div className="relative isolate">
        {icon && (
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-200 text-md sm:text-md pointer-events-none">
            {icon}
          </div>
        )}

        {React.cloneElement(children as React.ReactElement, {
          className: `
            w-full
            bg-gray-100
            rounded-md
            pl-9
            pr-2
            py-2
            focus:outline-none
            focus:bg-gray-50
            transition
            duration-300
            text-gray-600
            text-[15px]
            sm:text-md
            text-left
            ${
              error
                ? 'border border-red-500 focus:ring-2 focus:ring-red-400'
                : 'focus:ring-2 focus:ring-indigo-500'
            }
          `,
          style: {
            WebkitAppearance: 'none',
            touchAction: 'manipulation',
          },
        })}
      </div>

      {error && <p className="mt-1 text-[13px] text-red-500 px-0.5">{error}</p>}
    </div>
  );
};

export default FormField;
