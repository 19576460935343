import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
  FaUser,
  FaUserPlus,
  FaHourglassHalf,
  FaUserFriends,
  FaCog,
  FaCalendarAlt,
  FaHistory,
  FaComments,
  FaUserCheck,
} from 'react-icons/fa';

import { User } from '../models/user';
import { ChatroomService } from '../models/chatroom';
import TopNavBar from '../components/TopNavBar';
import UserSettingsModal from '../components/UserSettingsModal';

import { useAuth } from '../hooks/useAuth';
import { useFriendRequests } from '../hooks/useFriendRequests';
import { useFriends } from '../hooks/useFriends';
import { useOtherUserGames } from '../hooks/useOtherUserGames';
import { useOtherUserProfile } from '../hooks/useOtherUserProfile';

// Cache for current user data
const currentUserCache = {
  data: null as User | null,
  timestamp: 0,
  TTL: 5 * 60 * 1000 // 5 minutes
};

const UserProfilePage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const navigate = useNavigate();
  const { signOut, isAuthenticated, user: authUser } = useAuth();

  // Core state
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [initialLoading, setInitialLoading] = useState(true);

  // Use the hook with isAuthenticated
  const { userProfile: fetchedUser, isLoading: userLoading, error: userError } = useOtherUserProfile(user?.user_id, isAuthenticated);

  // UI state
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [showUnfriendConfirm, setShowUnfriendConfirm] = useState(false);

  // Action states
  const [sendingFriendRequest, setSendingFriendRequest] = useState(false);
  const [acceptingFriendRequest, setAcceptingFriendRequest] = useState(false);

  // Friend data hooks
  const {
    incomingRequests,
    sentRequests,
    loading: requestsLoading,
    refresh: refreshRequests,
  } = useFriendRequests();

  const {
    friendStatus,
    loading: friendStatusLoading,
    refresh: refreshFriendStatus,
  } = useFriends(user?.user_id);

  // Derived state
  const isOwnProfile = authUser?.username === username;
  const hasPendingIncomingRequest = !isOwnProfile && user && incomingRequests.some(req => req.requester_id === user.user_id);
  const hasPendingSentRequest = !isOwnProfile && user && sentRequests.some(req => req.recipient_id === user.user_id);

  // Show friend buttons only when all friend data is loaded
  const showFriendButtons = !friendStatusLoading && !requestsLoading && !initialLoading;

  // Clear games when switching profiles
  useEffect(() => {
    return () => {
      refreshRequests();
      refreshFriendStatus();
      if (games) {
        games.splice(0, games.length);
      }
    };
  }, [username]);

  /**
   * Fetch user profile info and current user.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!username) return;

        // Only fetch user data, since we already have current user from auth context
        const userData = await User.fetchUserByUsername(username);

        if (!userData) {
          setUser(null);
          setError('User not found');
          return;
        }

        // Only update user data if it's different
        if (!user || user.user_id !== userData.user_id) {
          setUser(userData);

          // Preload the profile picture if it exists
          if (userData.profile_picture_url) {
            const img = new Image();
            img.onload = () => {
              setProfilePicture(userData.profile_picture_url);
              setImageLoaded(true);
            };
            img.src = userData.profile_picture_url;
          }
        }
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError(
          err instanceof Error ? err.message : 'Failed to load user profile'
        );
      } finally {
        setInitialLoading(false);
      }
    };

    if (username) {
      fetchData();
    }
  }, [username]);

  // Games (infinite scroll)
  const { games, loading: gamesLoading, error: gamesError, loadMore, hasMore } =
    useOtherUserGames(user?.user_id);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastGameElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (gamesLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [gamesLoading, hasMore, loadMore]
  );

  /**
   * Handle direct message button
   */
  const handleMessageClick = useCallback(async () => {
    if (!user || !authUser) return;
    try {
      const chatroom = await ChatroomService.getOrCreateDMChatroom(
        authUser.user_id,
        user.user_id,
        authUser.username,
        user.username
      );
      
      if (chatroom?.chatroom_id) {
        navigate(`/chat/${chatroom.chatroom_id}`);
      }
    } catch (err) {
      console.error('Failed to open chat:', err);
      // You might want to show a toast/notification here
    }
  }, [user, authUser, navigate]);

  /**
   * Handle sending friend requests
   */
  const handleSendFriendRequest = async () => {
    if (!authUser || !user) return;
    try {
      setSendingFriendRequest(true);
      await authUser.createFriendRequest(user.user_id);
      await refreshFriendStatus();
      await refreshRequests();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to send friend request');
    } finally {
      setSendingFriendRequest(false);
    }
  };

  /**
   * Handle accepting friend requests
   */
  const handleAcceptFriendRequest = async () => {
    if (!user) return;
    setAcceptingFriendRequest(true);
    try {
      const friendRequest = incomingRequests.find(
        (request) => request.requester_id === user.user_id
      );
      if (!friendRequest) return;
      await User.acceptFriendRequest(friendRequest);
      await refreshFriendStatus();
      await refreshRequests();
    } catch (error) {
      console.error(error);
      setError('Failed to accept friend request');
    } finally {
      setAcceptingFriendRequest(false);
    }
  };

  /**
   * Handle removing a friend
   */
  const handleUnfriend = async () => {
    if (!user) return;
    setShowUnfriendConfirm(true);
  };

  /**
   * Handle reporting a user
   */
  const handleReport = async (reason: string, details: string) => {
    if (!user || !authUser) return;
    try {
      await User.reportUser(user.user_id, reason, details);
      // Show success toast or message
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to report user');
    }
  };

  /**
   * Handle blocking a user
   */
  const handleBlock = async () => {
    if (!user || !authUser) return;
    try {
      await authUser.blockUser(user.user_id);
      // Optionally redirect to home/previous page
      navigate(-1);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to block user');
    }
  };

  /**
   * Renders the friend-action button or placeholder.
   */
  const renderFriendButton = () => {
    // Don't render anything while loading friend data
    if (!showFriendButtons) {
      return null;
    }

    // Don't render if we don't have user data or it's our own profile
    if (!user || !authUser || authUser.user_id === user.user_id) {
      return null;
    }

    // If there's a request from this user to current user
    if (hasPendingIncomingRequest) {
      return (
        <button
          disabled={acceptingFriendRequest}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAcceptFriendRequest();
          }}
          className="text-gray-600 hover:text-gray-700 transition-colors text-sm font-medium flex items-center min-w-[36px] min-h-[36px] justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <FaUserCheck className="w-5 h-5" />
        </button>
      );
    }

    // If friend request was sent by the current user
    if (hasPendingSentRequest) {
      return (
        <button
          disabled={true}
          className="text-gray-600 hover:text-gray-700 transition-colors text-sm font-medium flex items-center min-w-[36px] min-h-[36px] justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <FaHourglassHalf className="w-5 h-5" />
        </button>
      );
    }

    if (friendStatus && friendStatus.friended_at != null) {
      return null;
    }

    if (requestsLoading) {
      return null;
    }

    // If not friends & no outstanding requests
    return (
      <button
        disabled={sendingFriendRequest}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSendFriendRequest();
        }}
        className="text-gray-600 hover:text-gray-700 transition-colors text-sm font-medium flex items-center min-w-[36px] min-h-[36px] justify-center disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <FaUserPlus className="w-5 h-5" />
      </button>
    );
  };

  // Settings modal state
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Render loading screen if still fetching initial user data AND we don't have cached data AND it's not our own profile
  if ((initialLoading || friendStatusLoading || requestsLoading) && !user && (!authUser || authUser.username !== username)) {
    return (
      <div className="min-h-screen bg-gray-50 select-none">
        <TopNavBar />
        <div className="flex flex-col">
          {/* Cover image skeleton */}
          <div className="bg-white">
            <div className="h-32 bg-gradient-to-r from-gray-200 to-gray-300 animate-pulse relative">
              {/* Action buttons skeleton */}
              {/* <div className="absolute right-4 mt-6 mr-2 flex items-center gap-4 z-20">
                <div className="min-w-[36px] min-h-[36px] flex items-center justify-center">
                  <div className="w-5 h-5 bg-gray-300 rounded-full animate-pulse" />
                </div>
                <div className="min-w-[36px] min-h-[36px] flex items-center justify-center">
                  <div className="w-5 h-5 bg-gray-300 rounded-full animate-pulse" />
                </div>
                <div className="min-w-[36px] min-h-[36px] flex items-center justify-center">
                  <div className="w-5 h-5 bg-gray-300 rounded-full animate-pulse" />
                </div>
              </div> */}
            </div>

            {/* Profile info skeleton */}
            <div className="px-4 pb-4 -mt-20 relative">
              <div className="flex items-start">
                <div className="flex flex-col items-start">
                  {/* Profile Picture skeleton */}
                  <div className="w-40 h-40 rounded-full bg-gray-200 border-4 border-white shadow-lg animate-pulse" />
                  
                  {/* Name & username skeleton */}
                  <div className="flex flex-col pl-3 mt-4">
                    <div className="h-8 bg-gray-200 rounded w-48 mb-2 animate-pulse" />
                    <div className="h-4 bg-gray-200 rounded w-32 animate-pulse" />
                  </div>
                </div>
              </div>

              {/* Bio skeleton */}
              <div className="mt-6">
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2 animate-pulse" />
                <div className="h-4 bg-gray-200 rounded w-1/2 animate-pulse" />
              </div>
            </div>
          </div>

          {/* Games section skeleton */}
          <div className="bg-white mt-4 flex-1">
            <div className="border-b">
              <div className="flex">
                <div className="flex-1 py-4 px-6">
                  <div className="w-5 h-5 bg-gray-200 rounded mx-auto animate-pulse" />
                </div>
                <div className="flex-1 py-4 px-6">
                  <div className="w-5 h-5 bg-gray-200 rounded mx-auto animate-pulse" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If user not found
  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 select-none">
        <TopNavBar />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <div className="text-gray-500 text-center">
            {error || 'User not found'}
          </div>
        </div>
      </div>
    );
  }

  // If there's a general error
  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 select-none">
        <TopNavBar />
        <div className="flex items-center justify-center h-[calc(100vh-64px)]">
          <div className="text-gray-500 text-center">{error}</div>
        </div>
    </div>
  );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col select-none">
      <div className="relative">
        <TopNavBar />
      </div>

      {/* Cover */}
      <div className="bg-white">
        <div className="h-32 bg-gradient-to-r from-indigo-500 to-indigo-600 relative z-0">
        </div>

        {/* Action buttons (top-right) */}
        {authUser && user && (
          <div className="absolute right-4 mt-6 mr-2 flex gap-4 z-20">
            {/* If it's your own profile */}
            {authUser.user_id === user.user_id ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate('/editprofile');
                }}
                className="text-gray-600 hover:text-gray-700 transition-colors text-sm font-medium flex items-center min-w-[36px] min-h-[36px] justify-center"
              >
                <FaCog className="w-5 h-5" />
              </button>
            ) : (
              <>
                {/* Loading state or actual buttons */}
                {!showFriendButtons ? (
                  // Loading state - show button placeholders
                  <>
                    <div className="min-w-[36px] min-h-[36px] flex items-center justify-center">
                      <div className="w-5 h-5 bg-gray-300 rounded-full animate-pulse" />
                    </div>
                    <div className="min-w-[36px] min-h-[36px] flex items-center justify-center">
                      <div className="w-5 h-5 bg-gray-300 rounded-full animate-pulse" />
                    </div>
                    <div className="min-w-[36px] min-h-[36px] flex items-center justify-center">
                      <div className="w-5 h-5 bg-gray-300 rounded-full animate-pulse" />
                    </div>
                  </>
                ) : (
                  <>
                    {/* Dynamic friend status button */}
                    {renderFriendButton()}

                    {/* Message button - always present */}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleMessageClick();
                      }}
                      className="text-gray-600 hover:text-gray-700 transition-colors text-sm font-medium flex items-center min-w-[36px] min-h-[36px] justify-center"
                    >
                      <FaComments className="w-5 h-5" />
                    </button>

                    {/* Settings button - always present */}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowSettingsModal(true);
                      }}
                      className="text-gray-600 hover:text-gray-700 transition-colors text-sm font-medium flex items-center min-w-[36px] min-h-[36px] justify-center"
                    >
                      <FaCog className="w-5 h-5" />
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* Profile Info */}
        <div className="px-4 pb-4 -mt-20 relative z-0">
          <div className="flex items-start">
            <div className="flex flex-col items-start">
              {/* Profile Picture */}
              <div className="relative w-40 h-40">
                {!user.profile_picture_url || !imageLoaded ? (
                  <div className="w-40 h-40 rounded-full bg-gray-200 flex items-center justify-center border-4 border-white shadow-lg">
                    <FaUser className="text-gray-400 text-6xl" />
                  </div>
                ) : (
                  <img
                    src={profilePicture || ''}
                    alt="Profile"
                    className="w-40 h-40 rounded-full object-cover border-4 border-white shadow-lg"
                  />
                )}
              </div>
              {/* Name & username */}
              <div className="flex flex-col pl-3 mt-4">
                <h1 className="text-2xl md:text-3xl font-bold mb-0.5">
                  {user.first_name} {user.last_name}
                </h1>
                <span className="text-gray-600 text-sm">@{user.username}</span>
              </div>
            </div>
          </div>

          {/* Bio */}
          <div className="mt-6">
            <p className="text-gray-600 mb-3 text-base md:text-lg pl-3">
              {user.bio || 'Passionate soccer player. Always up for a match!'}
            </p>
          </div>
        </div>
      </div>

      {/* Games Section */}
      <div className="bg-white mt-4 flex-1 flex flex-col min-h-0">
        <div className="border-b">
          <div className="flex">
            <button
              onClick={() => setActiveTab('upcoming')}
              className={`flex-1 py-4 px-6 focus:outline-none ${
                activeTab === 'upcoming'
                  ? 'border-b-2 border-blue-500 text-blue-500'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              aria-label="Upcoming Games"
            >
              <FaCalendarAlt
                className={`mx-auto text-xl ${
                  activeTab === 'upcoming' ? 'text-blue-500' : 'text-gray-500'
                }`}
              />
            </button>
            <button
              onClick={() => setActiveTab('recent')}
              className={`flex-1 py-4 px-6 focus:outline-none ${
                activeTab === 'recent'
                  ? 'border-b-2 border-blue-500 text-blue-500'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              aria-label="Recent Games"
            >
              <FaHistory
                className={`mx-auto text-xl ${
                  activeTab === 'recent' ? 'text-blue-500' : 'text-gray-500'
                }`}
              />
            </button>
          </div>
        </div>

        {/* Tab Content */}
        <div className="py-4 pb-20 flex-1 overflow-y-auto">
          {gamesLoading && !games?.length ? (
            <div className="space-y-4 px-4">
              {[...Array(3)].map((_, i) => (
                <div key={i} className="bg-white rounded-lg p-4 space-y-2">
                  <div className="h-5 bg-gray-200 rounded w-3/4 animate-pulse" />
                  <div className="flex justify-between items-center">
                    <div className="h-4 bg-gray-200 rounded w-1/4 animate-pulse" />
                    <div className="h-4 bg-gray-200 rounded w-1/4 animate-pulse" />
                  </div>
                </div>
              ))}
            </div>
          ) : gamesError ? (
            <div className="text-gray-500 text-center py-4">
              Failed to load games
            </div>
          ) : (
            <>
              {activeTab === 'upcoming' && (
                <div className="space-y-2 px-2 rounded-lg">
                  {games
                    ?.filter((g) => new Date(g.scheduled_at) > new Date())
                    .sort(
                      (a, b) =>
                        new Date(a.scheduled_at).getTime() -
                        new Date(b.scheduled_at).getTime()
                    )
                    .map((game, index, array) => (
                      <div
                        key={game.game_id}
                        ref={index === array.length - 1 ? lastGameElementRef : null}
                        className="px-4 py-2 rounded-lg cursor-pointer transition-colors duration-200 w-full"
                        onClick={() => navigate(`/game/${game.game_id}`)}
                      >
                        <div className="flex flex-col">
                          <h3 className="font-medium text-gray-700">{game.name}</h3>
                          <div className="flex items-center justify-between mt-1">
                            <p className="text-gray-400 text-sm">
                              {new Date(game.scheduled_at).toLocaleDateString()}
                            </p>
                            <p className="text-gray-400 text-sm text-right md:text-left">
                              {new Date(game.scheduled_at).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {games &&
                    games.filter((g) => new Date(g.scheduled_at) > new Date())
                      .length === 0 && (
                      <div className="text-gray-500 text-center py-4">
                        No upcoming games
                      </div>
                    )}
                  {/* {gamesLoading && games.length > 0 && (
                    <div className="py-4 text-center">
                      <Loading />
                    </div>
                  )} */}
                </div>
              )}

              {activeTab === 'recent' && (
                <div className="space-y-2 px-2 rounded-lg">
                  {games
                    ?.filter((g) => new Date(g.scheduled_at) <= new Date())
                    .sort(
                      (a, b) =>
                        new Date(b.scheduled_at).getTime() -
                        new Date(a.scheduled_at).getTime()
                    )
                    .map((game, index, array) => (
                      <div
                        key={game.game_id}
                        ref={index === array.length - 1 ? lastGameElementRef : null}
                        className="px-4 py-2 rounded-lg cursor-pointer transition-colors duration-200 w-full"
                        onClick={() => navigate(`/game/${game.game_id}`)}
                      >
                        <div className="flex flex-col">
                          <h3 className="font-medium text-gray-700">{game.name}</h3>
                          <div className="flex items-center justify-between mt-1">
                            <p className="text-gray-400 text-sm">
                              {new Date(game.scheduled_at).toLocaleDateString()}
                            </p>
                            <p className="text-gray-400 text-sm text-right md:text-left">
                              {new Date(game.scheduled_at).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {games &&
                    games.filter((g) => new Date(g.scheduled_at) <= new Date())
                      .length === 0 && (
                      <div className="text-gray-500 text-center py-4">
                        No recent games
                      </div>
                    )}
                  {/* {gamesLoading && games.length > 0 && (
                    <div className="py-4 text-center">
                      <Loading />
                    </div>
                  )} */}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Settings Modal */}
      {showSettingsModal && (
        <UserSettingsModal
          isOpen={showSettingsModal}
          onClose={() => setShowSettingsModal(false)}
          onUnfriend={handleUnfriend}
          onReport={handleReport}
          onBlock={handleBlock}
          isFriend={!!friendStatus?.friended_at}
        />
      )}
    </div>
  );
};

export default UserProfilePage;
