import React from 'react';
import Modal from './Modal';
import LoadingButton from './LoadingButton';

interface GameDetailsModalProps {
  title: string;
  description: string;
  onConfirm: () => void;
  onClose: () => void;
  isLoading: boolean;
}

const GameDetailsModal: React.FC<GameDetailsModalProps> = ({
  title,
  description,
  onConfirm,
  onClose,
  isLoading
}) => {
  return (
    <Modal onClose={onClose} className="w-72 rounded-md">
      <div className="p-4 select-none">
        <div className="text-gray-800 font-medium mb-2">{title}</div>
        <div className="text-gray-600 text-sm mb-4">
          {description}
        </div>
        <div className="flex gap-2">
          <LoadingButton
            onClick={onConfirm}
            isLoading={isLoading}
            className="flex-1 bg-red-500 text-white px-3 py-1.5 rounded-md text-sm font-medium hover:bg-red-600 transition-colors flex items-center justify-center gap-1"
          >
            Leave
          </LoadingButton>
          <button
            onClick={onClose}
            className="flex-1 bg-gray-100 text-gray-700 px-3 py-1.5 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default GameDetailsModal;
