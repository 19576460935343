import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from '../components/TopNavBar';
import EmailVerificationNotice from '../components/EmailVerificationNotice';
import CompleteProfileNotice from '../components/CompleteProfileNotice';
import PersonalInfo from '../components/PersonalInfo';
import AccountSettings from '../components/AccountSettings';
import SecuritySettings from '../components/SecuritySettings';
import PrivacySettings from '../components/PrivacySettings';
import { User } from '../models/user';
import UserProfileCardSkeleton from '../components/UserProfileCardSkeleton';
import CoverPhoto from '../components/CoverPhoto';
import PaymentHistory from '../components/PaymentHistory';
import { useUserProfile } from '../hooks/useUserProfile';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const EditProfilePage: React.FC = () => {
	const [error, setError] = useState<string>('');
	const { userProfile, isLoading } = useUserProfile();
	const { signOut } = useAuth();
	const navigate = useNavigate();
	const [showSignOutConfirm, setShowSignOutConfirm] = useState(false);
	const [isSigningOut, setIsSigningOut] = useState(false);

	const handleUpdateUserPassword = async (current_password: string, new_password: string) => {
		if (!userProfile) return;
		try {
			const user = new User(userProfile);
			const updatedUser = await user.updatePassword(current_password, new_password);
			// Optionally update the userProfile state if needed
			// setUserProfile(updatedUser);
		} catch (error) {
			console.error('Failed to update password:', error);
			setError('Failed to update password. Please try again.');
		}
	};

	const handleUpdateUserPersonal = async (updatedFields: Partial<User>) => {
		if (!userProfile) return;
		try {
			const user = new User(userProfile);
			const updatedUser = await user.updateUser(updatedFields);
			// Optionally update the userProfile state if needed
			// setUserProfile(updatedUser);
		} catch (error) {
			console.error('Failed to update user:', error);
		}
	};

	const uploadUserImage = async (file: File) => {
		const user = new User(userProfile);
		return await user.uploadUserImage(file);
	};

	const handleLogout = async () => {
		setIsSigningOut(true);
		try {
			await signOut();
			navigate('/');
		} catch (error) {
			console.error('Failed to sign out:', error);
		} finally {
			setIsSigningOut(false);
			setShowSignOutConfirm(false);
		}
	};

	if (error) {
		return (
			<div className="min-h-screen bg-gray-50">
				<TopNavBar />
				<div className="container px-4 py-4 max-w-7xl">
					<p className="text-red-500">{error}</p>
				</div>
			</div>
		);
	}

	// Only show loading if we're actually fetching and have no data
	if (isLoading && !userProfile) {
		return (
			<div className="min-h-screen bg-gray-50">
				<TopNavBar />
				<div className="container px-4 py-4 max-w-7xl">
					<UserProfileCardSkeleton />
				</div>
			</div>
		);
	}

	if (!userProfile) {
		return (
			<div className="min-h-screen bg-gray-50">
				<TopNavBar />
				<div className="container px-4 py-4 max-w-7xl">
					<UserProfileCardSkeleton />
				</div>
			</div>
		);
	}

	return (
		<div className="min-h-screen bg-gray-50">
			<TopNavBar />

			<div className="container px-2 py-2 max-w-7xl pb-24">
				<div className="bg-gray-50 p-4">
					<div className="space-y-4">
						<div className="grid grid-cols-1 gap-8">
							<div className="space-y-2">
								<PersonalInfo 
									user={userProfile} 
									onUpdate={handleUpdateUserPersonal} 
									uploadUserImage={uploadUserImage}
								/>
							</div>
							<div className="space-y-2">
								<SecuritySettings user={userProfile} onUpdate={handleUpdateUserPassword} />
								{/* <PrivacySettings user={userProfile} onUpdate={handleUpdateUserPrivacy} /> */}
							</div>
							<div className="mt-2 flex">
								<button
									onClick={() => setShowSignOutConfirm(true)}
									className="text-rose-500 hover:text-rose-600 transition-colors flex items-center gap-2 px-1 text-sm font-semibold"
								>
									<FontAwesomeIcon icon={faArrowRightFromBracket} className="w-4 h-4" />
									<span>Sign Out</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Sign out confirm modal */}
			{showSignOutConfirm && (
				<>
					<div
						className="fixed inset-0 bg-black bg-opacity-50 z-50"
						onClick={() => setShowSignOutConfirm(false)}
					/>
					<div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 bg-white rounded-lg shadow-lg p-4 z-50">
						<div className="text-gray-800 font-medium mb-2">Sign out?</div>
						<div className="text-gray-600 text-sm mb-4">
							You will need to sign in again to access your account.
						</div>
						<div className="flex gap-2">
							<button
								onClick={handleLogout}
								className="flex-1 bg-rose-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-rose-600 transition-colors flex items-center justify-center gap-1"
							>
								<FontAwesomeIcon icon={faArrowRightFromBracket} className="w-4 h-4" />
								{isSigningOut ? 'Signing Out...' : 'Sign Out'}
							</button>
							<button
								onClick={() => setShowSignOutConfirm(false)}
								className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
							>
								Cancel
							</button>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default EditProfilePage;
