export type NotificationType =
    | 'chat_update'
    | 'game_update'
    | 'game_created'
    | 'game_joined'
    | 'game_canceled'
    | 'friend_request'
    | 'friend_accepted';

export type ConnectionStatus = 'connecting' | 'online' | 'away' | 'offline';

export interface Notification {
    notification_id: string;
    user_id: string;
    notification_time: Date;
    type: NotificationType;
    source_id: string;
    content: string;
    read: boolean;
    priority: number;
    expires_at: Date;
}

export class NotificationWebsocketAPI {
    private static ws: WebSocket | null = null;
    private static messageQueue: Array<any> = [];
    private static listeners: Set<(notification: Notification) => void> = new Set();
    private static statusListeners: Set<(status: ConnectionStatus) => void> = new Set();
    private static reconnectTimeout: NodeJS.Timeout | null = null;
    private static reconnectAttempts = 0;
    private static readonly RECONNECT_DELAYS = [1000, 2000, 5000, 10000, 30000];
    private static isConnecting = false;

    private static deviceId: string = ''; 
    private static publicKey: string = 'your-public-key-here';
    private static currentAccountId: string = ''; // Store the current accountId here.

    static async connect(accountId: string): Promise<void> {
        // Validate accountId
        if (!accountId || accountId.trim() === '') {
            console.log('[Notifications] Invalid accountId, skipping connection');
            return;
        }

        // If we're already connected to this specific account, don't create a new connection
        if (this.ws?.readyState === WebSocket.OPEN && this.currentAccountId === accountId) {
            // console.log('[Notifications] Already connected to account:', accountId);
            return;
        }

        // If we're connecting and it's to the same account, don't start another connection
        if (this.isConnecting && this.currentAccountId === accountId) {
            console.log('[Notifications] Connection already in progress for account:', accountId);
            return;
        }

        const token = localStorage.getItem('accessToken');
        if (!token) throw new Error('No authentication token found');

        // Close existing connection if we're switching accounts
        if (this.ws && this.currentAccountId !== accountId) {
            console.log('[Notifications] Switching accounts, closing existing connection');
            this.ws.close();
            this.ws = null;
        }

        // Store the accountId so we can use it during reconnect attempts
        this.currentAccountId = accountId;
        this.isConnecting = true;

        if (!this.deviceId) {
            this.deviceId = crypto.randomUUID();
        }

        console.log('[Notifications] Connecting to notifier:', accountId);
        try {
            this.notifyStatusListeners('connecting');
            const baseUrl = 'https://kickoff.game';
            const url = new URL(`${baseUrl}/api/notifier/v1/${accountId}`);
            url.protocol = url.protocol.replace('http', 'ws');

            // Close existing connection if any
            if (this.ws) {
                this.ws.close();
                this.ws = null;
            }

            this.ws = new WebSocket(url.toString());

            this.ws.onopen = () => {
                console.log('[Notifications] Connection opened, sending auth...');
                this.isConnecting = false;
                this.ws?.send(JSON.stringify({ 
                    type: 'auth', 
                    token,
                    deviceId: this.deviceId,
                    publicKey: this.publicKey
                }));
            };

            this.ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    if (data.type === 'auth' && data.status === 'success') {
                        console.log('[Notifications] Authenticated successfully');
                        this.notifyStatusListeners('online');
                        this.reconnectAttempts = 0;
                        this.processQueue();
                    } else if (data.type === 'notification') {
                        this.notifyListeners(this.transformNotification(data.payload));
                    } else if (data.type === 'notificationDeleted') {
                        console.log('[Notifications] Notification deleted:', data.id);
                    } else if (data.type === 'error') {
                        console.error('[Notifications] Error message received:', data.message);
                    }
                } catch (error) {
                    console.error('[Notifications] Failed to process message:', error);
                }
            };

            this.ws.onclose = (event) => {
                console.log('[Notifications] Connection closed:', {
                    code: event.code,
                    reason: event.reason,
                    wasClean: event.wasClean
                });
                this.isConnecting = false;
                this.notifyStatusListeners('offline');
                
                if (event.code !== 1000) { // Not a clean close
                    this.scheduleReconnect();
                }
            };

            this.ws.onerror = (error) => {
                console.error('[Notifications] WebSocket error:', error);
                this.isConnecting = false;
            };
        } catch (error) {
            this.isConnecting = false;
            throw error;
        }
    }

    static subscribe(callback: (notification: Notification) => void): () => void {
        this.listeners.add(callback);
        return () => this.listeners.delete(callback);
    }

    static subscribeToStatus(callback: (status: ConnectionStatus) => void): () => void {
        this.statusListeners.add(callback);
        return () => this.statusListeners.delete(callback);
    }

    static async markAsRead(notificationId: string): Promise<void> {
        const message = {
            type: 'status',
            notificationId,
            status: 'read'
        };
        await this.sendMessage(message);
    }

    static async deleteNotification(notificationId: string): Promise<void> {
        const message = {
            type: 'status',
            notificationId,
            status: 'read'
        };
        await this.sendMessage(message);
    }

    private static async sendMessage(message: any): Promise<void> {
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify(message));
        } else {
            this.messageQueue.push(message);
        }
    }

    private static notifyListeners(notification: Notification): void {
        this.listeners.forEach(listener => {
            try {
                listener(notification);
            } catch (error) {
                console.error('[Notifications] Listener error:', error);
            }
        });
    }

    private static notifyStatusListeners(status: ConnectionStatus): void {
        this.statusListeners.forEach(listener => {
            try {
                listener(status);
            } catch (error) {
                console.error('[Notifications] Status listener error:', error);
            }
        });
    }

    private static processQueue(): void {
        while (this.messageQueue.length > 0) {
            const message = this.messageQueue.shift();
            if (message) {
                try {
                    this.sendMessage(message);
                } catch (error) {
                    console.error('[Notifications] Failed to process queued message:', error);
                    // Put the message back in the queue
                    this.messageQueue.unshift(message);
                    break;
                }
            }
        }
    }

    private static transformNotification(payload: any): Notification {
        return {
            notification_id: payload.id,
            user_id: payload.user_id,
            notification_time: new Date(payload.notification_time),
            type: payload.type,
            source_id: payload.source_id,
            content: payload.content.encrypted,
            read: false,
            priority: payload.priority ?? 0,
            expires_at: new Date(payload.expires_at)
        };
    }

    private static scheduleReconnect(): void {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }

        const delay = this.RECONNECT_DELAYS[
            Math.min(this.reconnectAttempts, this.RECONNECT_DELAYS.length - 1)
        ];
        console.log(`[Notifications] Reconnecting in ${delay}ms (attempt ${this.reconnectAttempts + 1})`);
        
        this.reconnectTimeout = setTimeout(() => {
            this.reconnectAttempts++;
            // Use the stored accountId for reconnect
            if (this.currentAccountId) {
                this.connect(this.currentAccountId).catch((error) =>
                    console.error('[Notifications] Reconnect attempt failed:', error)
                );
            } else {
                console.error('[Notifications] No accountId available for reconnection');
            }
        }, delay);
    }

    static disconnect(): void {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }
        if (this.ws) {
            this.ws.close(1000, 'Client disconnecting');
            this.ws = null;
        }
        this.listeners.clear();
        this.statusListeners.clear();
        this.messageQueue = [];
        this.reconnectAttempts = 0;
        this.currentAccountId = '';
    }
}
