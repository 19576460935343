import React, { useState, useEffect } from 'react';
import { User } from '../models/user';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../utils/cropImage';
import {
  FaUser,
  FaMapMarkerAlt,
  FaInfoCircle,
  FaRegCalendarAlt,
  FaEnvelope,
} from 'react-icons/fa';
import LoadingButton from './LoadingButton';
import FormField from './FormField';
import LocationPicker from './LocationPicker';
import './PersonalInfo.css';

interface PersonalInfoProps {
  user: User;
  onUpdate: (updatedFields: Partial<User>) => Promise<void>;
  uploadUserImage: (file: File) => Promise<string>;
}

// Safely parse a string in RFC3339 format (e.g., "2023-09-30T00:00:00Z") into a Date object.
// Returns null if invalid or empty.
function parseRFC3339Date(dateStr?: string | null): Date | null {
  if (!dateStr) return null;
  const parsed = new Date(dateStr);
  return isNaN(parsed.getTime()) ? null : parsed;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({
  user,
  onUpdate,
  uploadUserImage,
}) => {
  /* ------------------------------------------------------------------------ */
  /*                             NAME FIELD                                   */
  /* ------------------------------------------------------------------------ */
  // Build an initial string from first+last
  const initialName = [user.first_name, user.last_name].filter(Boolean).join(' ');
  const [fullName, setFullName] = useState(initialName);

  /* ------------------------------------------------------------------------ */
  /*                               BIO, LOCATION                              */
  /* ------------------------------------------------------------------------ */
  const [bio, setBio] = useState(user.bio || '');
  const [location, setLocation] = useState(user.location || '');

  /* ------------------------------------------------------------------------ */
  /*                            DATE OF BIRTH                                 */
  /* ------------------------------------------------------------------------ */
  // Parse user.birth_date if valid RFC3339; else null
  const [birthDate, setBirthDate] = useState<Date | null>(
    parseRFC3339Date(user.birth_date?.toString())
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  /* ------------------------------------------------------------------------ */
  /*                                IMAGE CROP                                */
  /* ------------------------------------------------------------------------ */
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState<string>(
    user.profile_picture_url || ''
  );
  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  const [isImageAvailable, setIsImageAvailable] = useState<boolean>(
    !!user.profile_picture_url
  );

  /* ------------------------------------------------------------------------ */
  /*                          UPDATE / SAVING STATES                          */
  /* ------------------------------------------------------------------------ */
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateStatus, setUpdateStatus] = useState<'success' | 'error' | null>(
    null
  );

  // Check if user’s existing image URL is accessible
  useEffect(() => {
    if (user.profile_picture_url) {
      fetch(user.profile_picture_url)
        .then((response) => {
          if (!response.ok) {
            setIsImageAvailable(false);
          }
        })
        .catch(() => {
          setIsImageAvailable(false);
        });
    }
  }, [user.profile_picture_url]);

  /* ------------------------------------------------------------------------ */
  /*                                IMAGE CROP                                */
  /* ------------------------------------------------------------------------ */
  const handleProfilePictureChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfilePicture(file);
      setProfilePicturePreview(URL.createObjectURL(file));
      setShowCropper(true);
    }
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropSave = async () => {
    try {
      const croppedImg = await getCroppedImg(
        profilePicturePreview,
        croppedAreaPixels
      );
      setCroppedImage(croppedImg);
      setProfilePicturePreview(croppedImg);
      setShowCropper(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCropCancel = () => {
    setShowCropper(false);
    setProfilePicture(null);
    setProfilePicturePreview(user.profile_picture_url || '/default-avatar.png');
  };

  /* ------------------------------------------------------------------------ */
  /*                           DATE PICKER LOGIC                              */
  /* ------------------------------------------------------------------------ */
  const openDatePicker = () => setIsDatePickerOpen(true);
  const closeDatePicker = () => setIsDatePickerOpen(false);

  // For quick selection, we have separate year/month dropdowns and a day grid.
  // We'll default the month/year to the current birthDate or "today" if none.
  const today = new Date();
  // We only allow past years up to the current year.
  // If birthDate is in the future, we'll clamp it if needed.
  const currentYear = today.getUTCFullYear();

  const [selectedYear, setSelectedYear] = useState(() => {
    const year = birthDate ? birthDate.getUTCFullYear() : currentYear;
    return year > currentYear ? currentYear : year;
  });

  const [selectedMonth, setSelectedMonth] = useState(() =>
    birthDate ? birthDate.getUTCMonth() : today.getUTCMonth()
  );

  // Generate a list of years up to currentYear (no future years)
  const years = [];
  for (let y = 1900; y <= currentYear; y++) {
    years.push(y);
  }

  // Generate days grid: always 6 rows (42 days) for constant modal size
  const generateDaysGrid = () => {
    // Create a UTC-based date for the 1st of the month
    const firstOfMonth = new Date(Date.UTC(selectedYear, selectedMonth, 1));
    const firstDayIndex = firstOfMonth.getUTCDay(); // 0=Sunday,1=Mon,...,6=Sat

    // Number of days in this month
    const lastOfMonth = new Date(
      Date.UTC(selectedYear, selectedMonth + 1, 0)
    );
    const totalDays = lastOfMonth.getUTCDate();

    // Create an array of 42 slots (6 rows * 7 columns)
    const calendarCells: Array<Date | null> = new Array(42).fill(null);

    // Fill in the days
    for (let i = 0; i < totalDays; i++) {
      // The offset to place day 1 in correct spot
      const cellIndex = i + firstDayIndex;
      calendarCells[cellIndex] = new Date(
        Date.UTC(selectedYear, selectedMonth, i + 1)
      );
    }

    return calendarCells;
  };

  const daysGrid = generateDaysGrid();

  const handleSelectDay = (day: Date) => {
    setBirthDate(day);
    closeDatePicker();
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(Number(e.target.value));
  };
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const chosenYear = Number(e.target.value);
    // If chosenYear is in the future, clamp to currentYear.
    setSelectedYear(chosenYear > currentYear ? currentYear : chosenYear);
  };

  /* ------------------------------------------------------------------------ */
  /*                                SAVE HANDLER                              */
  /* ------------------------------------------------------------------------ */
  const handleSave = async () => {
    setIsUpdating(true);
    setUpdateStatus(null);

    try {
      // Split the name into first + last
      const nameParts = fullName.trim().split(/\s+/);
      const firstName = nameParts[0] || '';
      const lastName = nameParts.slice(1).join(' ') || '';

      // Build partial user object
      const updatedFields: Partial<User> = {
        first_name: firstName,
        last_name: lastName,
        bio: bio,
        location: location,
        birth_date:
          birthDate && !isNaN(birthDate.getTime()) ? birthDate : null,
      };

      // If we have a newly cropped image, upload it
      if (croppedImage) {
        const blob = await fetch(croppedImage).then((r) => r.blob());
        const imageType = blob.type || 'image/jpeg';
        const fileExtension = imageType.split('/')[1];
        const file = new File([blob], `${user.user_id}.${fileExtension}`, {
          type: imageType,
        });
        const profilePictureUrl = await uploadUserImage(file);
        updatedFields.profile_picture_url = profilePictureUrl;
      }

      // Save changes
      await onUpdate(updatedFields);
      setUpdateStatus('success');
    } catch (error) {
      console.error('Failed to update personal information:', error);
      setUpdateStatus('error');
    } finally {
      setIsUpdating(false);
      setTimeout(() => setUpdateStatus(null), 3000);
    }
  };

  /* ------------------------------------------------------------------------ */
  /*                             RENDER JSX                                  */
  /* ------------------------------------------------------------------------ */

  // Format birthDate in UTC for display (left aligned):
  const displayDateUTC = birthDate
    ? birthDate.toLocaleDateString('en-US', {
        timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    : 'Select Date';

  return (
    <div className="col-span-1">
      <div className="space-y-4">
        {/* --------------------------------------------------------------
           Profile Picture + Username/Email Row
        ---------------------------------------------------------------- */}
        <div className="grid grid-cols-[96px_1fr] gap-2 pt-4">
          {/* Profile Picture */}
          <div className="relative w-24 h-24">
            {isImageAvailable && !profilePicture ? (
              <img
                src={user.profile_picture_url}
                alt="Profile"
                className="w-full h-full rounded-full object-cover border-2 border-gray-300"
              />
            ) : profilePicturePreview ? (
              <img
                src={profilePicturePreview}
                alt="Profile"
                className="w-full h-full rounded-full object-cover border-2 border-gray-300"
              />
            ) : (
              <div className="w-full h-full rounded-full bg-gray-200 flex items-center justify-center">
                <FaUser className="text-gray-400 text-4xl" />
              </div>
            )}
            <label
              htmlFor="profilePictureInput"
              className="absolute -bottom-1 -right-1 bg-indigo-600 hover:bg-indigo-700 text-white rounded-full p-1.5 sm:p-2 cursor-pointer shadow-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5 sm:h-4 sm:w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15.232 5.232l3.536 3.536M16 2a2 2 0 012 2v4M21 16v4a2 2 0 01-2 2h-4M8 21H4a2 2 0 01-2-2v-4M3 8V4a2 2 0 012-2h4"
                />
              </svg>
            </label>
            <input
              type="file"
              id="profilePictureInput"
              accept="image/*"
              className="hidden"
              onChange={handleProfilePictureChange}
            />
          </div>

          {/* Email and Username Column */}
          <div className="flex flex-col justify-center ml-4">
            <div className="flex items-center gap-2 mt-8">
              <FaEnvelope className="text-gray-300 text-[16px]" />
              <p
                className="text-gray-500 truncate text-[15px] mb-1"
                title={user.email || ''}
              >
                {user.email || ''}
              </p>
            </div>

            {/* Username - as label, truncated if too long */}
            <div className="flex-1 space-y-1 min-w-0">
              <div className="flex items-center gap-2">
                <FaUser className="text-gray-300 text-[16px]" />
                <p
                  className="text-gray-500 truncate max-w-full text-[15px]"
                  title={user.username || ''}
                >
                  {('@' + user.username) || ''}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* --------------------------------------------------------------
           Cropper Modal
        ---------------------------------------------------------------- */}
        {showCropper && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-xl sm:rounded-lg shadow-lg w-full max-w-sm">
              <div className="p-4 sm:p-6">
                <h3 className="text-[17px] sm:text-lg font-medium text-gray-900 mb-4">
                  Adjust Profile Picture
                </h3>
                <div className="relative w-full aspect-square bg-gray-100 rounded-lg overflow-hidden">
                  <Cropper
                    image={profilePicturePreview}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
                <div className="flex justify-end mt-6 space-x-3">
                  <button
                    onClick={handleCropCancel}
                    className="px-4 py-2.5 sm:py-2 text-[15px] sm:text-base bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-xl sm:rounded-md transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleCropSave}
                    className="px-4 py-2.5 sm:py-2 text-[15px] sm:text-base bg-indigo-600 hover:bg-indigo-700 text-white font-medium rounded-xl sm:rounded-md transition-colors"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* --------------------------------------------------------------
           Name (Single Field)
        ---------------------------------------------------------------- */}
        <FormField label="Name" icon={<FaUser className="text-gray-400" />}>
          <input
            type="text"
            className="w-full p-2 focus:outline-none rounded-md"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            maxLength={50} // Restrict name length
          />
        </FormField>

        {/* --------------------------------------------------------------
           Date of Birth (Custom Day Picker Modal), left aligned
        ---------------------------------------------------------------- */}
        <FormField
          label="Date of Birth"
          icon={<FaRegCalendarAlt className="text-gray-400" />}
        >
          <button
            type="button"
            onClick={openDatePicker}
            className="bg-gray-100 rounded-md px-3.5 py-2 focus:outline-none text-gray-700 text-left"
          >
            {displayDateUTC}
          </button>
        </FormField>

        {isDatePickerOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-xl sm:rounded-lg shadow-lg w-full max-w-md p-4 sm:p-6 min-h-[400px] flex flex-col">
              {/* Month/Year dropdowns */}
              <div className="flex items-center justify-center gap-3 mb-4">
                {/* Year dropdown (no future years) */}
                <select
                  className="border border-gray-300 rounded-md px-2 py-1"
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {years.map((y) => (
                    <option key={y} value={y}>
                      {y}
                    </option>
                  ))}
                </select>

                {/* Month dropdown */}
                <select
                  className="border border-gray-300 rounded-md px-2 py-1"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  {[
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ].map((m, idx) => (
                    <option key={m} value={idx}>
                      {m}
                    </option>
                  ))}
                </select>
              </div>

              {/* Weekday headings */}
              <div className="grid grid-cols-7 text-center text-sm font-medium text-gray-600 mb-1">
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((wd) => (
                  <div key={wd}>{wd}</div>
                ))}
              </div>

              {/* Days grid (6 rows x 7 columns = 42 cells) */}
              <div className="grid grid-cols-7 gap-y-1 flex-grow">
                {daysGrid.map((day, i) => {
                  if (!day) {
                    return <div key={i} />;
                  }
                  // Compare day in UTC to birthDate in UTC
                  const isSelected =
                    birthDate &&
                    day.getUTCFullYear() === birthDate.getUTCFullYear() &&
                    day.getUTCMonth() === birthDate.getUTCMonth() &&
                    day.getUTCDate() === birthDate.getUTCDate();

                  return (
                    <button
                      key={day.toISOString()}
                      onClick={() => handleSelectDay(day)}
                      className={`mx-auto my-1 w-10 h-8 flex items-center justify-center rounded-md text-sm transition-colors 
                        ${
                          isSelected
                            ? 'bg-indigo-600 text-white'
                            : 'hover:bg-gray-200 text-gray-700'
                        }
                      `}
                    >
                      {day.getUTCDate()}
                    </button>
                  );
                })}
              </div>

              {/* Modal Footer */}
              <div className="flex justify-end mt-4">
                <button
                  onClick={closeDatePicker}
                  className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-md transition-colors"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* --------------------------------------------------------------
           Location
        ---------------------------------------------------------------- */}
        <LocationPicker
          location={location}
          setLocation={setLocation}
          setCoordinates={() => {}}
        />

        {/* --------------------------------------------------------------
           About Me
        ---------------------------------------------------------------- */}
        <FormField label="About Me" icon={<FaInfoCircle className="text-gray-400" />}>
          <textarea
            className="w-full min-h-[100px] resize-y"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            rows={4}
          />
        </FormField>

        {/* --------------------------------------------------------------
           Save Button and Status
        ---------------------------------------------------------------- */}
        <div className="mt-6 sm:mt-4 flex items-center">
          <LoadingButton
            isLoading={isUpdating}
            onClick={handleSave}
            buttonClass="w-full bg-indigo-600 text-white px-3 py-2 rounded-lg hover:bg-indigo-700 transition-colors flex items-center justify-center gap-2 text-sm disabled:opacity-50 select-none"
            aria-label="Saving..."
          >
            Save Changes
          </LoadingButton>
        </div>

        {updateStatus === 'success' && (
          <p className="mt-3 sm:mt-2 text-[15px] sm:text-sm text-green-600">
            Changes saved successfully!
          </p>
        )}
        {updateStatus === 'error' && (
          <p className="mt-3 sm:mt-2 text-[15px] sm:text-sm text-red-600">
            Failed to save changes. Please try again.
          </p>
        )}
      </div>
    </div>
  );
};

export default PersonalInfo;
