import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaTimes, FaHome } from 'react-icons/fa';
import Turnstile from '../components/Turnstile';
import RedirectIfAuthenticated from '../components/RedirectIfAuthenticated';
import TermsAndConditions from '../constants/TermsAndConditions';
import { apiClient } from '../models/api';
import { clearAllState } from '../utils/clearState';
import { useUser } from '../contexts/UserContext';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-full max-w-[90%] md:max-w-[80%] lg:max-w-[70%] xl:max-w-[60%] mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="text-gray-600">{children}</div>
      </div>
    </div>
  );
};

const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [turnstileResetKey, setTurnstileResetKey] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showTerms, setShowTerms] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    username: false,
    password: false
  });
  const [initialBlur, setInitialBlur] = useState({
    email: false,
    username: false,
    password: false
  });
  const navigate = useNavigate();
  const { refreshUser } = useUser();

  const validatePassword = (pass: string) => {
    if (pass.length < 8) {
      return 'Password must be 8+ characters';
    }
    if (!/[A-Z]/.test(pass)) {
      return 'Password must have 1 uppercase letter';
    }
    if (!/[a-z]/.test(pass)) {
      return 'Password must have 1 lowercase letter';
    }
    if (!/[0-9]/.test(pass)) {
      return 'Password must have 1 number';
    }
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(pass)) {
      return 'Password must have 1 special character';
    }
    return '';
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return 'Email is required';
    }
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email';
    }
    return '';
  };

  const validateUsername = (username: string) => {
    const lowercaseUsername = username.toLowerCase();
    if (!lowercaseUsername) {
      return 'Username is required';
    }
    if (!/^[a-z]/.test(lowercaseUsername)) {
      return 'Username must start with a letter';
    }
    if (/[^a-z0-9._]/.test(lowercaseUsername)) {
      return `Only a-z, 0-9, . and _ are permitted`;
    }
    return '';
  };

  // Disable scrolling when component mounts
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const isValid = !emailError && !usernameError && !passwordError && 
                   email !== '' && username !== '' && password !== '' && 
                   turnstileToken !== null;
    setIsButtonDisabled(!isValid);
  }, [email, username, password, emailError, usernameError, passwordError, turnstileToken]);

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    // Ensure username is lowercase before validation and submission
    const lowercaseUsername = username.toLowerCase();
    setUsername(lowercaseUsername);

    // Check all validations
    const emailValidation = validateEmail(email);
    const usernameValidation = validateUsername(lowercaseUsername);
    const passwordValidation = validatePassword(password);

    setEmailError(emailValidation);
    setUsernameError(usernameValidation);
    setPasswordError(passwordValidation);

    if (emailValidation || usernameValidation || passwordValidation) {
      return;
    }

    if (!turnstileToken) {
      setErrorMessage('Please complete the Turnstile challenge.');
      return;
    }

    try {
      const response = await fetch('https://championships.kickoff.game/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'cf-turnstile-response': turnstileToken,
        },
        body: JSON.stringify({ email, username: lowercaseUsername, password }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to sign up.');
      }

      const data = await response.json();
      const { access_token: accessToken, refresh_token: refreshToken } = data;

      if (!accessToken || !refreshToken) {
        throw new Error('Tokens not received from server.');
      }

      // Clear any existing auth state or tokens
      clearAllState();

      // Update api client with new tokens
      apiClient.setAccessToken(accessToken);
      apiClient.setRefreshToken(refreshToken);

      // Initialize user state before navigation
      await refreshUser();

      // Navigate to dashboard
      window.location.href = '/dashboard';
    } catch (error: any) {
      if (error.response?.status === 429) {
        setErrorMessage('Too many attempts. Please try again later.');
      } else if (error.response?.status === 409) {
        setErrorMessage('Username or email already exists.');
      } else if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message || 'An unexpected error occurred. Please try again.');
      }
      // Reset turnstile only on error
      setTurnstileToken(null);
      setTurnstileResetKey((prevKey) => prevKey + 1);
    }
  };

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleBlur = (field: 'email' | 'username' | 'password') => {
    if (!initialBlur[field]) {
      setInitialBlur(prev => ({ ...prev, [field]: true }));
      setTouchedFields(prev => ({ ...prev, [field]: true }));
      
      // Validate on first blur
      switch (field) {
        case 'email':
          setEmailError(validateEmail(email));
          break;
        case 'username':
          setUsernameError(validateUsername(username));
          break;
        case 'password':
          setPasswordError(validatePassword(password));
          break;
      }
    }
  };

  return (
    <RedirectIfAuthenticated>
      <div className="h-screen w-screen flex flex-col overflow-hidden bg-gray-900">
        <div className="flex-1 overflow-y-auto">
          {/* Page Background */}
          <div className="h-screen overflow-hidden bg-gray-900 flex items-center justify-center px-4 sm:px-6 lg:px-8 relative">
            {/* Home Icon */}
            <button
              onClick={() => navigate('/')}
              className="absolute left-4 bottom-4 text-gray-500 opacity-50 hover:opacity-100 transition-opacity z-20 p-2 rounded-full hover:bg-white/10"
              aria-label="Home"
            >
              <FaHome size={24} />
            </button>

            {/* Background Image */}
            <div className="absolute inset-0 bg-gray-900">
              {/* Loading background */}
              <div className={`absolute inset-0 bg-gray-900 transition-opacity duration-500 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
              
              {/* Main image */}
              <img
                src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public"
                alt="Night soccer game under floodlights"
                className={`w-full h-full object-cover transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'} select-none pointer-events-none`}
                draggable="false"
                onContextMenu={(e) => e.preventDefault()}
                onLoad={() => setImageLoaded(true)}
              />
            </div>

            {/* Black overlay */}
            <div className="absolute inset-0 bg-black/30" />

            {/* Card Container */}
            <div className="bg-gray-50 rounded-lg shadow-xl p-6 max-w-md w-full relative z-10">
              {/* Page Title */}
              <h1 className="text-4xl font-bold tracking-tight mb-10 text-center text-neutral-900 select-none">
                Join Kickoff
              </h1>

              {/* Sign Up Form */}
              <form onSubmit={handleSignUp} className="mb-6" noValidate>
                {/* Email */}
                <div className="mb-2">
                  <label htmlFor="email" className="sr-only select-none">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    spellCheck="false"
                    autoCapitalize="off"
                    autoCorrect="off"
                    required
                    value={email}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setEmail(newValue);
                      if (initialBlur.email) {
                        setEmailError(validateEmail(newValue));
                      }
                    }}
                    onBlur={() => handleBlur('email')}
                    className={`w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100 
                      ${touchedFields.email && emailError ? 'ring-2 ring-red-500' : 'focus:ring-2 focus:ring-indigo-500'}`}
                    placeholder="Email"
                    inputMode="email"
                  />
                  <div className="h-5 mt-1">
                    {touchedFields.email && emailError && (
                      <p className="text-sm text-red-500">{emailError}</p>
                    )}
                  </div>
                </div>

                {/* Username */}
                <div className="mb-2">
                  <label htmlFor="username" className="sr-only select-none">
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    autoComplete="username"
                    spellCheck="false"
                    autoCapitalize="off"
                    autoCorrect="off"
                    required
                    value={username}
                    onChange={(e) => {
                      // Only prevent uppercase and spaces, allow other characters
                      const value = e.target.value.toLowerCase().replace(/\s/g, '');
                      setUsername(value);
                      if (initialBlur.username) {
                        setUsernameError(validateUsername(value));
                      }
                    }}
                    onBlur={() => handleBlur('username')}
                    className={`w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100 
                      ${touchedFields.username && usernameError ? 'ring-2 ring-ring-500' : 'focus:ring-2 focus:ring-indigo-500'}`}
                    placeholder="Username"
                  />
                  <div className="h-5 mt-1">
                    {touchedFields.username && usernameError && (
                      <p className="text-sm text-red-500">{usernameError}</p>
                    )}
                  </div>
                </div>

                {/* Password */}
                <div className="mb-2">
                  <label htmlFor="password" className="sr-only select-none">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    spellCheck="false"
                    autoCapitalize="off"
                    autoCorrect="off"
                    required
                    value={password}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setPassword(newValue);
                      if (initialBlur.password) {
                        setPasswordError(validatePassword(newValue));
                      }
                    }}
                    onBlur={() => handleBlur('password')}
                    className={`w-full px-3 py-2 rounded-lg focus:outline-none bg-gray-100 
                      ${touchedFields.password && passwordError ? 'ring-2 ring-red-500' : 'focus:ring-2 focus:ring-indigo-500'}`}
                    placeholder="Password"
                  />
                  <div className="h-5 mt-1">
                    {touchedFields.password && passwordError && (
                      <p className="text-sm text-red-500">{passwordError}</p>
                    )}
                  </div>
                </div>

                {/* Turnstile container */}
                <div className="mb-1 min-h-[72px]">
                  <Turnstile
                    key="signup-turnstile"
                    siteKey="0x4AAAAAAAjuZz4sYx8SxbrU"
                    onTokenChange={setTurnstileToken}
                    resetKey={turnstileResetKey}
                  />
                </div>

                {/* Error Message Container - Always present */}
                <div className="h-4 mb-1">
                  {errorMessage && (
                    <div className="text-xs text-gray-500 select-none">{errorMessage}</div>
                  )}
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={isButtonDisabled}
                  className={`w-full px-6 py-2 font-medium rounded-lg transition-all duration-500 ease-in-out text-medium select-none
                    ${
                      !isButtonDisabled
                        ? 'bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-300'
                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                >
                  Sign Up
                </button>
              </form>

              {/* Sign In Button */}
              <div className="text-center mb-4">
                <p className="text-sm select-none">
                  Already have an account?{' '}
                  <button
                    onClick={() => navigate('/signin')}
                    className="text-indigo-600 hover:text-indigo-800 hover:underline font-semibold select-none transition-colors"
                  >
                    Sign In
                  </button>
                </p>
              </div>

              {/* Terms and Conditions */}
              <div className="text-center text-xs text-gray-500 select-none">
                By signing up, you agree to our{' '}
                <button
                  onClick={() => setShowTerms(true)}
                  className="text-indigo-600 hover:text-indigo-800 hover:underline select-none transition-colors"
                >
                  Terms and Conditions
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Terms Modal */}
			<Modal isOpen={showTerms} onClose={() => setShowTerms(false)} title="Terms & Conditions">
				<div className="max-h-[70vh] overflow-y-auto">
					<TermsAndConditions />
				</div>
			</Modal>
    </RedirectIfAuthenticated>
  );
};

export default SignUp;
