import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useNotifications } from '../hooks/useNotifications';
import { useAuth } from '../hooks/useAuth';
import NotificationItem from '../components/NotificationItem';
import Loading from '../components/Loading';
import TopNavBar from '../components/TopNavBar';

const NotificationsPage: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const {
        notifications,
        loading: notificationsLoading,
        markAsRead
    } = useNotifications({
        accountId: user?.username,
        limit: 50
    });

    const handleMarkAsRead = async (notificationId: string) => {
        await markAsRead(notificationId);
    };

    return (
        <div className="min-h-screen bg-gray-50">
            <TopNavBar />
            {/* Header */}
            <div className="bg-white p-4 flex items-center space-x-4 shadow-sm sticky top-0 z-10">
                <button
                    onClick={() => navigate(-1)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                >
                    <FaArrowLeft className="w-5 h-5 text-gray-600" />
                </button>
                <h1 className="text-lg font-semibold">Notifications</h1>
            </div>

            {/* Notifications List */}
            <div className="divide-y divide-gray-200">
                {notificationsLoading ? (
                    <div className="flex justify-center items-center h-32">
                        <Loading />
                    </div>
                ) : notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <NotificationItem
                            key={notification.notification_id}
                            notification={notification}
                            onMarkAsRead={handleMarkAsRead}
                        />
                    ))
                ) : (
                    <div className="flex flex-col items-center justify-center h-32 text-gray-500 bg-white">
                        <p className="text-center">No notifications yet</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationsPage;
