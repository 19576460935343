import React from 'react';
import NewGameCard from './NewGameCard';
import { Game } from '../models/games';

interface GamesListProps {
  games: Game[];
  userID: string;
  onJoin?: (gameId: string) => void;
  onLeave?: (gameId: string) => void;
  loading?: boolean;
}

const GamesList: React.FC<GamesListProps> = ({ games, userID, onJoin, onLeave, loading = false }) => {
  if (loading) {
    return (
      <div className="space-y-6">
        {[1,2,3].map((i) => (
          <NewGameCard 
            key={i} 
            game={null as any}
            userID={userID}
            loading={true}
          />
        ))}
      </div>
    );
  }

  if (!games) return null;
  return (
    <div className="space-y-6">
      {games.map((game) => (
        <NewGameCard 
          key={game.game_id} 
          game={game} 
          userID={userID}
          onJoin={onJoin}
          onLeave={onLeave}
          loading={false}
        />
      ))}
    </div>
  );
};

export default GamesList;
