import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useChatroom, useChatrooms } from '../models/chatroom';
import TopNavBar from '../components/TopNavBar';
import { User } from '../models/user';
import { useUserProfile } from '../hooks/useUserProfile';
import Loading from '../components/Loading';
import { useViewMode } from '../contexts/ViewModeContext';
import MobileChatLayout from '../components/mobile/MobileChatLayout';

// Lazy load desktop components
const ChatList = lazy(() => import('../components/ChatList'));
const ChatInterface = lazy(() => import('../components/ChatInterface'));

const ChatPage: React.FC = () => {
  const { chatroomId } = useParams<{ chatroomId?: string }>();
  const { chatroom, loading: chatroomLoading, error: chatroomError } = useChatroom(chatroomId);
  const { chatrooms, loading: chatroomsLoading, error: chatroomsError } = useChatrooms();
  const { userProfile: currentUserProfile } = useUserProfile();
  const [otherProfiles, setOtherProfiles] = useState<{ [key: string]: User }>({});
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false);
  const { isMobile } = useViewMode();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const loadOtherProfiles = async () => {
      if (!currentUserProfile?.user_id || !chatrooms) return;
      
      const existingProfileIds = Object.keys(otherProfiles);
      const directMessages = chatrooms.filter(chat => chat.chatroom_type === 'direct_message');
      const otherUserIds = directMessages
        .map(chat => Object.keys(chat.members).find(id => id !== currentUserProfile.user_id))
        .filter((id): id is string => id !== undefined);

      if (chatroom?.chatroom_type === 'direct_message') {
        const otherUserId = Object.keys(chatroom.members).find(id => id !== currentUserProfile.user_id);
        if (otherUserId && !otherUserIds.includes(otherUserId)) {
          otherUserIds.push(otherUserId);
        }
      }

      const uniqueUserIds = [...new Set(otherUserIds)].filter(id => !existingProfileIds.includes(id));
      if (uniqueUserIds.length === 0) {
        setIsLoadingProfiles(false);
        return;
      }

      setIsLoadingProfiles(true);
      const profiles: { [key: string]: User } = { ...otherProfiles };

      try {
        await Promise.all(
          uniqueUserIds.map(async (userId) => {
            try {
              const userProfile = await User.getUserByIdPublic(userId);
              if (userProfile) {
                profiles[userId] = userProfile;
              }
            } catch (error) {
              console.error('Failed to load profile for user:', userId, error);
            }
          })
        );

        setOtherProfiles(profiles);
      } finally {
        setIsLoadingProfiles(false);
        if (isInitialLoad) {
          setIsInitialLoad(false);
        }
      }
    };

    // Only load profiles on initial mount or when chatroom/chatrooms change
    if (isInitialLoad || (!isLoadingProfiles && chatroom)) {
      loadOtherProfiles();
    }
  }, [currentUserProfile?.user_id, chatrooms, chatroom, isInitialLoad]);

  // Show loading only if we have no cached data
  const shouldShowLoading = chatroomsLoading && (!chatrooms || chatrooms.length === 0);

  // Mobile layout (default)
  if (isMobile) {
    return (
      <div className="flex flex-col h-screen max-h-screen overflow-hidden bg-gray-50">
        <TopNavBar />
        <MobileChatLayout
          chatrooms={chatrooms || []}
          loading={shouldShowLoading || isLoadingProfiles}
          error={chatroomsError}
          currentUserProfile={currentUserProfile}
          otherProfiles={otherProfiles}
          chatroom={chatroom}
          chatroomLoading={chatroomLoading}
          chatroomError={chatroomError}
        />
      </div>
    );
  }

  if (isInitialLoad && (chatroomsLoading || !currentUserProfile)) {
    return (
      <div className="flex flex-col h-screen">
        <TopNavBar />
        <div className="flex-1 flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }

  // Desktop layout (lazy loaded)
  return (
    <div className="flex flex-col h-screen max-h-screen overflow-hidden bg-gray-100">
      <TopNavBar />
      <Suspense fallback={<Loading />}>
        <div className="flex flex-1 min-h-0">
          <ChatList
            chatrooms={chatrooms || []}
            loading={shouldShowLoading || isLoadingProfiles}
            error={chatroomsError}
            currentUserProfile={currentUserProfile}
            otherProfiles={otherProfiles}
          />
          <div className="flex-1 min-h-0">
            {!chatroomId ? (
              <div className="flex justify-center items-center h-full text-gray-500">
                Select a chat to start messaging
              </div>
            ) : chatroomLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loading />
              </div>
            ) : chatroomError ? (
              <div className="text-red-500 text-center p-4">{chatroomError.message}</div>
            ) : (
              chatroom && (
                <ChatInterface
                  chatRoomId={chatroom.chatroom_id}
                  chatRoomName={chatroom.name}
                  currentUser={{
                    id: currentUserProfile.user_id,
                    name: currentUserProfile.username,
                  }}
                  otherProfiles={otherProfiles}
                  chatroomType={chatroom.chatroom_type}
                  participants={Object.keys(chatroom.members)}
                />
              )
            )}
          </div>
        </div>
      </Suspense>
    </div>
  );
};

export default ChatPage;