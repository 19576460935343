import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TopNavBar from '../components/TopNavBar';
import UserFriendsCard from '../components/UserFriendsCard';
import UserFriendsCardSkeleton from '../components/UserFriendsCardSkeleton';
import { useUserProfile } from '../hooks/useUserProfile';
import { useFriendRequests } from '../hooks/useFriendRequests';
import { useUserFriends } from '../hooks/useUserFriends';
import { User } from '../models/user';

// Store scroll positions globally
const scrollPositions = new Map<string, number>();

const FriendsPage: React.FC = () => {
  const location = useLocation();
  const { userProfile, isLoading: profileLoading } = useUserProfile();
  const { incomingRequests, sentRequests, refresh: refreshRequests, loading: requestsLoading } = useFriendRequests();
  const { friends, loading: friendsLoading } = useUserFriends(userProfile?.user_id);
  const [activeTab, setActiveTab] = useState<'friends' | 'sent' | 'received'>('friends');

  useEffect(() => {
    refreshRequests();
  }, [location.pathname]);

  // Restore scroll position on mount and save it on unmount
  useEffect(() => {
    const mainContent = document.getElementById('friends-page-content');
    if (mainContent) {
      const savedPosition = scrollPositions.get(location.pathname) || 0;
      mainContent.scrollTop = savedPosition;

      return () => {
        scrollPositions.set(location.pathname, mainContent.scrollTop);
      };
    }
  }, [location.pathname]);

  // Show loading state during initial data fetch
  const isLoading = profileLoading || requestsLoading || friendsLoading;

  const pageContent = (
    <div className="h-full">
      <div className="container px-4 max-w-7xl mx-auto min-h-0">
        {/* Tabs */}
        <div className="border-b mb-4 sticky top-0 bg-white z-10 pt-4">
          <div className="flex gap-6 -mb-px text-sm">
            {isLoading ? (
              <>
                <div className="py-2 px-0 border-b-2 border-blue-500 text-blue-500 font-medium flex items-center">
                  Friends
                  <span className="ml-2 text-xs font-medium text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full min-w-[2rem] text-center invisible">
                    0
                  </span>
                </div>
                <div className="py-2 px-0 text-gray-500 flex items-center">
                  Sent
                  <span className="ml-2 text-xs font-medium text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full min-w-[2rem] text-center invisible">
                    0
                  </span>
                </div>
                <div className="py-2 px-0 text-gray-500 flex items-center">
                  Received
                  <span className="ml-2 text-xs font-medium text-gray-500 bg-gray-100 px-2 py-0.5 rounded-full min-w-[2rem] text-center invisible">
                    0
                  </span>
                </div>
              </>
            ) : (
              <>
                <button
                  onClick={() => setActiveTab('friends')}
                  className={`py-2 flex items-center ${
                    activeTab === 'friends'
                      ? 'border-b-2 border-blue-500 text-blue-500 font-medium'
                      : 'text-gray-500'
                  }`}
                >
                  Friends
                  <span className={`ml-2 text-xs font-medium ${friends.length === 0 ? 'invisible' : 'text-gray-500 bg-gray-100'}  px-2 py-0.5 rounded-full min-w-[2rem] text-center`}>
                    {friends.length || '\u200B'}
                  </span>
                </button>
                <button
                  onClick={() => setActiveTab('sent')}
                  className={`py-2 flex items-center ${
                    activeTab === 'sent'
                      ? 'border-b-2 border-blue-500 text-blue-500 font-medium'
                      : 'text-gray-500'
                  }`}
                >
                  Sent
                  <span className={`ml-2 text-xs font-medium ${sentRequests.length === 0 ? 'invisible' : 'text-gray-500 bg-gray-100'}  px-2 py-0.5 rounded-full min-w-[2rem] text-center`}>
                    {sentRequests.length || '\u200B'}
                  </span>
                </button>
                <button
                  onClick={() => setActiveTab('received')}
                  className={`py-2 flex items-center ${
                    activeTab === 'received'
                      ? 'border-b-2 border-blue-500 text-blue-500 font-medium'
                      : 'text-gray-500'
                  }`}
                >
                  Received
                  <span className={`ml-2 text-xs font-medium ${
                    incomingRequests.length === 0 
                      ? 'invisible' 
                      : 'text-white bg-red-500'
                  } px-2 py-0.5 rounded-full min-w-[2rem] text-center`}>
                    {incomingRequests.length || '\u200B'}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>

        {/* Content */}
        {isLoading ? (
          <UserFriendsCardSkeleton />
        ) : (
          <div className="space-y-4">
            {activeTab === 'friends' && (
              <UserFriendsCard 
                friends={friends} 
                loading={friendsLoading}
                emptyTitle="No Friends Yet"
                emptyMessage="Start connecting with other players to build your friend list!"
              />
            )}
            {activeTab === 'sent' && (
              <UserFriendsCard 
                friends={sentRequests.map(request => request.user)} 
                loading={requestsLoading}
                emptyTitle="No Sent Requests"
                emptyMessage="You haven't sent any friend requests yet."
              />
            )}
            {activeTab === 'received' && (
              <UserFriendsCard 
                friends={incomingRequests.map(request => request.user)} 
                loading={requestsLoading}
                emptyTitle="No Received Requests"
                emptyMessage="You don't have any pending friend requests."
              />
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <TopNavBar />
      <main id="friends-page-content" className="flex-1 overflow-y-auto pb-24 min-h-0">
        {pageContent}
      </main>
    </div>
  );
};

export default FriendsPage;