import React from 'react';

interface LoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean;
  children: React.ReactNode;
  buttonClass?: string;    // Ensure this matches usage
  loadingText?: string;    // Ensure this matches usage
  onClick?: (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => void;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  children,
  buttonClass = 'bg-indigo-600 hover:bg-indigo-700 text-white', // Default styles
  loadingText = 'Loading...',
  disabled,
  onClick,
  ...props
}) => {
  // Handle both click and touch events
  const handleInteraction = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    // If onClick is provided and button is not disabled/loading, call it
    if (onClick && !isLoading && !disabled) {
      onClick(event);
    }
  };

  return (
    <button
      disabled={isLoading || disabled}
      className={`flex items-center justify-center px-3 py-2 sm:py-2 text-sm sm:text-base rounded-md sm:rounded-md transition duration-300 select-none whitespace-nowrap ${
        isLoading || disabled ? 'opacity-50 cursor-not-allowed' : ''
      } ${buttonClass}`}
      onClick={handleInteraction}
      {...props}
      style={{
        WebkitTapHighlightColor: 'transparent',
        touchAction: 'manipulation',
        userSelect: 'none',
        ...props.style
      }}
    >
      {isLoading ? (
        <>
          <svg
            className="animate-spin h-4 w-4 sm:h-5 sm:w-5 text-current mr-2 select-none"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25 select-none"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75 select-none"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
          <span className="select-none">{loadingText}</span>
        </>
      ) : (
        <span className="select-none">{children}</span>
      )}
    </button>
  );
};

export default LoadingButton;