import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { User } from '../models/user';
import { apiClient } from '../models/api';

interface UserContextType {
    user: User | null;
    isLoading: boolean;
    error: Error | null;
    refreshUser: () => Promise<void>;
    updateUser: (updates: Partial<User>) => Promise<void>;
    isAuthenticated: boolean | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [lastFetch, setLastFetch] = useState<number>(0);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const FETCH_DEBOUNCE_MS = 1000; // Prevent refetching within 1 second

    const fetchUser = useCallback(async () => {
        const now = Date.now();
        if (now - lastFetch < FETCH_DEBOUNCE_MS) {
            return; // Skip if called too soon after last fetch
        }
        setLastFetch(now);

        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                setUser(null);
                setIsAuthenticated(false);
                setIsLoading(false);
                return;
            }

            // If we already know we're not authenticated, don't make the call
            if (isAuthenticated === false) {
                setIsLoading(false);
                return;
            }

            const currentUser = await User.fetchCurrentUser();
            setUser(currentUser);
            setIsAuthenticated(true);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to fetch user'));
            setUser(null);
            setIsAuthenticated(false);
        } finally {
            setIsLoading(false);
        }
    }, [lastFetch, isAuthenticated]);

    const refreshUser = useCallback(async () => {
        // If we already know we're not authenticated, don't refresh
        if (isAuthenticated === false) {
            return;
        }
        setIsLoading(true);
        await fetchUser();
    }, [fetchUser, isAuthenticated]);

    const updateUser = useCallback(async (updates: Partial<User>) => {
        if (!user) return;
        
        try {
            setIsLoading(true);
            const updatedUser = await new User(user).updateUser(updates);
            setUser(updatedUser);
            setError(null);
        } catch (err) {
            setError(err instanceof Error ? err : new Error('Failed to update user'));
        } finally {
            setIsLoading(false);
        }
    }, [user]);

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            fetchUser();
        } else {
            setUser(null);
            setIsAuthenticated(false);
            setIsLoading(false);
        }
    }, [fetchUser]);

    const value = {
        user,
        isLoading,
        error,
        refreshUser,
        updateUser,
        isAuthenticated,
    };

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
