import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../models/api';
import { useUser } from '../contexts/UserContext';
import { clearAllState } from '../utils/clearState';

interface AuthState {
    isAuthenticated: boolean;
    isLoading: boolean;
}

export const useAuth = () => {
    const navigate = useNavigate();
    const { user, refreshUser, isAuthenticated: contextIsAuthenticated } = useUser();
    const [authState, setAuthState] = useState<AuthState>({
        isAuthenticated: Boolean(localStorage.getItem('accessToken')),
        isLoading: true
    });

    const validateAndRefreshTokens = async () => {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!accessToken || !refreshToken) {
            // Clear any partial token state
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            apiClient.setAccessToken(null);
            apiClient.setRefreshToken(null);
            setAuthState(prev => ({ ...prev, isAuthenticated: false, isLoading: false }));
            return false;
        }

        try {
            // Validate token format before attempting refresh
            const tokenParts = accessToken.split('.');
            if (tokenParts.length !== 3) {
                throw new Error('Invalid token format');
            }

            await refreshUser();
            setAuthState({ isAuthenticated: true, isLoading: false });
            return true;
        } catch (error) {
            // Clear tokens if validation fails
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            apiClient.setAccessToken(null);
            apiClient.setRefreshToken(null);
            setAuthState({ isAuthenticated: false, isLoading: false });
            return false;
        }
    };

    useEffect(() => {
        // Only validate if we don't already know the authentication status
        if (contextIsAuthenticated === null) {
            validateAndRefreshTokens();
        } else {
            setAuthState({ isAuthenticated: contextIsAuthenticated, isLoading: false });
        }
    }, [contextIsAuthenticated]);

    // Listen for storage changes (e.g., from other tabs)
    useEffect(() => {
        const handleStorageChange = () => {
            validateAndRefreshTokens();
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const signOut = async () => {
        try {
            // Clear all application state
            clearAllState();
            
            // Update auth state
            setAuthState({ isAuthenticated: false, isLoading: false });
            
            // Navigate to login
            navigate('/login');
        } catch (error) {
            console.error('Error during sign out:', error);
            // Still navigate to login even if there's an error
            navigate('/login');
        }
    };

    return {
        isAuthenticated: authState.isAuthenticated,
        isLoading: authState.isLoading,
        user,
        signOut,
    };
};