import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	FaMapMarkerAlt,
	FaUsers,
	FaShare,
	FaTimesCircle,
	FaCheckCircle,
	FaUserCircle,
	FaClock,
	FaExternalLinkAlt,
	FaCalendarPlus,
	FaChevronUp,
	FaChevronDown,
	FaEdit,
} from 'react-icons/fa';
import { useOtherUserProfile } from '../hooks/useOtherUserProfile';
import { useUserProfile } from '../hooks/useUserProfile';
import LoadingButton from './LoadingButton';
import Modal from './Modal';
import { Game } from '../models/games';
import FrostedImageContainer from './FrostedImageContainer';

interface GameCardProps {
	game: Game;
	userID: string;
	onJoin?: (gameId: string) => void;
	onLeave?: (gameId: string) => void;
	loading?: boolean;
}

const NewGameCard: React.FC<GameCardProps> = ({ game, userID, onJoin, onLeave, loading = false }) => {
	const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
	const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
	const [isLeaving, setIsLeaving] = useState(false);
	const [hasJoined, setHasJoined] = useState(game?.members?.has(userID) || false);
	const { userProfile: creator, refreshProfile: refreshCreator } = useOtherUserProfile(game?.creator_id || '');
	const { userProfile } = useUserProfile();
	const navigate = useNavigate();

	// Refresh creator profile when game updates
	useEffect(() => {
		refreshCreator();
	}, [game, refreshCreator]);

	/**
	 * Helper to check if current user has joined the game
	 */
	const checkIfUserHasJoined = (gameObj: Game) => {
		return gameObj.members?.has(userID) || false;
	};

	/** Helper function for exponential backoff retry */
	const retryWithBackoff = async (operation: () => Promise<any>, maxAttempts = 10, baseDelay = 100) => {
		for (let attempt = 1; attempt <= maxAttempts; attempt++) {
			try {
				const result = await operation();
				return result;
			} catch (error) {
				if (attempt === maxAttempts) throw error;
				
				// Calculate delay with exponential backoff, capped at 5000ms
				const delay = Math.min(baseDelay * Math.pow(2, attempt - 1), 5000);
				await new Promise(resolve => setTimeout(resolve, delay));
			}
		}
	};

	/** Verify game membership status */
	const verifyMembershipStatus = async (gameId: string, userId: string, expectedStatus: boolean): Promise<boolean> => {
		const refreshedGame = await Game.fetchById(gameId);
		return expectedStatus === refreshedGame.isUserJoined(userId);
	};

	const handleJoinClick = async (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (!game || !userID) return;

		if (hasJoined) {
			setShowLeaveConfirm(true);
		} else {
			try {
				await game.joinGame();
				
				// Verify join status with retries
				await retryWithBackoff(async () => {
					const isJoined = await verifyMembershipStatus(game.game_id, userID, true);
					if (!isJoined) throw new Error('Join verification failed');
					
					const updatedGame = await Game.fetchById(game.game_id);
					setHasJoined(checkIfUserHasJoined(updatedGame));
					if (onJoin) {
						onJoin(game.game_id);
					}
				});
			} catch (err) {
				console.error('Failed to join game:', err);
			}
		}
	};

	const handleLeaveGame = async () => {
		if (!game || !userID) return;
		setIsLeaving(true);
		try {
			await game.leaveGame();
			
			// Verify leave status with retries
			await retryWithBackoff(async () => {
				const hasLeft = await verifyMembershipStatus(game.game_id, userID, false);
				if (!hasLeft) throw new Error('Leave verification failed');
				
				const updatedGame = await Game.fetchById(game.game_id);
				setHasJoined(checkIfUserHasJoined(updatedGame));
				if (onLeave) {
					onLeave(game.game_id);
				}
			});
			setShowLeaveConfirm(false);
		} catch (err) {
			console.error('Failed to leave game:', err);
		} finally {
			setIsLeaving(false);
		}
	};

	const handleGameClick = () => {
		navigate('/game/' + game.game_id);
		// Refresh dashboard when returning from game details
	};

	/**
	 * Share game with improved fallback
	 */
	const handleShare = async (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const url = `${window.location.origin}/game/${game.game_id}`;
		const title = game.name; // Just use the game name without "| Kickoff"
		const description =
			game.description ||
			`Join us for an exciting match at ${
				game.location || 'a secret location'
			}. This game promises to be a thrilling encounter with skilled players and great sportsmanship.`;
		const image = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/1c690c6c-e593-4328-22a9-d9fb30ec4700/public';

		try {
			if (navigator.share) {
				const shareData: ShareData = {
					title, // Use just the game name for native share
					text: description,
					url: url,
				};
				await navigator.share(shareData);
			} else {
				// Fallback: copy link
				await navigator.clipboard.writeText(url);
				alert('Game link copied to clipboard!');
			}
		} catch (error) {
			console.error('Error sharing:', error);
			// Fallback if sharing fails
			try {
				await navigator.clipboard.writeText(url);
				alert('Game link copied to clipboard!');
			} catch (clipboardError) {
				console.error('Error copying to clipboard:', clipboardError);
			}
		}
	};

	// Use joined_member_count from game object like GameDetails
	const memberCount = game.joined_member_count || 0;

	const isFull = memberCount >= game.max_members;
	const isWaitlisted = false; //game.waitlist?.has(userID);

	// Loading skeleton
	if (loading) {
		return (
			<div className="block bg-white rounded-lg shadow-sm transition-shadow duration-200">
				{/* Header with host info and game name */}
				<div className="px-4 py-3 sm:px-6 flex items-center border-b border-gray-200">
					<div className="w-12 h-12 rounded-full bg-gray-200 animate-pulse mr-3" />
					<div className="flex-1">
						<div className="h-6 bg-gray-200 rounded w-48 mb-2 animate-pulse" />
						<div className="h-4 bg-gray-200 rounded w-32 animate-pulse" />
					</div>
					<div className="flex items-center gap-2">
						<div className="h-6 w-16 bg-gray-200 rounded-full animate-pulse" />
					</div>
				</div>

				{/* Game image */}
				<div className="relative h-64 w-full overflow-hidden">
					<div className="absolute inset-0 bg-gray-200 animate-pulse" />
				</div>

				{/* Central game details */}
				<div className="px-4 py-4 sm:px-6 space-y-3 sm:space-y-4 border-b border-gray-200">
					<div className="flex flex-col space-y-3">
						{/* Date and Time */}
						<div className="flex items-start gap-3">
							<div className="w-5 h-5 bg-gray-200 rounded-full animate-pulse mt-1" />
							<div className="flex-1">
								<div className="h-5 bg-gray-200 rounded w-40 mb-1 animate-pulse" />
								<div className="h-4 bg-gray-200 rounded w-32 animate-pulse" />
							</div>
						</div>
						{/* Location */}
						<div className="flex items-start gap-3">
							<div className="w-5 h-5 bg-gray-200 rounded-full animate-pulse mt-1" />
							<div className="flex-1">
								<div className="h-5 bg-gray-200 rounded w-48 mb-1 animate-pulse" />
								<div className="h-4 bg-gray-200 rounded w-40 animate-pulse" />
							</div>
						</div>
					</div>
					{/* Description */}
					<div className="px-2 pt-4">
						<div className="h-4 bg-gray-200 rounded w-full mb-2 animate-pulse" />
						<div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse" />
					</div>
				</div>

				{/* Action buttons */}
				<div className="px-4 py-4 sm:px-6 flex items-center justify-between">
					<div className="flex-1 pr-2">
						<div className="w-full h-10 bg-gray-200 rounded-lg animate-pulse" />
					</div>
					<div className="flex-shrink-0 w-24 h-10 bg-gray-200 rounded-lg animate-pulse" />
				</div>
			</div>
		);
	}

	return (
		<>
			<Link
				to={`/game/${game.game_id}`}
				onClick={handleGameClick}
				className="block bg-white rounded-lg shadow-sm transition-shadow duration-200 select-none"
			>
				{/* Header with host info and game name */}
				<div className="px-4 py-3 sm:px-6 flex items-center border-b border-gray-200 select-none">
					{/* Host avatar */}
					{creator && (
						<div className="mr-3">
							<Link to={`/u/${creator.username}`} onClick={(e) => e.stopPropagation()}>
								{creator.profile_picture_url ? (
									<img 
										key={creator.profile_picture_url} 
										src={creator.profile_picture_url} 
										alt={creator.username} 
										className="w-12 h-12 rounded-full object-cover" 
									/>
								) : (
									<FaUserCircle className="w-12 h-12 text-gray-400" />
								)}
							</Link>
						</div>
					)}
					{/* Game Info */}
					<div className="flex-1 select-none">
						<h2 className="text-lg sm:text-xl font-semibold text-gray-800 select-none">{game.name}</h2>
						{creator && <p className="text-xs sm:text-sm text-gray-500 select-none">{creator.username}</p>}
					</div>
					{/* Player count */}
					<div className="flex items-center gap-2 text-gray-500 select-none">
						<span className="text-sm px-2 py-0.5 rounded-full bg-gray-100 select-none">
							{memberCount}/{game.max_members}
						</span>
					</div>
				</div>

				{/* Game image */}
				<div className="relative h-64 w-full overflow-hidden">
					<div className="absolute inset-0">
						<FrostedImageContainer
							src={game.cover_image ? (game.cover_image) : 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/1c690c6c-e593-4328-22a9-d9fb30ec4700/public'}
							alt={game.name}
						/>
					</div>
				</div>

				{/* Central game details: date/time/location/description */}
				<div className="px-4 py-4 sm:px-6 space-y-3 sm:space-y-4 border-b border-gray-200 select-none">
					{/* Date/Time/Location row */}
					<div className="flex flex-col space-y-3 select-none">
						{/* Date and Time */}
						<div className="flex items-start gap-3 text-gray-800 select-none">
							<FaClock className="text-indigo-600 w-5 h-5 mt-1 flex-shrink-0" />
							<div className="leading-tight select-none">
								<div className="text-sm sm:text-base text-gray-800 select-none">
									{new Date(game.scheduled_at).toLocaleDateString('en-US', {
										weekday: 'long',
										month: 'long',
										day: 'numeric',
										year: 'numeric',
									})}
								</div>
								<div className="text-xs sm:text-sm text-gray-500 select-none">
									{new Date(game.scheduled_at).toLocaleTimeString('en-US', {
										hour: 'numeric',
										minute: '2-digit',
										hour12: true,
									})}
								</div>
							</div>
						</div>

						{/* Location */}
						{game.location && (
							<div className="flex items-start gap-3 text-gray-800 select-none">
								<FaMapMarkerAlt className="text-indigo-600 w-5 h-5 mt-1 flex-shrink-0" />
								<div className="flex-1 leading-tight select-none">
									<div className="text-sm sm:text-base text-gray-800 select-none">{game.location.split(',')[0]}</div>
									<div className="text-xs sm:text-sm text-gray-500 select-none">
										<a
											href={`https://maps.google.com/?q=${encodeURIComponent(game.location)}`}
											target="_blank"
											rel="noopener noreferrer"
											className="text-xs sm:text-sm text-gray-500 hover:underline active:text-indigo-600 select-none"
											title="Open in Google Maps"
											onClick={(e) => e.stopPropagation()}
										>
											{game.location}
										</a>
									</div>
								</div>
							</div>
						)}
					</div>

					{/* Description */}
					<div
						onClick={handleGameClick}
						// onClick={(e) => {
						//   e.preventDefault();
						//   e.stopPropagation();
						//   setIsDescriptionExpanded(!isDescriptionExpanded);
						// }}
						className="relative select-none cursor-pointer"
					>
						<p className={`text-sm sm:text-base px-2 pt-4 text-gray-500 leading-relaxed select-none line-clamp-3`}>
							{game.description || `Join us for an exciting match at ${game.location || 'a secret location'}!`}
						</p>
					</div>
				</div>

				{/* Action buttons row: Join/Leave, Share, etc. */}
				<div className="px-4 py-4 sm:px-6 flex items-center justify-between select-none">
					{/* Left - Join/Leave button or Edit button for creator */}
					<div className="flex-1 pr-2 select-none">
						{userID === game.creator_id ? (
							<button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									navigate(`/game/${game.game_id}?edit=true`);
								}}
								className="w-full bg-gray-100 text-gray-700 px-3 py-2 rounded-lg hover:bg-gray-200 transition duration-300 flex items-center justify-center gap-2 text-sm select-none"
							>
								<FaEdit className="text-indigo-600" />
								<span className="font-medium">Edit Game</span>
							</button>
						) : hasJoined ? (
							<button
								onClick={handleJoinClick}
								className="w-full bg-gray-100 text-gray-700 px-3 py-2 rounded-lg hover:bg-gray-200 transition duration-300 flex items-center justify-center gap-2 text-sm select-none"
							>
								<FaCheckCircle className="text-green-600" />
								<span className="font-medium">Joined</span>
								<span className="text-gray-500 text-xs">(Tap to Leave)</span>
							</button>
						) : isFull ? (
							isWaitlisted ? (
								<button
									onClick={handleJoinClick}
									className="w-full bg-red-500 text-white px-3 py-2 rounded-lg hover:bg-red-600 transition duration-300 flex items-center justify-center gap-2 text-sm select-none"
								>
									<FaTimesCircle />
									<span className="font-medium">Leave Waitlist</span>
								</button>
							) : (
								<button
									onClick={handleJoinClick}
									className="w-full bg-yellow-500 text-white px-3 py-2 rounded-lg hover:bg-yellow-600 transition duration-300 flex items-center justify-center gap-2 text-sm select-none"
								>
									<FaCalendarPlus />
									<span className="font-medium">Join Waitlist</span>
								</button>
							)
						) : (
							<button
								onClick={handleJoinClick}
								className="w-full bg-gray-100 text-gray-700 px-3 py-2 rounded-lg hover:bg-gray-200 transition duration-300 flex items-center justify-center gap-2 text-sm select-none"
							>
								<FaUsers className="text-indigo-600" />
								<span className="font-medium">Join Game</span>
							</button>
						)}
					</div>

					{/* Right - Share button */}
					<button
						onClick={handleShare}
						className="flex-shrink-0 bg-indigo-600 text-white px-3 py-2 rounded-lg hover:bg-indigo-700 transition duration-300 flex items-center gap-2 text-sm select-none"
					>
						<FaShare />
						<span className="font-medium">Share</span>
					</button>
				</div>
			</Link>

			{/* Leave Confirmation Modal */}
			{showLeaveConfirm && (
				<Modal onClose={() => setShowLeaveConfirm(false)} className="w-72">
					<div className="p-4 select-none">
						<div className="text-gray-800 font-medium mb-1">Leave game?</div>
						<div className="text-gray-600 text-sm mb-3">You can always rejoin if spots are available.</div>
						<div className="flex gap-2">
							<LoadingButton
								onClick={handleLeaveGame}
								isLoading={isLeaving}
								className="flex-1 bg-red-500 text-white px-3 py-1.5 rounded-md text-sm font-medium hover:bg-red-600 transition-colors flex items-center justify-center gap-1"
							>
								Leave
							</LoadingButton>
							<button
								onClick={() => setShowLeaveConfirm(false)}
								className="flex-1 bg-gray-100 text-gray-700 px-3 py-1.5 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
							>
								Cancel
							</button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default NewGameCard;
