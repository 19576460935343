import React, { useState } from 'react';
import { FaTimes, FaUserMinus, FaFlag, FaBan } from 'react-icons/fa';
import Modal from './Modal';

interface UserSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUnfriend?: () => void;
  onReport: (reason: string, details: string) => void;
  onBlock: () => void;
  isFriend: boolean;
}

const REPORT_REASONS = [
  'Inappropriate behavior',
  'Harassment',
  'Spam',
  'Fake profile',
  'Other'
];

const UserSettingsModal: React.FC<UserSettingsModalProps> = ({
  isOpen,
  onClose,
  onUnfriend,
  onReport,
  onBlock,
  isFriend
}) => {
  // Modal states
  const [showUnfriendConfirm, setShowUnfriendConfirm] = useState(false);
  const [showReportForm, setShowReportForm] = useState(false);
  const [showBlockConfirm, setShowBlockConfirm] = useState(false);

  // Report form state
  const [selectedReason, setSelectedReason] = useState('');
  const [reportDetails, setReportDetails] = useState('');
  const [showBlockAfterReport, setShowBlockAfterReport] = useState(false);

  const handleReport = () => {
    onReport(selectedReason, reportDetails);
    if (showBlockAfterReport) {
      onBlock();
    }
    setShowReportForm(false);
    setSelectedReason('');
    setReportDetails('');
    setShowBlockAfterReport(false);
    onClose();
  };

  const handleBlock = () => {
    onBlock();
    setShowBlockConfirm(false);
    onClose();
  };

  const handleUnfriend = () => {
    onUnfriend?.();
    setShowUnfriendConfirm(false);
    onClose();
  };

  if (showUnfriendConfirm) {
    return (
      <>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={() => setShowUnfriendConfirm(false)} />
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 bg-white rounded-lg shadow-lg p-4 z-50">
          <div className="text-gray-800 font-medium mb-2">Unfriend User?</div>
          <div className="text-gray-600 text-sm mb-4">
            Are you sure you want to remove this user from your friends list?
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleUnfriend}
              className="flex-1 bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition-colors"
            >
              Unfriend
            </button>
            <button
              onClick={() => setShowUnfriendConfirm(false)}
              className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    );
  }

  if (showReportForm) {
    return (
      <>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={() => setShowReportForm(false)} />
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[32rem] bg-white rounded-lg shadow-lg p-4 z-50">
          <div className="text-gray-800 font-medium mb-4">Report User</div>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Reason for reporting
              </label>
              <select
                value={selectedReason}
                onChange={(e) => setSelectedReason(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select a reason</option>
                {REPORT_REASONS.map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Additional details
              </label>
              <textarea
                value={reportDetails}
                onChange={(e) => setReportDetails(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 min-h-[100px]"
                placeholder="Please provide any additional details about the issue..."
              />
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="blockAfterReport"
                checked={showBlockAfterReport}
                onChange={(e) => setShowBlockAfterReport(e.target.checked)}
                className="rounded text-blue-600 focus:ring-blue-500"
              />
              <label htmlFor="blockAfterReport" className="text-sm text-gray-600">
                Block this user after reporting
              </label>
            </div>
          </div>
          <div className="flex gap-2 mt-6">
            <button
              onClick={handleReport}
              disabled={!selectedReason}
              className="flex-1 bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Submit Report
            </button>
            <button
              onClick={() => setShowReportForm(false)}
              className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    );
  }

  if (showBlockConfirm) {
    return (
      <>
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={() => setShowBlockConfirm(false)} />
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 bg-white rounded-lg shadow-lg p-4 z-50">
          <div className="text-gray-800 font-medium mb-2">Block User?</div>
          <div className="text-gray-600 text-sm mb-4">
            This user will no longer be able to:
            <ul className="list-disc ml-6 mt-2">
              <li>See your profile</li>
              <li>Send you messages</li>
              <li>Send you friend requests</li>
              <li>Interact with you in games</li>
            </ul>
          </div>
          <div className="flex gap-2">
            <button
              onClick={handleBlock}
              className="flex-1 bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition-colors"
            >
              Block User
            </button>
            <button
              onClick={() => setShowBlockConfirm(false)}
              className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={onClose} />
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 bg-white rounded-lg shadow-lg p-4 z-50">
        {/* <div className="text-gray-800 font-medium mb-2">Settings</div> */}
        <div className="space-y-2">
          {isFriend && (
            <button
              onClick={() => setShowUnfriendConfirm(true)}
              className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 rounded-md flex items-center gap-3 transition-colors"
            >
              <FaUserMinus className="text-gray-400" />
              <span>Unfriend</span>
            </button>
          )}
          <button
            onClick={() => setShowReportForm(true)}
            className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 rounded-md flex items-center gap-3 transition-colors"
          >
            <FaFlag className="text-gray-400" />
            <span>Report</span>
          </button>
          <button
            onClick={() => setShowBlockConfirm(true)}
            className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 rounded-md flex items-center gap-3 transition-colors"
          >
            <FaBan className="text-gray-400" />
            <span>Block</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default UserSettingsModal; 