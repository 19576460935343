import api from './api'; // Import the api module

export interface Friend {
	user_id_1: string;
	user_id_2: string;
	friended_at: Date;
}

export interface FriendRequest {
	request_id: string;
	requester_id: string;
	recipient_id: string;
	status: string;
}

export class User {
	user_id: string;
	auth_provider: string;
	auth_provider_id: string;
	bio: string;
	created_at: Date;
	email: string;
	email_verified: boolean;
	first_name: string;
	hashed_password: string;
	last_name: string;
	phone_number: string;
	phone_number_verified: boolean;
	previous_usernames: string[];
	profile_picture_url: string;
	cover_picture_url: string;
	standing_updated_at: Date;
	tagline: string;
	updated_at: Date;
	user_standing: string;
	username: string;
	birth_date: Date;
	location: string;
	has_accepted_waiver: boolean;
	waiver_accepted_at: Date | null;
	privacy_settings: {
		public_profile: boolean;
		allow_search: boolean;
		allow_invites: boolean;
	};

	isComplete(): boolean {
		return Boolean(this.first_name) && Boolean(this.last_name) && Boolean(this.bio) && Boolean(this.profile_picture_url);
	}

	private static currentUserCache: User | null = null;
	private static cacheTimestamp: number | null = null;
	private static readonly CACHE_DURATION = 300000; // 5 minute cache

	private static userByIdCache: Map<string, { user: User; timestamp: number }> = new Map();
	private static readonly USER_CACHE_DURATION = 300000; // 5 minute cache for other users too

	private static friendRequestsCache: Map<string, { incomingRequests: FriendRequest[]; sentRequests: FriendRequest[]; timestamp: number }> = new Map();
	private static readonly FRIEND_REQUESTS_CACHE_DURATION = 300000; // 5 minutes

	static async fetchCurrentUser(forceRefresh: boolean = false): Promise<User> {
		const now = Date.now();

		// Return cached user if it exists and cache hasn't expired
		if (!forceRefresh && this.currentUserCache && this.cacheTimestamp && now - this.cacheTimestamp < this.CACHE_DURATION) {
			return this.currentUserCache;
		}

		try {
			const response = await api.get('/api/v1/user/me');
			this.currentUserCache = new User(response.data);
			this.cacheTimestamp = now;
			return this.currentUserCache;
		} catch (error) {
			this.currentUserCache = null;
			this.cacheTimestamp = null;
			throw error;
		}
	}

	static clearCurrentUserCache() {
		this.currentUserCache = null;
		this.cacheTimestamp = null;
	}

	static async getFriendRequests(): Promise<FriendRequest[]> {
		const currentUser = await User.fetchCurrentUser(false);
		const userId = currentUser.user_id;
		const now = Date.now();
		const cached = this.friendRequestsCache.get(userId);

		if (cached && now - cached.timestamp < this.FRIEND_REQUESTS_CACHE_DURATION) {
			return cached.incomingRequests;
		}

		try {
			const response = await api.get('/api/v1/user/friend-requests');
			const incomingRequests = response.data as FriendRequest[];
			
			// Get the current cache state or create a new one
			const currentCache = this.friendRequestsCache.get(userId) || {
				incomingRequests: [],
				sentRequests: [],
				timestamp: now
			};

			// Update only the incoming requests part
			currentCache.incomingRequests = incomingRequests;
			currentCache.timestamp = now;
			this.friendRequestsCache.set(userId, currentCache);
			
			return incomingRequests;
		} catch (error) {
			throw new Error('Failed to retrieve incoming friend requests');
		}
	}

	static async getSentFriendRequests(): Promise<FriendRequest[]> {
		const currentUser = await User.fetchCurrentUser(false);
		const userId = currentUser.user_id;
		const now = Date.now();
		const cached = this.friendRequestsCache.get(userId);

		if (cached && now - cached.timestamp < this.FRIEND_REQUESTS_CACHE_DURATION) {
			return cached.sentRequests;
		}

		try {
			const response = await api.get('/api/v1/user/sent-friend-requests');
			const sentRequests = response.data as FriendRequest[];
			
			// Get the current cache state or create a new one
			const currentCache = this.friendRequestsCache.get(userId) || {
				incomingRequests: [],
				sentRequests: [],
				timestamp: now
			};

			// Update only the sent requests part
			currentCache.sentRequests = sentRequests;
			currentCache.timestamp = now;
			this.friendRequestsCache.set(userId, currentCache);
			
			return sentRequests;
		} catch (error) {
			throw new Error('Failed to retrieve sent friend requests');
		}
	}

	static clearFriendRequestsCache(userId: string) {
		this.friendRequestsCache.delete(userId);
	}

	private static userCache: Map<string, { user: User; timestamp: number }> = new Map();

	static async fetchUserByUsername(username: string, bypassCache: boolean = false): Promise<User> {
		// Check cache first
		const cachedData = this.userCache.get(username);
		if (!bypassCache && cachedData && Date.now() - cachedData.timestamp < this.CACHE_DURATION) {
			return cachedData.user;
		}

		try {
			const response = await api.get(`/api/v1/user/search?username=${username}`);
			const userData = Array.isArray(response.data) ? response.data[0] : response.data;
			const user = new User(userData);

			// Update cache
			this.userCache.set(username, { user, timestamp: Date.now() });
			return user;
		} catch (error) {
			throw new Error('Failed to fetch user profile');
		}
	}

	async updateUser(updatedFields: Partial<User>): Promise<User> {
		try {
			const response = await api.put('/api/v1/user/me', updatedFields);
			const updatedUser = new User(response.data);
			// Update cache with updated user data
			User.clearCurrentUserCache();
			return updatedUser;
		} catch (error) {
			throw new Error('Failed to update user data');
		}
	}

	async updatePassword(current_password: string, new_password: string): Promise<User> {
		try {
			const response = await api.put('/api/v1/user/me/password', { current_password, new_password });
			return new User(response.data);
		} catch (error) {
			throw new Error('Failed to update password');
		}
	}

	async sendVerificationEmail(): Promise<User> {
		try {
			const response = await api.post('/api/auth/verify');
			return new User(response.data);
		} catch (error) {
			throw new Error('Failed to send verification email');
		}
	}

	constructor(data: any) {
		Object.assign(this, data);
	}

	async setUserWaiver(waiver: string): Promise<User> {
		try {
			const response = await api.post('/api/v1/user/me/waiver', { waiver });
			return new User(response.data);
		} catch (error) {
			throw new Error('Failed to set user waiver');
		}
	}

	async getUserWaiver(): Promise<boolean> {
		try {
			const response = await api.get('/api/v1/user/me/waiver');
			// parse the response, it is a json object with a boolean signed_waiver
			const data = (await response.data) as { signed_waiver: boolean };
			return data.signed_waiver;
		} catch (error) {
			throw new Error('Failed to get user waiver');
		}
	}

	async uploadUserImage(file: File): Promise<string> {
		// generate upload url
		const uploadResponse = await api.post('/api/v1/user/me/image');
		const { upload_url } = uploadResponse.data;

		// upload file to cloudflare
		const formData = new FormData();
		formData.append('file', file);

		const response = await fetch(upload_url, {
			method: 'POST',
			body: formData,
		});

		if (!response.ok) {
			throw new Error('Failed to upload user image');
		}

		const data = (await response.json()) as {
			success: boolean;
			messages: { code: number; message: string }[];
			errors: { code: number; message: string }[];
			result: {
				id: string;
				filename: string;
				meta: {
					user_id: string;
				};
				uploaded: string;
				variants: string[];
				requireSignedURLs: boolean;
			};
		};

		if (!data.success) {
			throw new Error('Failed to upload user image');
		}
		if (data.errors.length > 0) {
			throw new Error(`Failed to upload user image: ${data.errors}`);
		}

		// if there are no variants, then throw an error
		if (data.result.variants.length === 0) {
			throw new Error('No variants returned');
		}
		// if there are no variants, then throw an error
		if (data.result.variants.length === 1) {
			return data.result.variants[0];
		}
		for (const variant of data.result.variants) {
			if (variant.endsWith('public')) {
				return variant;
			}
		}

		// if no public variant, return the last one
		return data.result.variants[data.result.variants.length - 1];
	}

	async uploadProfilePicture(file: File): Promise<string> {
		// generate upload url
		const uploadResponse = await api.post('/api/v1/user/me/profile_picture');
		const { upload_url } = uploadResponse.data;

		// upload file to cloudflare
		const formData = new FormData();
		formData.append('file', file);

		const response = await fetch(upload_url, {
			method: 'POST',
			body: formData,
		});

		if (!response.ok) {
			throw new Error('Failed to upload profile picture');
		}

		const data = (await response.json()) as {
			success: boolean;
			messages: { code: number; message: string }[];
			errors: { code: number; message: string }[];
			result: {
				id: string;
				filename: string;
				meta: {
					user_id: string;
				};
				uploaded: string;
				variants: string[];
				requireSignedURLs: boolean;
			};
		};
		if (!data.success) {
			throw new Error('Failed to upload profile picture');
		}
		if (data.errors.length > 0) {
			throw new Error(`Failed to upload profile picture: ${data.errors}`);
		}

		// if there are no variants, then throw an error
		if (data.result.variants.length === 0) {
			throw new Error('No variants returned');
		}
		// return the only variant if there is one
		// otherwise return the one that has public at the end
		// otherwise return the last one
		if (data.result.variants.length === 1) {
			return data.result.variants[0];
		}
		for (const variant of data.result.variants) {
			if (variant.endsWith('public')) {
				return variant;
			}
		}
		// if no public variant, return the last one
		return data.result.variants[data.result.variants.length - 1];
	}

	async signOut(): Promise<void> {
		await api.post('/api/auth/signout');
	}

	static async verifyEmail(code: string, turnstileToken: string): Promise<void> {
		await api.get(`/api/auth/verify?code=${code}`, {
			headers: {
				'cf-turnstile-response': turnstileToken,
			},
		});
	}

	async verifyEmail(code: string): Promise<void> {
		const response = await api.get(`/api/auth/verify?code=${code}`);
		if (response.status !== 200) {
			throw new Error('Failed to verify email');
		}
	}

	// Add this static method
	static async signWaiver(waiverData: any): Promise<User> {
		try {
			const response = await api.post('/api/v1/user/me/waiver', waiverData);
			return new User(response.data);
		} catch (error) {
			throw new Error('Failed to submit waiver');
		}
	}

	static async searchUsers(query: string): Promise<User[]> {
		const isFullName = query.trim().includes(' ');
		const isEmail = query.includes('@');

		// Decide which fields to search based on the query
		const searchFields = isEmail ? ['email'] : isFullName ? ['full_name'] : ['username', 'first_name', 'last_name'];

		// Set a timeout duration (in milliseconds)
		const TIMEOUT_DURATION = 15000; // Adjust the timeout as needed

		// Prepare API calls for each search field with timeout
		const requests = searchFields.map((field) =>
			api
				.get(`/api/v1/user/search`, {
					params: { [field]: query.trim() },
					timeout: TIMEOUT_DURATION,
				})
				.then((response) => response.data)
				.catch((error) => {
					// Log the error and return an empty array
					console.error(`Search request failed for field "${field}":`, error);
					return [];
				})
		);

		// Execute all API calls in parallel and collect results
		const results = await Promise.all(requests);

		// Collect and merge results, ignoring empty arrays
		const usersMap = new Map<string, User>(); // Use user_id as the key

		results.forEach((dataArray) => {
			if (Array.isArray(dataArray) && dataArray.length > 0) {
				dataArray.forEach((userData: any) => {
					const user = new User(userData);
					usersMap.set(user.user_id, user);
				});
			}
		});

		// Return the merged list of users
		return Array.from(usersMap.values());
	}

	static async getUserById(userId: string, bypassCache: boolean = false): Promise<User> {
		const now = Date.now();
		const cached = this.userByIdCache.get(userId);

		if (!bypassCache && cached && now - cached.timestamp < this.USER_CACHE_DURATION) {
			return cached.user;
		}

		try {
			// Use api client to ensure auth headers are set
			const response = await api.get(`/api/v1/user/search`, {
				params: { user_id: userId },
				timeout: 15000,
			});

			const results = response.data;

			if (!Array.isArray(results) || results.length === 0) {
				throw new Error('User not found');
			}

			const user = new User(results[0]);
			this.userByIdCache.set(userId, { user, timestamp: now });
			return user;
		} catch (error) {
			console.error(`Search request failed for user ID "${userId}":`, error);
			throw new Error('User not found');
		}
	}

	static async getUserByIdPublic(userId: string, bypassCache: boolean = false): Promise<User> {
		const now = Date.now();
		const cached = this.userByIdCache.get(userId);

		if (!bypassCache && cached && now - cached.timestamp < this.USER_CACHE_DURATION) {
			return cached.user;
		}

		try {
			const response = await api.get(`/api/v1/user`, {
				params: { user_id: userId },
				timeout: 15000,
			});

			const userData = response.data;
			const user = new User(userData);
			this.userByIdCache.set(userId, { user, timestamp: now });
			return user;
		} catch (error) {
			console.error(`Public user request failed for user ID "${userId}":`, error);
			throw new Error('User not found');
		}
	}

	async createFriendRequest(friendId: string): Promise<void> {
		try {
			await api.post(`/api/v1/user/friend-requests?friend_id=${friendId}`);
			// Clear cache after creating a new request
			User.clearFriendRequestsCache(this.user_id);
		} catch (error) {
			throw new Error('Failed to send friend request');
		}
	}

	static async acceptFriendRequest(friendRequest: FriendRequest): Promise<void> {
		try {
			await api.put('/api/v1/user/friend-requests', JSON.stringify(friendRequest));
			// Clear cache after accepting a request
			const currentUser = await User.fetchCurrentUser(false);
			User.clearFriendRequestsCache(currentUser.user_id);
		} catch (error) {
			throw new Error('Failed to accept friend request');
		}
	}

	static async getFriends(): Promise<string[]> {
		try {
			const response = await api.get('/api/v1/user/friends');
			return response.data as string[];
		} catch (error) {
			throw new Error('Failed to retrieve friends');
		}
	}

	static async unfriend(friendId: string): Promise<void> {
		try {
			await api.delete(`/api/v1/user/friends?friend_id=${friendId}`);
		} catch (error) {
			throw new Error('Failed to unfriend');
		}
	}

	static async getFriendStatus(friendId: string): Promise<Friend | null> {
		try {
			const response = await api.get(`/api/v1/user/friends?friend_id=${friendId}`);
			if (response.status === 200) {
				return response.data as Friend;
			}
			return null;
		} catch (error) {
			throw new Error('Failed to retrieve friend status');
		}
	}

	/**
	 * Report a user
	 */
	static async reportUser(userId: string, reason: string, details: string): Promise<void> {
		try {
			await api.post('/api/v1/user/report', {
				reported_user_id: userId,
				reason,
				details
			});
		} catch (error) {
			throw new Error('Failed to report user');
		}
	}

	/**
	 * Block a user
	 */
	async blockUser(userId: string): Promise<void> {
		try {
			await api.post('/api/v1/user/block', {
				blocked_user_id: userId
			});
		} catch (error) {
			throw new Error('Failed to block user');
		}
	}
}
