import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
	FaUserCircle,
	FaUserEdit,
	FaSignOutAlt,
	FaPlusCircle,
	FaUser,
	FaCog,
	FaComments,
	FaHome,
	FaUsers,
	FaUserFriends,
} from 'react-icons/fa';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import SearchBar from './SearchBar';
import { useNotifications } from '../hooks/useNotifications';
import { useAuth } from '../hooks/useAuth';
import { useViewMode } from '../contexts/ViewModeContext';
import { useFriendRequests } from '../hooks/useFriendRequests';

interface TopNavBarProps {
	onMenuClick?: () => void;
	className?: string;
	showMobileSearch?: boolean;
}

const TopNavBar: React.FC<TopNavBarProps> = ({ onMenuClick, className, showMobileSearch = false }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showDropdown, setShowDropdown] = useState(false);
	const [showMobileSettings, setShowMobileSettings] = useState(false);
	const [showCreateDropdown, setShowCreateDropdown] = useState(false);
	const [showSignOutConfirm, setShowSignOutConfirm] = useState(false);
	const [isSigningOut, setIsSigningOut] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const mobileSettingsRef = useRef<HTMLDivElement>(null);
	const createDropdownRef = useRef<HTMLDivElement>(null);
	const [showNotifications, setShowNotifications] = useState(false);
	const notificationsRef = useRef<HTMLDivElement>(null);

	const { isAuthenticated, user, signOut } = useAuth();

	const {
		notifications,
		loading: notificationsLoading,
		markAsRead,
		deleteNotification,
		unreadCount,
	} = useNotifications({
		accountId: user?.username || '',
		limit: 20,
		isAuthenticated: isAuthenticated && !!user?.username,
	});

	const { incomingRequests } = useFriendRequests();

	const handleSignOut = async () => {
		setIsSigningOut(true);
		try {
			await signOut();
			navigate('/signin');
		} catch (error) {
			console.error('Failed to sign out:', error);
		} finally {
			setIsSigningOut(false);
			setShowSignOutConfirm(false);
		}
	};

	const { viewMode, setViewMode } = useViewMode();

	const isPWA = window.matchMedia('(display-mode: standalone)').matches;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (createDropdownRef.current && !createDropdownRef.current.contains(event.target as Node)) {
				setShowCreateDropdown(false);
			}
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setShowDropdown(false);
			}
			if (notificationsRef.current && !notificationsRef.current.contains(event.target as Node)) {
				setShowNotifications(false);
			}
		};

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			window.removeEventListener('resize', handleResize);
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const closeMobileSettings = () => {
		setShowMobileSettings(false);
	};

	const [imageLoaded, setImageLoaded] = useState(false);

	const logo = useMemo(() => <img src="/favicon.ico" alt="Kickoff Logo" width={32} height={32} className="mr-2 md:mr-4" />, []);

	const handleNotificationClick = (requestId: string) => {
		console.log('Friend request clicked:', requestId);
		setShowNotifications(false);
	};

	// Handle window resize
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const ChampionProfileIcon = () => (
		<div className="relative p-2">
			<FaUser size={18} className="text-gray-600" />
		</div>
	);

	return (
		<>
			<div
				className={`
				${className} 
				md:bg-white md:shadow-md 
				px-1 md:px-6 
				flex items-center justify-between 
				relative 
				bottom-nav
				pointer-events-auto
				${
					isMobile
						? `bg-white/90 border-t border-white/20 ${
								isPWA ? 'pt-4 pb-[calc(env(safe-area-inset-bottom)+1.75rem)]' : 'pb-[env(safe-area-inset-bottom)]'
						  }`
						: ''
				}
			`}
			>
				{/* Logo for desktop */}
				<Link to="/dashboard" className="flex items-center cursor-pointer hidden md:flex pointer-events-auto">
					{logo}
					<h1 className="text-xl font-bold text-gray-800 -ml-2 hidden sm:block">Kickoff</h1>
				</Link>

				{/* Search bar - visible based on screen size and showMobileSearch prop */}
				{/* <div className={`${showMobileSearch ? 'block md:hidden' : 'hidden'} w-full absolute left-0 top-full bg-transparent z-50`}>
					<div className="pt-6 pb-4 px-12 relative isolate">
						<SearchBar className="w-full" />
					</div>
				</div> */}
				<div className="hidden md:block flex-1 max-w-xl mx-4 relative z-30" style={{ touchAction: 'manipulation' }}>
					<SearchBar dropUp={true} />
				</div>

				{/* Desktop Navigation */}
				<div className="hidden md:flex items-center space-x-4">
					{isAuthenticated ? (
						<>
							{/* Existing desktop navigation items */}
							<Link
								to="/chat"
								className="text-gray-600 transition duration-300 pointer-events-auto"
								style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
							>
								<FaComments size={24} />
							</Link>

							{/* Create button */}
							<div className="relative" ref={createDropdownRef}>
								<Link
									to="/create-game"
									className="flex items-center text-rose-500 hover:text-rose-600 p-2 rounded-full transition duration-300 pointer-events-auto"
									style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
								>
									<FaPlusCircle size={24} />
								</Link>
							</div>

							{/* Friends Icon */}
							<div className="relative" ref={notificationsRef}>
								 <button
									className="relative flex items-center text-gray-600 hover:text-gray-700 p-2 rounded-full transition duration-300 pointer-events-auto"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										navigate('/friends');
									}}
									style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
								>
									<FaUserFriends size={24} />
								</button>
								{/*{showNotifications && (
									<div className="absolute md:top-[calc(100%+0.5rem)] bottom-[calc(100%+0.5rem)] md:bottom-auto right-0 w-[calc(100vw-2rem)] md:w-96 max-h-[calc(100vh-8rem)] bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-auto">
										<div className="sticky top-0 bg-white px-4 py-2 border-b border-gray-200 flex justify-between items-center">
											<h3 className="font-semibold text-gray-700">Notifications</h3>
											{(notificationsLoading || friendRequestsLoading) && <InlineLoading size="sm" />}
											<button onClick={() => setShowNotifications(false)} className="text-gray-500 hover:text-gray-700">
												<FaTimes size={16} />
											</button>
										</div>

										{/* Regular Notifications Section */}
										{/* <div>
											{notifications.length > 0 ? (
												notifications.map((notification) => (
													<NotificationItem
														key={notification.notification_id}
														notification={notification}
														onMarkAsRead={handleMarkAsRead}
														connectionStatus={connectionStatus}
													/>
												))
											) : (
												<div className="px-4 py-3 text-sm text-gray-600 text-center">
													{notificationsLoading ? <InlineLoading size="sm" /> : 'No notifications'}
												</div>
											)}
										// </div> 
									</div> 
								)} */}
							</div>

							{/* Profile Dropdown */}
							<div className="relative flex items-center" ref={dropdownRef}>
								<button
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										navigate(`/u/${user?.username}`);
									}}
									className="flex items-center justify-center text-gray-600 p-2 rounded-full transition duration-300 pointer-events-auto"
									style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
								>
									<FaUser size={18} />

									{/* {user?.profile_picture_url ? (
										<img
											src={user.profile_picture_url}
											alt="Profile"
											className="w-6 h-6 rounded-full object-cover shadow-md"
											onContextMenu={(e) => e.preventDefault()}
											draggable={false}
										/>
									) : (
										<ChampionProfileIcon />
									)} */}
								</button>

								{showDropdown && window.innerWidth >= 768 && (
									<div
										className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden"
										style={{ top: '100%' }}
									>
										<Link
											to={`/editprofile`}
											className="flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300"
										>
											<FaCog className="mr-3 text-indigo-600" size={18} />
											<span className="align-middle">Settings</span>
										</Link>

										<button
											onClick={() => {
												setShowDropdown(false);
												setShowSignOutConfirm(true);
											}}
											className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300"
										>
											<FaSignOutAlt className="mr-3 text-rose-600" size={18} />
											<span className="align-middle">Sign Out</span>
										</button>
									</div>
								)}
							</div>
						</>
					) : (
						<div className="flex items-center space-x-4">
							<span className="text-gray-600">Sign In to Continue</span>
							<Link
								to={`/signin?redirectAfterSignIn=${encodeURIComponent(location.pathname)}`}
								className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								Sign In
							</Link>
						</div>
					)}
				</div>

				{/* Bottom Navigation for Mobile */}
				<div className={`flex items-center justify-around w-full md:hidden ${isPWA ? 'mt-16 pb-4 pt-2 mb-8' : ''}`}>
					{isAuthenticated ? (
						<>
							<Link
								to="/dashboard"
								className="text-gray-600 p-2 hover:text-gray-700 transition duration-300"
								style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
							>
								<FaHome size={23} className="text-gray-600" />
							</Link>
							<Link
								to="/chat"
								className="text-gray-600 p-2 hover:text-gray-700 transition duration-300"
								style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
							>
								<FaComments size={22} />
							</Link>
							<div className="relative" ref={createDropdownRef}>
								<Link
									to="/create-game"
									className="flex items-center text-rose-500 p-2 rounded-full transition duration-300 hover:text-rose-600"
									style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
								>
									<FaPlusCircle size={28} />
								</Link>
							</div>
							<div className="relative" ref={notificationsRef}>
								<button
									className="relative flex items-center text-gray-600 p-2 rounded-full transition duration-300 hover:text-gray-700"
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										if (window.innerWidth < 768) {
											navigate('/friends');
										} else {
											setShowNotifications(!showNotifications);
										}
									}}
									style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
								>
									<FaUsers size={24} />
								</button>
								{/* {showNotifications && window.innerWidth < 768 && (
									<div className="absolute md:top-[calc(100%+0.5rem)] bottom-[calc(100%+0.5rem)] md:bottom-auto right-0 w-[calc(100vw-2rem)] md:w-96 max-h-[calc(100vh-8rem)] bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-auto">
										<div className="sticky top-0 bg-white px-4 py-2 border-b border-gray-200 flex justify-between items-center">
											<h3 className="font-semibold text-gray-700">Notifications</h3>
											{(notificationsLoading || friendRequestsLoading) && <InlineLoading size="sm" />}
											<button onClick={() => setShowNotifications(false)} className="text-gray-500 hover:text-gray-700">
												<FaTimes size={16} />
											</button>
										</div>

										<div>
											{notifications.length > 0 ? (
												notifications.map((notification) => (
													<NotificationItem
														key={notification.notification_id}
														notification={notification}
														onMarkAsRead={handleMarkAsRead}
														connectionStatus={connectionStatus}
													/>
												))
											) : (
												<div className="px-4 py-3 text-sm text-gray-600 text-center">
													{notificationsLoading ? <InlineLoading size="sm" /> : 'No notifications'}
												</div>
											)}
										</div>
									</div> 
								)} */}
							</div>
							<button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									if (window.innerWidth < 768) {
										navigate(`/u/${user?.username}`);
									}
								}}
								className="text-gray-600 hover:text-gray-700 transition duration-300"
								style={{ touchAction: 'manipulation', WebkitTapHighlightColor: 'transparent' }}
							>
								<ChampionProfileIcon />
							</button>
						</>
					) : (
						<div className="flex items-center justify-between w-full px-4 py-2">
							<span className="text-gray-600 text-sm">Sign In to Continue</span>
							<Link
								to={`/signin?redirectAfterSignIn=${encodeURIComponent(location.pathname)}`}
								className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								Sign In
							</Link>
						</div>
					)}
				</div>

				{/* Mobile Settings Modal */}
				{showMobileSettings && (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex items-end md:hidden z-50">
						<div className="absolute inset-0" onClick={closeMobileSettings}></div>
						<div ref={mobileSettingsRef} className="bg-white rounded-t-xl w-full p-4 animate-slide-up relative">
							<div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mb-4" />
							<div className="space-y-4">
								<button
									onClick={() => {
										navigate('/profile');
										closeMobileSettings();
									}}
									className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded-lg"
								>
									<FaUserCircle className="mr-3 text-indigo-600" size={20} />
									<span className="align-middle">Profile</span>
								</button>
								<button
									onClick={() => {
										navigate('/editprofile');
										closeMobileSettings();
									}}
									className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-indigo-50 transition duration-300 rounded-lg"
								>
									<FaUserEdit className="mr-3 text-indigo-600" size={20} />
									<span className="align-middle">Edit Profile</span>
								</button>
								<button
									onClick={() => {
										setShowSignOutConfirm(true);
										closeMobileSettings();
									}}
									className="w-full flex items-center px-4 py-3 text-gray-700 hover:bg-rose-50 transition duration-300 rounded-lg"
								>
									<FaSignOutAlt className="mr-3 text-rose-600" size={20} />
									<span className="align-middle">Sign Out</span>
								</button>
							</div>
						</div>
					</div>
				)}

				{/* Sign Out Confirmation Modal */}
				{showSignOutConfirm && (
					<>
						<div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={() => setShowSignOutConfirm(false)} />
						<div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-72 bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50">
							<div className="text-gray-800 font-medium mb-2">Sign out?</div>
							<div className="text-gray-600 text-sm mb-4">You will need to sign in again to access your account.</div>
							<div className="flex gap-2">
								<button
									onClick={handleSignOut}
									className="flex-1 bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600 transition-colors flex items-center justify-center gap-1"
								>
									<FaSignOutAlt className="text-sm" />
									Sign Out
								</button>
								<button
									onClick={() => setShowSignOutConfirm(false)}
									className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200 transition-colors"
								>
									Cancel
								</button>
							</div>
						</div>
					</>
				)}
			</div>

			{/* Mobile Search Bar - Non Sticky */}
			{showMobileSearch && window.innerWidth < 768 && <SearchBar className="top-0 z-40 bg-transparent px-4 pt-4 md:hidden" />}
		</>
	);
};

// Add CSS for bottom navigation
const styles = `
	.bottom-nav {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 50;
		box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.05);
		height: 64px;
		background-color: white;
	}
	
	/* Add padding to main content area in mobile */
	@media (max-width: 767px) {
		main, .main-content {
			padding-bottom: calc(64px + env(safe-area-inset-bottom, 0px));
		}

		/* Ensure dropdowns appear above the bottom nav */
		.bottom-nav > div > div {
			z-index: 50;
		}

		/* Support for older Safari versions */
		@supports not (backdrop-filter: blur(12px)) {
			.bottom-nav {
				background-color: rgba(255, 255, 255, 1);
			}
		}
	}
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default React.memo(TopNavBar);
