import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({ onClose, children, className }) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 overflow-y-auto select-none">
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="fixed inset-0 bg-black opacity-50"
          onClick={onClose}
        ></div>
        <div className={`bg-white rounded-lg overflow-hidden shadow-xl transform transition-all ${className} z-10`}>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;