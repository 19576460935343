import { useState, useEffect, useCallback, useMemo } from 'react';
import { Notification, NotificationAPI, NotificationResponse } from '../models/notification';
import { NotificationWebsocketAPI } from '../models/notification_websocket';

interface UseNotificationsReturn {
  notifications: Notification[];
  unreadCount: number;
  loading: boolean;
  error: Error | null;
  hasMore: boolean;
  loadMore: () => Promise<void>;
  markAsRead: (notificationId: string) => Promise<void>;
  deleteNotification: (notificationId: string) => Promise<void>;
  refresh: () => Promise<void>;
}

interface UseNotificationsOptions {
  accountId: string;
  limit?: number;
  isAuthenticated?: boolean;
}

// Cache for notifications
const notificationsCache = new Map<string, { data: NotificationResponse; timestamp: number }>();
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

export function useNotifications({ accountId, limit = 20, isAuthenticated = false }: UseNotificationsOptions): UseNotificationsReturn {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [hasMore, setHasMore] = useState(false);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // Calculate unread count from notifications
  const unreadCount = useMemo(() => 
    notifications.filter(notification => !notification.read).length,
    [notifications]
  );

  // Memoize the cache key to prevent unnecessary cache lookups
  const cacheKey = useMemo(() => `${accountId}:${limit}`, [accountId, limit]);

  // Function to fetch notifications with caching
  const fetchNotifications = useCallback(async (pageState?: string) => {
    if (!isAuthenticated || !accountId) {
      return { notifications: [], next_page: null, has_more: false };
    }

    // Check cache for initial page load
    if (!pageState) {
      const cached = notificationsCache.get(cacheKey);
      if (cached && Date.now() - cached.timestamp < CACHE_TTL) {
        return cached.data;
      }
    }

    try {
      const response = await NotificationAPI.fetchUserNotifications({
        page_state: pageState,
        limit
      });

      // Cache only the initial page
      if (!pageState) {
        notificationsCache.set(cacheKey, {
          data: response,
          timestamp: Date.now()
        });
      }

      return response;
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  }, [isAuthenticated, accountId, limit, cacheKey]);

  // Initial load
  useEffect(() => {
    if (!isAuthenticated || !accountId || initialLoadComplete) {
      return;
    }

    const loadInitial = async () => {
      setLoading(true);
      try {
        const response = await fetchNotifications();
        setNotifications(response.notifications);
        setNextPage(response.next_page);
        setHasMore(response.has_more);
      } catch (error) {
        console.error('Failed to load initial notifications:', error);
      } finally {
        setLoading(false);
        setInitialLoadComplete(true);
      }
    };

    loadInitial();
  }, [fetchNotifications, isAuthenticated, accountId, initialLoadComplete]);

  // Subscribe to real-time updates
  useEffect(() => {
    if (!isAuthenticated || !accountId) {
      return;
    }

    const handleNewNotification = (notification: Notification) => {
      setNotifications(prev => {
        // Check if notification already exists
        if (prev.some(n => n.notification_id === notification.notification_id)) {
          return prev;
        }
        return [notification, ...prev];
      });
    };

    const unsubscribe = NotificationWebsocketAPI.subscribe(handleNewNotification);
    return () => unsubscribe();
  }, [isAuthenticated, accountId]);

  // Load more notifications
  const loadMore = async () => {
    if (!hasMore || !nextPage || loading) return;

    setLoading(true);
    try {
      const response = await fetchNotifications(nextPage);
      setNotifications(prev => [...prev, ...response.notifications]);
      setNextPage(response.next_page);
      setHasMore(response.has_more);
    } catch (error) {
      console.error('Failed to load more notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  // Mark notification as read
  const markAsRead = async (notificationId: string) => {
    try {
      await NotificationAPI.markAsRead(notificationId);
      setNotifications(prev =>
        prev.map(notification =>
          notification.notification_id === notificationId
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
      throw error;
    }
  };

  // Delete notification
  const deleteNotification = async (notificationId: string) => {
    try {
      await NotificationAPI.deleteNotification(notificationId);
      setNotifications(prev =>
        prev.filter(notification => notification.notification_id !== notificationId)
      );
    } catch (error) {
      console.error('Failed to delete notification:', error);
      throw error;
    }
  };

  // Refresh notifications
  const refresh = async () => {
    if (!isAuthenticated || !accountId) {
      return;
    }

    try {
      // Clear cache before refreshing
      notificationsCache.delete(cacheKey);
      
      const response = await fetchNotifications();
      setNotifications(response.notifications);
      setNextPage(response.next_page);
      setHasMore(response.has_more);
    } catch (error) {
      console.error('Failed to refresh notifications:', error);
      throw error;
    }
  };

  return {
    notifications,
    unreadCount,
    loading,
    error,
    hasMore,
    loadMore,
    markAsRead,
    deleteNotification,
    refresh
  };
}