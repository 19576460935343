import React, { useCallback, useEffect, useState } from 'react';
import { FaPaperPlane, FaPlus, FaTimes, FaChevronDown, FaSpinner, FaImage, FaSync } from 'react-icons/fa';
import { User } from '../../models/user';
import LoadingSpinner from '../LoadingSpinner';

interface Attachment {
  type: 'link' | 'image' | 'document';
  url: string;
  title?: string;
  mimeType?: string;
  size?: number;
  previewUrl?: string;
}

interface ChatMessage {
  id: string;
  content: {
    text?: string;
    attachments?: Attachment[];
  };
  timestamp: number;
  sender: {
    id: string;
    name: string;
  };
  status?: {
    sent: number;
    delivered: { [userId: string]: number };
    read: { [userId: string]: number };
    reactions?: { [userId: string]: string };
  };
}

interface MobileChatInterfaceProps {
  messages: ChatMessage[];
  inputMessage: string;
  setInputMessage: (message: string) => void;
  sendMessage: (message: string) => void;
  imagePreview: { file: File; preview: string } | null;
  setImagePreview: (preview: { file: File; preview: string } | null) => void;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileUploadToServer: (file: File) => Promise<void>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  inputRef: React.RefObject<HTMLInputElement>;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  messagesContainerRef: React.RefObject<HTMLDivElement>;
  showScrollToBottom: boolean;
  setShowScrollToBottom: (show: boolean) => void;
  scrollToBottom: () => void;
  selectedMessageId: string | null;
  setSelectedMessageId: (id: string | null) => void;
  emojiPickerPosition: { visible: boolean } | null;
  setEmojiPickerPosition: (position: { visible: boolean } | null) => void;
  handleEmojiSelect: (emoji: string) => void;
  currentUser: {
    id: string;
    name: string;
  };
  loadingImages?: Set<string>;
  imageAspectRatios?: Map<string, number>;
  uploadingFiles?: Set<string>;
  isAuthenticated?: boolean;
  otherProfiles?: { [key: string]: User };
  handleReaction: (messageId: string, emoji: string, action: 'add' | 'remove') => void;
  pendingReactions: {
    [messageId: string]: { [emoji: string]: boolean };
  };
  connectionError: boolean;
  error: string | null;
  handleRefresh: () => void;
}

const MobileChatInterface: React.FC<MobileChatInterfaceProps> = ({
  messages,
  inputMessage,
  setInputMessage,
  sendMessage,
  imagePreview,
  setImagePreview,
  handleFileUpload,
  handleFileUploadToServer,
  fileInputRef,
  inputRef,
  messagesEndRef,
  messagesContainerRef,
  showScrollToBottom,
  setShowScrollToBottom,
  scrollToBottom,
  selectedMessageId,
  setSelectedMessageId,
  emojiPickerPosition,
  setEmojiPickerPosition,
  handleEmojiSelect,
  currentUser,
  loadingImages = new Set(),
  imageAspectRatios = new Map(),
  uploadingFiles = new Set(),
  isAuthenticated = true,
  otherProfiles = {},
  handleReaction,
  pendingReactions,
  connectionError,
  error,
  handleRefresh,
}) => {
  const [visibleDate, setVisibleDate] = useState<number | null>(null);
  const [reconnectTimer, setReconnectTimer] = useState<NodeJS.Timeout | null>(null);

  const formatTimestamp = (timestamp: number) => {
    return new Date(timestamp).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: date.getFullYear() !== today.getFullYear() ? 'numeric' : undefined,
      });
    }
  };

  const isSameDay = (timestamp1: number, timestamp2: number) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.toDateString() === date2.toDateString();
  };

  const updateVisibleDate = useCallback(() => {
    const container = messagesContainerRef.current;
    if (!container || messages.length === 0) return;

    // Get all date separator elements
    const dateSeparators = container.querySelectorAll('[data-date-separator]');
    if (dateSeparators.length === 0) {
      setVisibleDate(messages[0].timestamp);
      return;
    }

    // Find the date separator that's closest to being just above the viewport top
    let lastVisibleDate = messages[0].timestamp;
    const containerRect = container.getBoundingClientRect();
    const topPadding = 48; // pt-12 = 48px (3rem)
    const stickyHeaderHeight = 48; // Height of the sticky header
    const containerTop = containerRect.top + topPadding + stickyHeaderHeight;

    // Find the first separator that's below the sticky header
    for (let i = dateSeparators.length - 1; i >= 0; i--) {
      const separator = dateSeparators[i];
      const rect = separator.getBoundingClientRect();
      
      if (rect.top <= containerTop) {
        lastVisibleDate = parseInt(separator.getAttribute('data-timestamp') || '0', 10);
        break;
      }
    }

    setVisibleDate(lastVisibleDate);
  }, [messages]);

  const updateScrollPosition = useCallback(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    // Update visible date
    updateVisibleDate();

    // Check if we're not at the bottom
    const isAtBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 50;
    setShowScrollToBottom(!isAtBottom);
  }, [updateVisibleDate]);

  // Add scroll event listener
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', updateScrollPosition);
    // Initial update
    updateScrollPosition();

    return () => {
      container.removeEventListener('scroll', updateScrollPosition);
    };
  }, [updateScrollPosition]);

  const handleSendMessage = () => {
    if (!isAuthenticated) return;
    
    if (imagePreview) {
      handleFileUploadToServer(imagePreview.file);
    } else if (inputMessage.trim()) {
      sendMessage(inputMessage);
    }
  };

  const getUserDisplayInfo = (userId: string) => {
    if (userId === currentUser.id) {
      return {
        name: currentUser.name,
        avatar: null
      };
    }
    const profile = otherProfiles[userId];
    return {
      name: profile?.username || 'Unknown User',
      avatar: profile?.profile_picture_url || null
    };
  };

  const handleMessageInteraction = (e: React.TouchEvent | React.MouseEvent, messageId: string) => {
    // Don't allow reactions on own messages
    const message = messages.find((m) => m.id === messageId);
    if (message?.sender.id === currentUser.id) {
      return;
    }

    let longPressTimer: NodeJS.Timeout;
    
    if (e.type === 'touchstart') {
      // Prevent default actions for all touch events
      e.preventDefault();
      e.stopPropagation();

      const touch = (e as React.TouchEvent).touches[0];
      if (touch && (touch as any).force > 0.8) {
        // Force touch detected
        showEmojiPicker(messageId);
      } else {
        // Start long press timer
        longPressTimer = setTimeout(() => {
          showEmojiPicker(messageId);
        }, 500); // 500ms for long press

        // Add touch end listener to clear timer
        const clearTimer = () => {
          clearTimeout(longPressTimer);
          document.removeEventListener('touchend', clearTimer);
          document.removeEventListener('touchmove', clearTimer);
        };

        document.addEventListener('touchend', clearTimer, { once: true });
        document.addEventListener('touchmove', clearTimer, { once: true });
      }
    }
  };

  const showEmojiPicker = (messageId: string) => {
    setSelectedMessageId(messageId);
    setEmojiPickerPosition({ visible: true });
  };

  useEffect(() => {
    if (connectionError) {
      // Clear any existing timer
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
      // Set new reconnect timer
      const timer = setTimeout(() => {
        handleRefresh();
      }, 5000); // Try to reconnect every 5 seconds
      setReconnectTimer(timer);
    } else if (reconnectTimer) {
      clearTimeout(reconnectTimer);
      setReconnectTimer(null);
    }

    return () => {
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
    };
  }, [connectionError, handleRefresh]);

  return (
    <div className="relative flex flex-col h-full bg-white font-['-apple-system',BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif] font-normal">
      {connectionError && (
        <div className="fixed top-safe right-0 z-[60] p-4 flex justify-end" style={{ top: 'max(env(safe-area-inset-top), 16px)' }}>
          <div className="bg-white/80 backdrop-blur-sm rounded-full p-2 shadow-sm">
            <FaSync className="w-4 h-4 text-red-500 animate-spin" />
          </div>
        </div>
      )}
      {/* Emoji Picker Modal */}
      {selectedMessageId && emojiPickerPosition?.visible && (
        <div 
          className="fixed inset-0 z-[70] flex items-center justify-center bg-black/30 backdrop-blur-sm"
          onMouseDown={(e) => {
            if (e.target === e.currentTarget) {
              setSelectedMessageId(null);
              setEmojiPickerPosition(null);
            }
          }}
        >
          <div 
            className="bg-white/95 backdrop-blur-xl rounded-2xl shadow-2xl w-[360px] flex flex-col mx-4"
            onMouseDown={(e) => e.stopPropagation()}
          >
            {/* Message Preview */}
            <div className="p-6 flex-1 overflow-y-auto max-h-[200px]">
              {(() => {
                const message = messages.find(m => m.id === selectedMessageId);
                if (!message) return null;
                return (
                  <div
                    className={`relative break-words rounded-lg px-4 py-2 message-content cursor-pointer select-none font-normal ${
                      message.sender.id === currentUser.id ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900'
                    } font-['-apple-system',BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif]`}
                  >
                    <div className="whitespace-pre-wrap">{message.content?.text}</div>
                    {message.content?.attachments?.map((attachment, index) => (
                      <div key={index} className="mt-2">
                        {attachment.type === 'image' && (
                          <div className="relative">
                            <img
                              src={attachment.url}
                              alt={attachment.title || 'Attachment'}
                              className="w-full rounded-lg"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })()}
            </div>

            {/* Fixed Emoji Grid */}
            <div className="border-t border-gray-100">
              <div className="grid grid-cols-6 gap-3 p-6">
                {['👍', '❤️', '😂', '😮', '😢', '😡'].map((emoji) => {
                  const message = messages.find(m => m.id === selectedMessageId);
                  const hasReacted = message?.status?.reactions?.[currentUser.id] === emoji;
                  const isPending = pendingReactions[selectedMessageId]?.[emoji];
                  const isActive = isPending !== undefined ? isPending : hasReacted;

                  return (
                    <button
                      key={emoji}
                      type="button"
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (isActive) {
                          handleReaction(selectedMessageId, emoji, 'remove');
                        } else {
                          handleReaction(selectedMessageId, emoji, 'add');
                        }
                        setSelectedMessageId(null);
                        setEmojiPickerPosition(null);
                      }}
                      className={`w-11 h-11 flex items-center justify-center text-2xl rounded-xl
                        transition-all duration-200 hover:scale-110 transform
                        ${isActive
                          ? 'bg-gradient-to-br from-blue-500/90 to-blue-600/90 text-white shadow-lg'
                          : 'hover:bg-gradient-to-br hover:from-gray-50 hover:to-gray-100/80'
                        }`}
                    >
                      <span>{emoji}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        ref={messagesContainerRef}
        className="flex-1 overflow-y-auto pt-12 px-4 pb-[60px] space-y-4 min-h-0"
      >
        {/* Sticky date header */}
        {messages.length > 0 && visibleDate && (
          <div className="sticky top-12 z-10 bg-transparent py-1 text-center">
            <span className="text-xs font-medium text-gray-500 px-3 py-0.5 bg-white/80 backdrop-blur-sm rounded-full shadow-sm">
              {formatDate(visibleDate)}
            </span>
          </div>
        )}

        {messages.map((message, index, array) => {
          const isCurrentUser = message.sender.id === currentUser.id;
          const { name, avatar } = getUserDisplayInfo(message.sender.id);
          
          return (
            <React.Fragment key={message.id}>
              {/* Date separator */}
              {index > 0 && !isSameDay(message.timestamp, array[index - 1].timestamp) && (
                <div className="flex items-center my-4" data-date-separator data-timestamp={message.timestamp}>
                  <div className="flex-grow border-t border-gray-200"></div>
                  <div className="mx-3 text-xs text-gray-500 font-medium">{formatDate(message.timestamp)}</div>
                  <div className="flex-grow border-t border-gray-200"></div>
                </div>
              )}

              {/* Message */}
              <div
                className={`flex ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'} items-center gap-2 relative message-container my-6`}
                onTouchStart={(e) => handleMessageInteraction(e, message.id)}
                data-can-react={message.sender.id !== currentUser.id}
              >
                {/* User Info */}
                {!isCurrentUser && (
                  <div className="flex-shrink-0">
                    <div className="w-10 h-10">
                      {avatar ? (
                        <img
                          src={avatar}
                          alt={name}
                          className="w-full h-full rounded-full object-cover select-none"
                          onContextMenu={(e) => e.preventDefault()}
                          draggable={false}
                        />
                      ) : (
                        <div className="w-full h-full rounded-full bg-gray-300 flex items-center justify-center select-none">
                          <span className="text-sm font-medium text-gray-600">
                            {name.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Message content */}
                <div 
                  className={`flex flex-col ${isCurrentUser ? 'items-end' : 'items-start'} max-w-[70%]`}
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <div
                    className={`relative break-words rounded-lg px-4 py-2 message-content cursor-pointer select-none font-normal ${
                      isCurrentUser ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-900'
                    }`}
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    {message.content?.text && (
                      <div 
                        className="whitespace-pre-wrap select-none"
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        {message.content.text}
                      </div>
                    )}
                    {message.content?.attachments?.map((attachment, index) => (
                      <div 
                        key={index} 
                        className="mt-2 select-none"
                        onContextMenu={(e) => e.preventDefault()}
                        onTouchStart={(e) => e.preventDefault()}
                        onTouchEnd={(e) => e.preventDefault()}
                      >
                        {attachment.type === 'image' ? (
                          <div 
                            className="relative select-none"
                            onContextMenu={(e) => e.preventDefault()}
                            onTouchStart={(e) => e.preventDefault()}
                            onTouchEnd={(e) => e.preventDefault()}
                          >
                            <div 
                              className={`w-full bg-gray-100 rounded-lg flex items-center justify-center select-none ${
                                !loadingImages.has(attachment.url) ? 'hidden' : ''
                              }`}
                              style={{
                                paddingBottom: `${imageAspectRatios.has(attachment.url) 
                                  ? `${(1 / imageAspectRatios.get(attachment.url)!) * 100}%` 
                                  : '75%'}`
                              }}
                              onContextMenu={(e) => e.preventDefault()}
                            >
                              <div className="absolute inset-0 flex items-center justify-center">
                                <div className="w-8 h-8 text-blue-500">
                                  <LoadingSpinner />
                                </div>
                              </div>
                            </div>
                            <img
                              src={attachment.url}
                              alt={attachment.title || 'Attachment'}
                              className={`w-full rounded-lg select-none ${loadingImages.has(attachment.url) ? 'hidden' : ''}`}
                              onContextMenu={(e) => e.preventDefault()}
                              onTouchStart={(e) => e.preventDefault()}
                              onTouchEnd={(e) => e.preventDefault()}
                              draggable={false}
                            />
                          </div>
                        ) : attachment.type === 'document' ? (
                          <div className="flex items-center space-x-2 p-2 bg-gray-50 rounded">
                            <div className="flex-1 truncate">
                              <div className="text-sm font-medium">{attachment.title}</div>
                              <div className="text-xs text-gray-500">{(attachment.size / 1024 / 1024).toFixed(1)} MB</div>
                            </div>
                            <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-600">
                              Download
                            </a>
                          </div>
                        ) : null}
                      </div>
                    ))}
                    {message.status?.reactions && (() => {
                      // Get all active reactions (excluding pending removals)
                      const activeReactions = Object.entries(message.status.reactions).filter(([userId, emoji]) => {
                        if (!emoji) return false;
                        if (userId === currentUser.id && pendingReactions[message.id]?.[emoji] === false) return false;
                        return true;
                      });

                      // If no active reactions, don't render anything
                      if (activeReactions.length === 0) return null;

                      // Render the first active reaction
                      const [userId, emoji] = activeReactions[0];
                      return (
                        <div
                          key={`${message.id}-${userId}`}
                          className={`inline-flex items-center justify-center
                            ${isCurrentUser
                              ? 'bg-gradient-to-br from-gray-800/0 to-gray-900/0 text-white'
                              : 'bg-gradient-to-br from-gray-500/0 to-gray-600/0 text-white'
                            } 
                            transition-all duration-200 absolute shadow-lg hover:shadow-xl
                            ${isCurrentUser
                              ? 'top-0 left-0 -translate-x-1/2 -translate-y-1/2'
                              : 'top-0 right-0 translate-x-1/2 -translate-y-1/2'
                            }
                            w-8 h-8 rounded-full backdrop-blur-sm border border-white/10
                            hover:scale-110 transform cursor-pointer`}
                          style={{
                            zIndex: 1,
                            fontSize: '1.6rem',
                            backdropFilter: 'blur(8px)',
                            WebkitBackdropFilter: 'blur(8px)',
                          }}
                          onTouchStart={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (userId === currentUser.id) {
                              handleReaction(message.id, emoji, 'remove');
                            }
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (userId === currentUser.id) {
                              handleReaction(message.id, emoji, 'remove');
                            }
                          }}
                        >
                          <span className="transform hover:scale-110 transition-transform duration-200">{emoji}</span>
                        </div>
                      );
                    })()}
                  </div>

                  {/* Timestamp and Status */}
                  <div className="text-xs text-gray-500 mt-1 px-1 flex items-center space-x-1">
                    <span>{formatTimestamp(message.timestamp)}</span>
                    {isCurrentUser && message.status && (
                      <span className="ml-1 relative">
                        {Object.entries(message.status.read || {}).some(([userId]) => userId !== currentUser.id) ? (
                          <span className="relative">
                            <span className="absolute text-blue-400" style={{ left: '-0.3em' }}>✓</span>
                            <span className="text-blue-400">✓</span>
                          </span>
                        ) : Object.entries(message.status.delivered || {}).some(([userId]) => userId !== currentUser.id) ? (
                          <span className="relative">
                            <span className="absolute text-gray-400" style={{ left: '-0.3em' }}>✓</span>
                            <span className="text-gray-400">✓</span>
                          </span>
                        ) : (
                          <span className="text-gray-400">✓</span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      {showScrollToBottom && (
        <div className="fixed right-4 bottom-[calc(60px+env(safe-area-inset-bottom,0px)+8px)] z-[51]">
          <button
            onClick={() => scrollToBottom()}
            className="bg-black shadow-lg rounded-full p-2"
          >
            <FaChevronDown className="text-white hover:text-gray-200" />
          </button>
        </div>
      )}

      <div className="fixed inset-x-0 bottom-0 bg-white translate-y-0 z-[50]" style={{ paddingBottom: 'env(safe-area-inset-bottom, 0px)' }}>
        <div 
          className={`transition-all duration-200 absolute bottom-full left-0 right-0 ${imagePreview ? 'opacity-100 translate-y-0' : 'opacity-0 pointer-events-none translate-y-4 h-0 overflow-hidden'} bg-white p-4`}
        >
          <div className="relative inline-block">
            <img 
              src={imagePreview?.preview} 
              alt="Preview" 
              className="max-h-[120px] rounded-lg object-contain bg-gray-50"
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setImagePreview(null);
                if (fileInputRef.current) {
                  fileInputRef.current.value = '';
                }
              }}
              className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 shadow-sm"
              disabled={uploadingFiles.size > 0}
            >
              <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        <div className="h-[60px] px-2 pb-1 flex items-center">
          <div className="flex items-center bg-gray-100 rounded-2xl px-2 h-[44px] w-full">
            <button
              onClick={() => {
                if (!uploadingFiles.size && isAuthenticated) {
                  fileInputRef.current?.click();
                }
              }}
              className="p-1.5 text-gray-500 hover:text-gray-700 focus:outline-none relative flex-shrink-0 h-[36px] w-[36px] flex items-center justify-center"
              disabled={!isAuthenticated}
            >
              {uploadingFiles.size > 0 ? (
                <FaSpinner className="w-5 h-5 animate-spin" />
              ) : (
                <FaImage className="w-5 h-5" />
              )}
            </button>
            <input
              ref={inputRef}
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Message"
              className="flex-1 px-2 bg-transparent border-none focus:outline-none font-normal placeholder-gray-500 h-[36px]"
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              disabled={uploadingFiles.size > 0 || !isAuthenticated}
            />
            <button
              onClick={handleSendMessage}
              className={`p-1.5 rounded-full focus:outline-none flex-shrink-0 h-[36px] w-[36px] flex items-center justify-center ${
                (!inputMessage.trim() && !imagePreview) || uploadingFiles.size > 0 || !isAuthenticated
                  ? 'text-gray-400'
                  : 'text-blue-500 hover:text-blue-600'
              }`}
              disabled={(!inputMessage.trim() && !imagePreview) || uploadingFiles.size > 0 || !isAuthenticated}
            >
              <FaPaperPlane className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        className="hidden"
        disabled={uploadingFiles.size > 0 || !isAuthenticated}
      />
    </div>
  );
};

export default MobileChatInterface;
