import { useState, useEffect, useCallback } from 'react';
import { Game } from '../models/games';
import { retryWithBackoff } from '../utils/retry';

// Get current year
const getCurrentYear = (): number => {
    return new Date().getFullYear();
};

export interface UseOtherUserGamesOptions {
    limit?: number;
    initialYear?: number;
}

export const useOtherUserGames = (userId: string | undefined, options?: UseOtherUserGamesOptions) => {
    const [games, setGames] = useState<Game[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const [year, setYear] = useState<number | undefined>(options?.initialYear);

    // Clear games when user ID changes
    useEffect(() => {
        setGames([]);
        setNextPage(null);
        setHasMore(false);
        setLoading(true);
    }, [userId]);

    const fetchGames = useCallback(async (pageState?: string) => {
        if (!userId) {
            setLoading(false);
            return;
        }

        try {
            const result = await retryWithBackoff(async () => {
                return await Game.getByUserId(userId, {
                    limit: options?.limit || 10,
                    year,
                    pageState,
                });
            });

            if (!result.games || result.games.length === 0) {
                if (!pageState) {
                    setGames([]);
                }
                setHasMore(false);
                setNextPage(null);
                return;
            }

            // Update the games list
            setGames(prev => pageState ? [...prev, ...result.games] : result.games);
            setHasMore(result.hasMore);
            setNextPage(result.nextPage);
            setError(null);
        } catch (err) {
            console.error('Error in fetchGames:', err);
            setError(err as Error);
        } finally {
            setLoading(false);
        }
    }, [userId, options?.limit, year]);

    // Initial load
    useEffect(() => {
        setLoading(true);
        fetchGames();
    }, [userId, refreshTrigger, year]);

    const refresh = useCallback(() => {
        setRefreshTrigger(prev => prev + 1);
    }, []);

    const loadMore = useCallback(() => {
        if (!hasMore || loading || !nextPage) return;
        setLoading(true);
        fetchGames(nextPage);
    }, [hasMore, loading, nextPage, fetchGames]);

    return {
        games,
        loading,
        error,
        hasMore,
        loadMore,
        refresh,
        year,
        setYear,
        getCurrentYear
    };
};
