import React, { useState, useEffect } from 'react';
import { FaClock } from 'react-icons/fa';

interface TimePickerProps {
  selectedTime: Date | null;
  onChange: (date: Date) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ selectedTime, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState<'AM' | 'PM'>(selectedTime ? (selectedTime.getHours() >= 12 ? 'PM' : 'AM') : 'AM');
  const [tempSelectedTime, setTempSelectedTime] = useState<Date>(selectedTime || new Date());

  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 60 }, (_, i) => i);

  const formatTime = (date: Date | null) => {
    if (!date) return 'Select time';
    return date.toLocaleTimeString('en-US', { 
      hour: 'numeric',
      minute: '2-digit',
      hour12: true 
    });
  };

  const handleHourClick = (hour: number) => {
    const newDate = new Date(tempSelectedTime);
    const adjustedHour = period === 'PM' ? (hour === 12 ? 12 : hour + 12) : (hour === 12 ? 0 : hour);
    newDate.setHours(adjustedHour);
    setTempSelectedTime(newDate);
  };

  const handleMinuteClick = (minute: number) => {
    const newDate = new Date(tempSelectedTime);
    newDate.setMinutes(minute);
    setTempSelectedTime(newDate);
  };

  const handlePeriodChange = (newPeriod: 'AM' | 'PM') => {
    setPeriod(newPeriod);
    const newDate = new Date(tempSelectedTime);
    const currentHour = newDate.getHours();
    const currentIs12Hour = currentHour === 0 || currentHour === 12;
    
    if (newPeriod === 'PM' && currentHour < 12) {
      newDate.setHours(currentHour + 12);
    } else if (newPeriod === 'AM' && currentHour >= 12) {
      newDate.setHours(currentHour - 12);
    }
    
    setTempSelectedTime(newDate);
  };

  const getCurrentSelection = () => {
    if (!tempSelectedTime) return { hour: 12, minute: 0 };
    let hour = tempSelectedTime.getHours();
    if (hour === 0) hour = 12;
    else if (hour > 12) hour -= 12;
    return {
      hour,
      minute: tempSelectedTime.getMinutes()
    };
  };

  const handleSave = () => {
    onChange(tempSelectedTime);
    setIsOpen(false);
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setIsOpen(false);
    }
  };

  const { hour: selectedHour, minute: selectedMinute } = getCurrentSelection();

  return (
    <div>
      {/* Time Input Display */}
      <div 
        className="flex items-center px-4 py-3 border-b border-gray-200 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <FaClock className="w-5 h-5 text-gray-400" />
        <span className="ml-3 text-base text-gray-800">{formatTime(selectedTime)}</span>
      </div>

      {/* Modal Overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-60 z-50 flex items-center justify-center p-4 transition-opacity duration-200 ease-in-out backdrop-blur-sm"
          onClick={handleBackdropClick}
        >
          {/* Modal Content */}
          <div 
            className="bg-white rounded-3xl shadow-2xl w-full max-w-sm transform transition-all duration-200 ease-in-out"
            onClick={e => e.stopPropagation()}
          >
            {/* Time Display */}
            <div className="py-10 px-6 text-center">
              <div className="text-6xl font-semibold text-gray-900 tracking-tight">
                {selectedHour.toString().padStart(2, '0')}
                <span className="mx-2 text-gray-400">:</span>
                {selectedMinute.toString().padStart(2, '0')}
                <span className="text-3xl ml-3 text-gray-400 font-medium">{period}</span>
              </div>
            </div>

            {/* Time Picker Grid */}
            <div className="flex divide-x divide-gray-100 mx-2 rounded-2xl bg-gray-50">
              {/* Hours */}
              <div className="flex-1 h-[260px] overflow-y-auto scrollbar-hide">
                <div className="py-2">
                  {hours.map((h) => (
                    <div
                      key={h}
                      className={`mx-1 px-4 py-3 cursor-pointer text-center rounded-xl transition-all duration-200 ${
                        h === selectedHour
                          ? 'text-indigo-600 font-semibold bg-white shadow-sm'
                          : 'text-gray-600 hover:bg-white hover:text-gray-900'
                      }`}
                      onClick={() => handleHourClick(h)}
                    >
                      {h.toString().padStart(2, '0')}
                    </div>
                  ))}
                </div>
              </div>

              {/* Minutes */}
              <div className="flex-1 h-[260px] overflow-y-auto scrollbar-hide">
                <div className="py-2">
                  {minutes.map((m) => (
                    <div
                      key={m}
                      className={`mx-1 px-4 py-3 cursor-pointer text-center rounded-xl transition-all duration-200 ${
                        m === selectedMinute
                          ? 'text-indigo-600 font-semibold bg-white shadow-sm'
                          : 'text-gray-600 hover:bg-white hover:text-gray-900'
                      }`}
                      onClick={() => handleMinuteClick(m)}
                    >
                      {m.toString().padStart(2, '0')}
                    </div>
                  ))}
                </div>
              </div>

              {/* AM/PM */}
              <div className="w-24">
                <div className="py-2 px-1">
                  <div
                    className={`mb-1 py-3 cursor-pointer text-center rounded-xl transition-all duration-200 ${
                      period === 'AM'
                        ? 'text-indigo-600 font-semibold bg-white shadow-sm'
                        : 'text-gray-600 hover:bg-white hover:text-gray-900'
                    }`}
                    onClick={() => handlePeriodChange('AM')}
                  >
                    AM
                  </div>
                  <div
                    className={`py-3 cursor-pointer text-center rounded-xl transition-all duration-200 ${
                      period === 'PM'
                        ? 'text-indigo-600 font-semibold bg-white shadow-sm'
                        : 'text-gray-600 hover:bg-white hover:text-gray-900'
                    }`}
                    onClick={() => handlePeriodChange('PM')}
                  >
                    PM
                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex p-2 mt-2">
              <button
                onClick={() => setIsOpen(false)}
                className="flex-1 py-3 px-4 mx-1 rounded-xl text-gray-500 hover:text-gray-600 hover:bg-gray-50 font-medium transition-all duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="flex-1 py-3 px-4 mx-1 rounded-xl text-white bg-indigo-600 hover:bg-indigo-700 font-medium transition-all duration-200"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
