import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import TermsAndConditions from '../constants/TermsAndConditions';

const Terms: React.FC = () => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = 'https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public';
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden bg-gray-900">
      <div className="flex-1 overflow-y-auto">
        {/* Page Background */}
        <div className="h-screen overflow-hidden bg-gray-900 flex items-center justify-center px-4 sm:px-6 lg:px-8 relative">
          {/* Home Icon */}
          <button
            onClick={() => navigate('/')}
            className="absolute left-4 bottom-4 text-gray-500 opacity-50 hover:opacity-100 transition-opacity z-20 p-2 rounded-full hover:bg-white/10"
            aria-label="Home"
          >
            <FaHome size={24} />
          </button>

          {/* Background Image */}
          <div className="absolute inset-0 bg-gray-900">
            {/* Loading background */}
            <div className={`absolute inset-0 bg-gray-900 transition-opacity duration-500 ${imageLoaded ? 'opacity-0' : 'opacity-100'}`} />
            
            {/* Main image */}
            <img
              src="https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/8b5c0bbe-9f11-42f4-fabf-b3da242c6800/public"
              alt="Night soccer game under floodlights"
              className={`w-full h-full object-cover transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'} select-none pointer-events-none`}
              draggable="false"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>

          {/* Black overlay */}
          <div className="absolute inset-0 bg-black/30" />

          {/* Card Container */}
          <div className="bg-gray-50 rounded-lg shadow-xl p-6 max-w-4xl w-full relative z-10 mx-4 h-[80vh] flex flex-col">
            <h1 className="text-4xl font-bold tracking-tight mb-10 text-center text-neutral-900 select-none">Terms & Conditions</h1>
            <div className="prose prose-neutral max-w-none overflow-y-auto flex-1">
              <TermsAndConditions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
