import React, { useState, useRef, useEffect } from 'react';
import { FaMapMarkerAlt, FaTimesCircle } from 'react-icons/fa';
import FormField from './FormField';

// Define interfaces
interface Suggestion {
  description: string;
  placeId: string;
}

interface AutocompleteResponse {
  suggestions: Array<{
    placePrediction: {
      place: string;
      placeId: string;
      text: {
        text: string;
        matches: Array<{ endOffset: number }>;
      };
      // Add other fields if necessary
    };
  }>;
}

interface PlaceDetailsResponse {
  location: {
    latitude: number;
    longitude: number;
  };
}

interface LocationPickerProps {
  location: string;
  setLocation: (location: string) => void;
  setCoordinates: (coordinates: { lat: number; lng: number } | null) => void;
}

const LocationPicker: React.FC<LocationPickerProps> = ({ location, setLocation, setCoordinates }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userLocations, setUserLocations] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isLocationSelected, setIsLocationSelected] = useState(!!location);

  useEffect(() => {
    const fetchUserLocations = async () => {
    //   const locations = await useUserLocations();
    //   setUserLocations(locations);
    };
    fetchUserLocations();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocation(value);
    setIsLocationSelected(false);
    
    if (value.length > 2) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (description: string, placeId: string) => {
    setLocation(description);
    setShowDropdown(false);
    setIsLocationSelected(true);
    fetchCoordinates(placeId);
  };

  // Update fetchSuggestions function
  const fetchSuggestions = async (input: string) => {
    try {
      const response = await fetch('https://places.googleapis.com/v1/places:autocomplete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': 'AIzaSyCywtheAnFb8j2NPTxoiZAjYqc7oixpTNw',
        },
        body: JSON.stringify({
          input: input,
        }),
      });
      const data: AutocompleteResponse = await response.json();
      if (data.suggestions) {
        const suggestionList: Suggestion[] = data.suggestions.map((suggestionItem) => ({
          description: suggestionItem.placePrediction.text.text,
          placeId: suggestionItem.placePrediction.placeId,
        }));
        setSuggestions(suggestionList);
      } else {
        setSuggestions([]);
        console.error('Error fetching suggestions:', data);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  };

  const fetchCoordinates = async (placeId: string) => {
    try {
      const response = await fetch(`https://places.googleapis.com/v1/places/${placeId}?fields=location`, {
        method: 'GET',
        headers: {
          'X-Goog-Api-Key': 'AIzaSyCywtheAnFb8j2NPTxoiZAjYqc7oixpTNw',
        },
      });
      const data = await response.json();
      if (data.location) {
        setCoordinates({ 
          lat: data.location.latitude, 
          lng: data.location.longitude 
        });
      } else {
        setCoordinates(null);
        console.error('Error fetching coordinates:', data);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      setCoordinates(null);
    }
  };

  const formatLocation = (location: string) => {
    const parts = location.split(',');
    if (parts.length > 1) {
      return {
        primary: parts[0].trim(),
        secondary: parts.slice(1).join(',').trim()
      };
    }
    return {
      primary: location,
      secondary: ''
    };
  };

  const formattedLocation = formatLocation(location);

  const handleRemoveLocation = () => {
    setLocation('');
    setIsLocationSelected(false);
    setCoordinates(null);
    setShowDropdown(false);
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div className="flex items-start px-4 py-3 border-b border-gray-200">
        <FaMapMarkerAlt className={`w-5 h-5 mt-1 ${isLocationSelected ? 'text-indigo-600' : 'text-gray-400'}`} />
        {!isLocationSelected ? (
          <div className="flex-1 ml-3">
            <input
              type="text"
              value={location}
              onChange={handleInputChange}
              placeholder="Add location"
              onFocus={() => setShowDropdown(true)}
              className="w-full text-base text-gray-800 bg-transparent border-none focus:outline-none focus:ring-0 placeholder-gray-400"
            />
          </div>
        ) : (
          <div className="ml-3 flex-1 flex items-start justify-between">
            <div className="flex flex-col">
              <span className="text-base font-medium text-gray-900">{formattedLocation.primary}</span>
              {formattedLocation.secondary && (
                <span className="text-sm text-gray-500">{formattedLocation.secondary}</span>
              )}
            </div>
            <button
              onClick={handleRemoveLocation}
              className="text-gray-400 hover:text-gray-600 transition-colors"
            >
              <FaTimesCircle className="w-4 h-4" />
            </button>
          </div>
        )}
      </div>
      {showDropdown && (
        <div className="absolute mt-1 w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden z-10">
          {userLocations.length > 0 && (
            <div className="border-b border-gray-200">
              <div className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Your Locations</div>
              {userLocations.map((loc, index) => (
                <div
                  key={index}
                  className="px-4 py-3 cursor-pointer hover:bg-gray-50 transition-colors flex items-center"
                  onClick={() => handleSelect(loc, '')}
                >
                  <FaMapMarkerAlt className="text-gray-400 mr-3 w-4 h-4" />
                  <span className="text-base text-gray-900">{loc}</span>
                </div>
              ))}
            </div>
          )}
          {suggestions.length > 0 && (
            <div>
              <div className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">Suggestions</div>
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="px-4 py-3 cursor-pointer hover:bg-gray-50 transition-colors flex items-center"
                  onClick={() => handleSelect(suggestion.description, suggestion.placeId)}
                >
                  <FaMapMarkerAlt className="text-gray-400 mr-3 w-4 h-4" />
                  <span className="text-base text-gray-900">{suggestion.description}</span>
                </div>
              ))}
            </div>
          )}
          {suggestions.length === 0 && location.length > 2 && (
            <div className="p-4 text-sm text-gray-500">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default LocationPicker;
