import React, { useEffect, useRef, useState, memo } from 'react';

interface TurnstileProps {
  siteKey: string;
  onTokenChange: (token: string | null) => void;
  resetKey?: number;
}

interface TurnstileOptions {
  sitekey: string;
  callback: (token: string) => void;
  expiredCallback?: () => void;
  errorCallback?: () => void;
  theme?: string;
  appearance?: string;
}

declare global {
  interface Window {
    turnstile?: {
      render: (
        container: HTMLElement,
        options: TurnstileOptions
      ) => number;
      reset: (widgetId: number) => void;
      remove: (widgetId: number) => void;
    };
  }
}

const Turnstile: React.FC<TurnstileProps> = memo(
  ({ siteKey, onTokenChange, resetKey }) => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const widgetId = useRef<number | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const onTokenChangeRef = useRef(onTokenChange);

    const callback = (token: string) => {
      onTokenChangeRef.current(token);
    };

    const expiredCallback = () => {
      onTokenChangeRef.current(null);
    };

    const errorCallback = () => {
      onTokenChangeRef.current(null);
    };

    // Update ref when callback changes
    useEffect(() => {
      onTokenChangeRef.current = onTokenChange;
    }, [onTokenChange]);

    // Script loading effect
    useEffect(() => {
      if (document.querySelector('script[src*="turnstile"]')) {
        setIsScriptLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
      script.async = true;
      script.onload = () => setIsScriptLoaded(true);
      document.body.appendChild(script);

      return () => {
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
      };
    }, []); // Run only once on mount

    // Widget rendering effect
    useEffect(() => {
      if (!isScriptLoaded || !window.turnstile || !containerRef.current) return;

      widgetId.current = window.turnstile.render(containerRef.current, {
        sitekey: siteKey,
        callback,
        expiredCallback,
        errorCallback,
        theme: 'light',
        appearance: 'always',
      });

      return () => {
        if (widgetId.current !== null && window.turnstile) {
          window.turnstile.remove(widgetId.current);
          widgetId.current = null;
        }
      };
    }, [isScriptLoaded, siteKey]); // Only re-render when script loads or siteKey changes

    // Handle reset
    useEffect(() => {
      if (resetKey && widgetId.current !== null && window.turnstile) {
        window.turnstile.reset(widgetId.current);
      }
    }, [resetKey]);

    return <div ref={containerRef} style={{ width: '100%' }} />;
  }
);

export default Turnstile;
