import React from 'react';
import { FaClone, FaEnvelope } from 'react-icons/fa';
import CopyableEmail from '../components/CopyableEmail';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="p-6 md:p-8 text-gray-800">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900">Kickoff Terms &amp; Conditions</h1>
      <p className="mb-8 text-gray-600"><strong>Effective Date:</strong> 31st December 2024</p>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">1. Acceptance of Terms</h2>
        <p className="mb-4 leading-relaxed">
          Welcome to <strong>Kickoff</strong> (“<strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>”).
          By registering for an account, accessing, or using our platform (the “<strong>Platform</strong>”)—including
          our website, mobile applications, and related services—you acknowledge that you have read,
          understand, and agree to be bound by these Terms &amp; Conditions (“<strong>Terms</strong>”). If you do
          not agree with any part of these Terms, you must immediately discontinue use of the Platform.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">2. Eligibility</h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Age Requirement:</strong> You must be at least 13 years of age (or
            the age of majority in your jurisdiction) to use the Platform. By using Kickoff, you affirm that you
            meet this age requirement.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Legal Capacity:</strong> You represent that you have the legal
            capacity to enter into a binding agreement.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Local Laws:</strong> You are responsible for complying with all
            local laws where you access the Platform, including any sports or recreational activity regulations.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          3. User Accounts and Registration
        </h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Account Creation:</strong> To access certain features, you may need
            to create an account, providing accurate and complete information, including a valid email address
            and a secure password. You agree to keep your account information up to date.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Multiple Accounts:</strong> You may not create multiple accounts
            for abusive or disruptive behavior. We reserve the right to remove or merge duplicate accounts.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Account Security:</strong> You are solely responsible for
            safeguarding your login credentials. Any activity under your account will be your responsibility.
            You agree to notify us promptly of any unauthorized access or suspected breach.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          4. Assumption of Risk and Physical Fitness
        </h2>
        <p className="mb-4 leading-relaxed">
          <strong>4.1 Physical Requirements.</strong> Many activities organized through Kickoff may involve sports,
          exercise, or other forms of physical exertion. You represent that you understand the physical demands
          of such activities, that you are in good health, and that you have no condition that would pose a risk
          to your health or safety while participating. If you have any doubt, you should consult a qualified
          medical professional before engaging in these activities.
        </p>
        <p className="mb-4 leading-relaxed">
          <strong>4.2 Voluntary Participation.</strong> You understand and agree that your participation in any
          sports, fitness, or recreational activities is entirely voluntary. You acknowledge that Kickoff does
          not evaluate or guarantee the skill level or physical fitness of any participant, and you assume all
          risks related to your participation.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          5. Liability Waiver and Release
        </h2>
        <p className="mb-4 leading-relaxed">
          <strong>5.1 Acknowledgment of Risks.</strong> You acknowledge and fully understand that sports,
          exercise, or recreational activities involve inherent risks, including but not limited to severe
          personal injury, permanent disability, property damage, or even death. These risks may be caused by:
        </p>
        <ul className="space-y-4 mb-4 list-disc pl-5">
          <li className="leading-relaxed">
            The actions, inactions, or negligence of other participants, volunteers, spectators, or organizers.
          </li>
          <li className="leading-relaxed">Conditions of the premises, facilities, or equipment used.</li>
          <li className="leading-relaxed">
            Weather conditions, unforeseeable hazards, or other factors beyond our control.
          </li>
          <li className="leading-relaxed">
            Failure of you or other participants to abide by posted rules or guidelines.
          </li>
        </ul>
        <p className="mb-4 leading-relaxed">
          <strong>5.2 Assumption of Risk.</strong> You knowingly, intelligently, and voluntarily assume all
          risks—both known and unknown, anticipated and unanticipated, foreseeable and unforeseeable—associated
          with your participation, <em>even if</em> arising from the negligence of Kickoff or its affiliates.
        </p>
        <p className="mb-4 leading-relaxed">
          <strong>5.3 Release of Liability.</strong> In consideration of being allowed to participate in any way
          on the Platform, <strong>you</strong> (on behalf of yourself, your heirs, beneficiaries, executors,
          administrators, personal representatives, and assigns) <strong>HEREBY WAIVE, RELEASE, DISCHARGE, AND
          AGREE NOT TO SUE</strong> Kickoff, its affiliates, subsidiaries, employees, volunteers, agents,
          sponsors, advertisers, and if applicable, owners and lessors of the facilities, from any and all
          claims for damages, injuries, losses, liabilities, and expenses, including those based on Kickoff’s{' '}
          <strong>negligence</strong>, arising out of or related to your participation in any program, event, or
          activity organized or facilitated by Kickoff.
        </p>
        <p className="mb-4 leading-relaxed">
          <strong>5.4 Indemnification.</strong> You agree to indemnify, defend, and hold harmless Kickoff and
          its Releasees from any claims, liabilities, losses, damages, or expenses (including reasonable
          attorneys’ fees) arising from or related to (a) your violation of these Terms, (b) your participation
          in sports or recreational activities, or (c) your negligent or wrongful conduct.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">6. Code of Conduct</h2>
        <p className="mb-4 leading-relaxed">
          Kickoff is committed to fostering a safe, inclusive, and fun environment for all participants. You
          agree to abide by the following guidelines (the “<strong>Code of Conduct</strong>”). These guidelines
          apply at all times when you use or participate in activities arranged through Kickoff, including
          virtual or in-person events.
        </p>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Respect for Others:</strong> You will treat all participants,
            officials, referees, and spectators with courtesy and respect. This includes, but is not limited to,
            refraining from any form of harassment, discrimination, or abusive behavior.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">No Discriminatory Language or Behavior:</strong> You will not
            express any derogatory or unwelcome comments based on or in reference to race, sex, religion,
            national origin, disability, age, sexual orientation, or gender identity.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Safe Play:</strong> You agree not to engage in overly aggressive
            behavior, unnecessary roughness, or actions that endanger the safety of other participants or
            spectators.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Objectionable Content:</strong> You will not post content that
            is illegal, threatening, harassing, abusive, defamatory, discriminatory, or otherwise
            objectionable. We reserve the right to remove such content and restrict or terminate access for
            violations.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Facility Rules:</strong> You will comply with all facility
            rules, including rules related to smoking, alcohol, and the care and use of equipment or premises.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Follow Official Decisions:</strong> You will abide by any
            official’s decisions regarding gameplay, disputes, or ejections. Arguments or confrontations with
            officials may result in suspension or removal from the event without refund.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Consequences:</strong> Violating the Code of Conduct may result
            in a warning, ejection, suspension without refund, or complete removal from the Platform.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          7. Medical Treatment and Emergencies
        </h2>
        <p className="mb-4 leading-relaxed">
          <strong>7.1 Consent to Treatment.</strong> By participating in any sports or recreational event
          organized through Kickoff, you consent to any necessary or appropriate medical treatment (including
          first aid) that may be deemed advisable in the event of an accident, injury, or illness.
        </p>
        <p className="mb-4 leading-relaxed">
          <strong>7.2 No Liability for Medical Decisions.</strong> You release Kickoff and all persons
          participating in or administering such treatment from any responsibility for medical decisions or
          actions taken during your participation.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          8. Media and Photography Release
        </h2>
        <p className="mb-4 leading-relaxed">
          You grant Kickoff the irrevocable right to record, photograph, or otherwise capture your likeness,
          voice, or participation in any event (“<strong>Content</strong>”). You agree that Kickoff may use this
          Content in any media for promotional, commercial, or marketing purposes without further compensation
          to you. You waive any right to inspect or approve the final product, and you understand that all
          Content is the sole property of Kickoff.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          9. Payments and Fees (If Applicable)
        </h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Fees and Charges:</strong> Certain events or premium features
            may require payment of fees. By purchasing such features or registering for paid events, you agree
            to pay all applicable fees.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Refunds:</strong> Refund policies will be specified at the time
            of purchase or registration. Unless otherwise stated, fees are non-refundable.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Payment Processing:</strong> Third-party payment processors
            handle payment transactions. Kickoff does not store your payment information and is not
            responsible for any errors or breaches by these processors.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          10. Intellectual Property
        </h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">User-Generated Content:</strong> You retain ownership of any
            content you post on the Platform, but you grant Kickoff a worldwide, non-exclusive, irrevocable,
            royalty-free license (with the right to sublicense) to use, display, copy, modify, and distribute
            such content for the operation and promotion of the Platform.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Platform Content:</strong> All trademarks, logos, designs, text,
            graphics, software, and other content provided by Kickoff are the exclusive property of Kickoff or
            its licensors. You may not use or distribute any Kickoff content without express written
            permission.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">11. Disclaimers</h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">As-Is Service:</strong> The Platform and all services provided
            through Kickoff are offered on an “as-is” and “as-available” basis. We disclaim all warranties,
            express or implied, including warranties of merchantability, fitness for a particular purpose, and
            non-infringement.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">No Guarantee of Availability:</strong> We do not warrant that
            the Platform will be uninterrupted, secure, or error-free. Maintenance or technical issues may
            result in service interruptions.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          12. Limitation of Liability
        </h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Indirect Damages:</strong> To the fullest extent allowed by
            law, in no event shall Kickoff be liable for any indirect, incidental, consequential, or special
            damages arising from or in connection with the use of the Platform.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Cap on Liability:</strong> In no event shall Kickoff’s total
            liability exceed the total amount paid by you to Kickoff in the 12 months preceding the event
            giving rise to the liability, or one hundred U.S. dollars (USD 100), whichever is greater.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          13. Dispute Resolution and Governing Law
        </h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Governing Law:</strong> These Terms are governed by and
            construed in accordance with the laws of [State/Country], without regard to its conflicts of law
            principles.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Arbitration:</strong> Any dispute or claim arising out of or
            relating to these Terms or the breach thereof shall be settled by binding arbitration administered
            in [City/State] under the rules of [Arbitration Institution]. The arbitrator’s decision shall be
            final.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Class Action Waiver:</strong> All claims must be brought in the
            parties’ individual capacity, and not as a plaintiff or class member in any purported class or
            representative proceeding.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          14. Modification of Terms
        </h2>
        <p className="mb-4 leading-relaxed">
          We reserve the right to amend or update these Terms at any time. When we do, we will post the revised
          Terms on the Platform and update the “Effective Date” above. Your continued use of the Platform after
          such changes indicates your acceptance of the revised Terms.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">15. Termination</h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Right to Terminate:</strong> We may suspend or terminate your
            account at any time, with or without notice, if we believe you have violated any portion of these
            Terms.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Effect of Termination:</strong> Upon termination, the rights
            granted to you under these Terms will cease immediately. You may not be entitled to any refunds for
            paid services.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">16. Miscellaneous</h2>
        <ul className="space-y-4 mb-4">
          <li className="leading-relaxed">
            <strong className="text-gray-900">Severability:</strong> If any provision of these Terms is found
            by an arbitrator or court of competent jurisdiction to be invalid, unlawful, or unenforceable, the
            remainder of the Terms shall remain in full force.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">No Waiver:</strong> Our failure to enforce any right or
            provision of these Terms shall not constitute a waiver of future enforcement of that right or
            provision.
          </li>
          <li className="leading-relaxed">
            <strong className="text-gray-900">Entire Agreement:</strong> These Terms, along with our Privacy
            Policy, constitute the complete and exclusive agreement between you and Kickoff regarding the use
            of the Platform.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-900">
          17. Contact Information
        </h2>
        <p className="mb-4 leading-relaxed">
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <CopyableEmail email="info@kickoff.game" />
      </section>

      <p className="mb-4 leading-relaxed">
        <em>
          By using or accessing the Platform, you agree that you have read and understand these Terms &amp;
          Conditions and that you will abide by them. If you do not agree, you must not use the Platform.
        </em>
      </p>

      <hr />
      {/* <p style={{ fontStyle: 'italic' }}>
        <strong>NOTE:</strong> This document is intended as a comprehensive template. It should be reviewed
        and customized by qualified legal counsel to ensure compliance with all applicable laws and regulations.
      </p> */}
    </div>
  );
};

export default TermsAndConditions;
