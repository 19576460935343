import React, { useState, useRef } from 'react';
import { FaImage } from 'react-icons/fa';
import { Game } from '../models/games';

interface CoverImageSelectorProps {
	currentImage?: string;
	onChange: (url: string) => void;
}

const CoverImageSelector: React.FC<CoverImageSelectorProps> = ({ currentImage, onChange }) => {
	const [isUploading, setIsUploading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setIsUploading(true);
			setError(null);
			try {
				const imageId = await Game.uploadGameImage(file);
				onChange(imageId);
			} catch (err) {
				setError(err instanceof Error ? err.message : 'Failed to upload image');
				console.error('Failed to upload image:', err);
			} finally {
				setIsUploading(false);
			}
		}
	};

	return (
		<div className="relative w-full aspect-video rounded-lg overflow-hidden bg-gray-100">
			{currentImage ? (
				<>
					<img src={currentImage} alt="Cover" className="w-full h-full object-cover" />
					<button 
						onClick={() => fileInputRef.current?.click()}
						className="absolute top-2 right-2 bg-black/50 text-white p-2 rounded-full hover:bg-black/70"
						disabled={isUploading}
					>
						Change
					</button>
					<input 
						ref={fileInputRef}
						type="file" 
						accept="image/*" 
						onChange={handleImageUpload} 
						className="hidden" 
						disabled={isUploading}
					/>
				</>
			) : (
				<label className={`flex flex-col items-center justify-center w-full h-full cursor-pointer hover:bg-gray-200 transition-colors ${isUploading ? 'opacity-50 cursor-wait' : ''}`}>
					<FaImage className="w-8 h-8 text-gray-400 mb-2" />
					<span className="text-sm text-gray-500">
						{isUploading ? 'Uploading...' : 'Add Cover Image'}
					</span>
					<input 
						type="file" 
						accept="image/*" 
						onChange={handleImageUpload} 
						className="hidden" 
						disabled={isUploading}
					/>
				</label>
			)}
			{error && (
				<div className="absolute bottom-2 left-2 right-2 bg-red-500 text-white px-3 py-2 rounded text-sm">
					{error}
				</div>
			)}
		</div>
	);
};

export default CoverImageSelector; 