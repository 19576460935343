/**
 * useUserGames Hook
 *
 * This hook manages the user's games data, providing efficient caching and
 * optimistic updates for a responsive user experience.
 *
 * Functionality:
 * 1. Immediately returns cached games data if available and not expired.
 * 2. Asynchronously fetches and updates games data when needed.
 * 3. Implements local storage caching with a 24-hour expiration.
 * 4. Provides optimistic updates for adding new games.
 * 5. Manages loading and error states.
 *
 * Usage:
 * const { userGames, nearbyGames, isGamesLoading, error, refreshGames, loadMore, hasMore, year, setYear, getCurrentYear } = useUserGames(options);
 *
 * - userGames: Array of Game objects or null if not loaded
 * - nearbyGames: Array of Game objects or null if not loaded
 * - isGamesLoading: Boolean indicating if games are being fetched
 * - error: Error object if an error occurred during fetching
 * - refreshGames: Function to refresh games data
 * - loadMore: Function to load more games
 * - hasMore: Boolean indicating if there are more games to load
 * - year: Current year
 * - setYear: Function to set the year
 * - getCurrentYear: Function to get the current year
 *
 * The hook will cause a re-render when:
 * - Initial cached data is loaded
 * - New games data is fetched
 * - A game is added (optimistically and after confirmation)
 * - Loading state changes
 * - An error occurs
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, new data is fetched from the server
 * - Cache expiration is set to 24 hours
 *
 * Note: This hook depends on the useUserProfile hook for the current user's ID.
 */

import { useState, useEffect, useMemo, useCallback } from 'react';
import { useUserProfile } from './useUserProfile';
import { Game } from '../models/games';

const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Function to sort games by their scheduled date (ascending order)
const sortGamesByDate = (games: Game[]): Game[] => {
	return games.sort((a, b) => new Date(a.scheduled_at).getTime() - new Date(b.scheduled_at).getTime());
};

// Get current year
const getCurrentYear = (): number => {
    return new Date().getFullYear();
};

// Static in-memory cache that persists across hook instances
export const gamesCache = {
	userGames: null as Game[] | null,
	nearbyGames: null as Game[] | null,
	nextPage: null as string | null,
	hasMore: true,
	isFetching: false,
};

export interface UseUserGamesOptions {
	limit?: number;
	initialYear?: number;
	noprefetch?: boolean;
}

export const useUserGames = (options?: UseUserGamesOptions) => {
	const { userProfile } = useUserProfile();
	const [userGames, setUserGames] = useState<Game[] | null>(() => gamesCache.userGames);
	const [nearbyGames, setNearbyGames] = useState<Game[] | null>(() => gamesCache.nearbyGames);
	const [isGamesLoading, setIsGamesLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [refreshKey, setRefreshKey] = useState(0);
	const [hasMore, setHasMore] = useState(() => gamesCache.hasMore);
	const [nextPage, setNextPage] = useState<string | null>(() => gamesCache.nextPage);
	const [year, setYear] = useState<number | undefined>(options?.initialYear);

	const refreshGames = useCallback(() => {
		setRefreshKey((prev) => prev + 1);
		setYear(undefined);
		setNextPage(null);
		setHasMore(true);
		gamesCache.userGames = null;
		gamesCache.nearbyGames = null;
		gamesCache.nextPage = null;
		gamesCache.hasMore = true;
	}, []);

	const refreshGame = useCallback(async (gameId: string) => {
		try {
			const updatedGame = await Game.fetchById(gameId);
			setUserGames((prevGames) => {
				if (!prevGames) return prevGames;
				const updatedGames = prevGames.map(game => 
					game.game_id === gameId ? updatedGame : game
				);
				gamesCache.userGames = updatedGames;
				return updatedGames;
			});
			return updatedGame;
		} catch (error) {
			console.error('Failed to refresh game:', error);
			throw error;
		}
	}, []);

	// Function to convert game data to Game instances
	const convertToGameInstances = useCallback((games: any[]): Game[] => {
		return games.map((gameData) => {
			if (gameData instanceof Game) return gameData;
			return new Game(gameData);
		});
	}, []);

	// Function to deduplicate games
	const dedupeGames = useCallback((games: Game[]): Game[] => {
		const uniqueGames = new Map<string, Game>();
		games.forEach((game) => {
			uniqueGames.set(game.game_id, game);
		});
		return Array.from(uniqueGames.values());
	}, []);

	// Function to load more games
	const loadMore = useCallback(async () => {
		if (!userProfile || !hasMore || isGamesLoading) return;

		setIsGamesLoading(true);
		gamesCache.isFetching = true;

		try {
			// Fetch paginated user games
			const userGamesResult = await Game.getByUserId(userProfile.user_id, {
				limit: options?.limit || 10,
				pageState: nextPage,
				year,
			});

			setNextPage(userGamesResult.nextPage);
			setHasMore(userGamesResult.hasMore);

			if (userGamesResult.games.length > 0) {
				const newGames = convertToGameInstances(userGamesResult.games);
				setUserGames((prevGames) => {
					const updatedGames = prevGames ? dedupeGames(sortGamesByDate([...prevGames, ...newGames])) : newGames;
					gamesCache.userGames = updatedGames;
					return updatedGames;
				});
			}
		} catch (err) {
			console.error('Failed to load more games:', err);
			setError(err instanceof Error ? err : new Error('Failed to load more games'));
		} finally {
			setIsGamesLoading(false);
			gamesCache.isFetching = false;
		}
	}, [userProfile, hasMore, isGamesLoading, nextPage, year, options?.limit, convertToGameInstances, dedupeGames]);

	// Initial data fetch
	useEffect(() => {
		if (!userProfile) {
			setIsGamesLoading(false);
			return;
		}

		let isMounted = true;

		const fetchInitialData = async () => {
			if (!gamesCache.userGames && !gamesCache.isFetching) {
				setIsGamesLoading(true);
				gamesCache.isFetching = true;
			}

			try {
				// Fetch initial user games with pagination
				const userGamesResult = await Game.getByUserId(userProfile.user_id, {
					limit: options?.limit || 10,
					pageState: gamesCache.nextPage,
					year,
				});

				if (!isMounted) return;

				// Fetch nearby games
				const nearbyGamesResult = await Game.getNearby(userProfile.user_id);

				if (!isMounted) return;

				const validUserGames = convertToGameInstances(userGamesResult.games.filter(isValidGame));
				const validNearbyGames = convertToGameInstances(nearbyGamesResult.filter(isValidGame));

				if (isMounted) {
					setUserGames(validUserGames);
					setNearbyGames(validNearbyGames);
					setNextPage(userGamesResult.nextPage);
					setHasMore(userGamesResult.hasMore);

					// Update cache
					gamesCache.userGames = validUserGames;
					gamesCache.nearbyGames = validNearbyGames;
					gamesCache.nextPage = userGamesResult.nextPage;
					gamesCache.hasMore = userGamesResult.hasMore;
					gamesCache.isFetching = false;

					setError(null);
				}
			} catch (err) {
				if (!isMounted) return;
				console.error('Error fetching games:', err);
				setError(err as Error);
				gamesCache.isFetching = false;
			} finally {
				if (isMounted) {
					setIsGamesLoading(false);
				}
			}
		};

		if (!(options?.noprefetch)) {
			fetchInitialData();
		}

		return () => {
			isMounted = false;
		};
	}, [userProfile?.user_id, refreshKey, year, options?.limit, convertToGameInstances]);

	const allGames = useMemo(() => {
		const games = [...(nearbyGames || []), ...(userGames || [])];
		return dedupeGames(sortGamesByDate(games));
	}, [userGames, nearbyGames, dedupeGames]);

	return {
		userGames: allGames,
		nearbyGames,
		allGames,
		isGamesLoading,
		error,
		refreshGames,
		refreshGame,
		loadMore,
		hasMore,
		year,
		setYear,
		getCurrentYear,
	};
};

function isValidGame(game: Game): boolean {
	return (
		typeof game.game_id === 'string' &&
		typeof game.name === 'string' &&
		!isNaN(new Date(game.scheduled_at).getTime()) &&
		typeof game.location === 'string'
	);
}
