import React, { useState } from 'react';
import TopNavBar from '../components/TopNavBar';
import { Game } from '../models/games';
import { useNavigate } from 'react-router-dom';
import 'react-day-picker/dist/style.css';
import GameForm from '../components/GameForm';
import { useViewMode } from '../hooks/useViewMode';
import FrostedImageContainer from '../components/FrostedImageContainer';
import { Helmet } from 'react-helmet-async';
import { FaChevronLeft, FaUsers } from 'react-icons/fa';
import CoverImageSelector from '../components/CoverImageSelector';

type CreationStep = 'details' | 'review';

interface CreateGameProps {
	onSubmit: (gameData: Partial<Game>) => Promise<void>;
	onCancel: () => void;
	step: CreationStep;
	onStepChange: (step: CreationStep) => void;
	coverImage?: string;
	onCoverImageChange: (url: string) => void;
}

const css = `
	.rdp {
		--rdp-cell-size: 40px;
		--rdp-accent-color: rgb(79 70 229);
		--rdp-background-color: rgb(243 244 246);
		margin: 0;
		border: none !important;
	}
	.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
		background-color: rgb(243 244 246);
	}
	.rdp-day_selected {
		background-color: var(--rdp-accent-color);
	}
	.rdp-day_selected:hover {
		background-color: var(--rdp-accent-color);
	}
	.rdp-head_cell {
		font-size: 0.875rem;
		font-weight: 500;
		color: rgb(107 114 128);
		height: 40px;
	}
	.rdp-day {
		font-size: 0.875rem;
		color: rgb(55 65 81);
	}
	.rdp-nav_button {
		color: rgb(107 114 128);
	}
	.rdp-caption {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.rdp-caption_label {
		font-size: 1rem;
		font-weight: 600;
		color: rgb(31 41 55);
	}
`;

const CreateGameMobile: React.FC<CreateGameProps> = ({ onSubmit, onCancel, step, onStepChange, coverImage, onCoverImageChange }) => {
	const [gameData, setGameData] = useState<Partial<Game>>({});
	const [playersPerTeam, setPlayersPerTeam] = useState(11);
	const [reserveCount, setReserveCount] = useState<number | null>(null);

	const onInputChange = (field: keyof Game, value: any) => {
		setGameData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const handlePlayerCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === '') {
			setPlayersPerTeam(0);
			onInputChange('max_members', 0 + (reserveCount || 0));
		} else {
			const count = Math.max(0, parseInt(value) || 0);
			setPlayersPerTeam(count);
			onInputChange('max_members', count * 2 + (reserveCount || 0));
		}
	};

	const handleReserveCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === '') {
			setReserveCount(0);
			onInputChange('max_members', playersPerTeam * 2 + 0);
			onInputChange('reserve_count', 0);
		} else {
			const count = Math.max(0, parseInt(value) || 0);
			setReserveCount(count);
			onInputChange('max_members', playersPerTeam * 2 + count);
			onInputChange('reserve_count', count);
		}
	};

	const handleNextStep = async (updatedGameData: Partial<Game>) => {
		setGameData((prevData) => ({
			...prevData,
			...updatedGameData,
		}));
		onStepChange('review');
		return Promise.resolve();
	};

	const handleCancel = () => {
		setReserveCount(0);
		onCancel();
	};

	return (
		<>
			<style>{css}</style>
			{/* Step Navigation */}
			<div className="bg-white border-b border-gray-200 p-4 flex items-center">
				{step === 'review' && (
					<button 
						onClick={() => onStepChange('details')}
						className="text-gray-500 hover:text-gray-700 mr-4"
					>
						<FaChevronLeft className="w-5 h-5" />
					</button>
				)}
				{step === 'details' ? (
					<input
						type="text"
						className="flex-1 text-lg font-semibold text-gray-800 bg-transparent border-none focus:outline-none focus:ring-0"
						value={gameData.name || ''}
						onChange={(e) => onInputChange('name', e.target.value)}
						placeholder="Enter game title..."
					/>
				) : (
					<h3 className="text-lg font-semibold text-gray-800">Review & Create</h3>
				)}
			</div>

			{/* Form Content */}
			<div className="bg-white p-4 space-y-6 pb-20">
				{step === 'details' ? (
					<>
						{/* Basic Details */}
						<GameForm 
							gameData={gameData} 
							onInputChange={onInputChange}
							onSubmit={handleNextStep}
							onCancel={handleCancel}
							hideSubmit
							hideTitle
							coverImage={coverImage}
							onCoverImageChange={onCoverImageChange}
						/>

						{/* Next Step Button */}
						<div className="pt-6">
							<button
								onClick={() => handleNextStep(gameData)}
								className="w-full bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition-colors"
							>
								Next: Configure Players
							</button>
						</div>
					</>
				) : (
					<>
						{/* Player Settings */}
						<div className="space-y-6">
							<div>
								<label className="block text-sm font-medium text-gray-700 mb-2">Players per Team</label>
								<div className="flex items-center space-x-4">
									<input
										type="number"
										value={playersPerTeam || ''}
										onChange={handlePlayerCountChange}
										className="w-20 px-3 py-2 border border-gray-300 rounded-md"
										min="0"
									/>
									<span className="text-gray-500">×2 teams</span>
									<span className="text-gray-700 font-medium">{playersPerTeam * 2} players total</span>
								</div>
							</div>

							<div>
								<label className="block text-sm font-medium text-gray-700 mb-2">Reserve Players</label>
								<div className="flex items-center space-x-4">
									<input
										type="number"
										value={reserveCount || ''}
										onChange={handleReserveCountChange}
										className="w-20 px-3 py-2 border border-gray-300 rounded-md"
										min="0"
										placeholder="0"
									/>
									<span className="text-gray-700 font-medium">+{reserveCount || 0} reserves</span>
								</div>
							</div>

							<div className="pt-4 border-t border-gray-200">
								<div className="flex items-center justify-between text-sm">
									<span className="text-gray-500">Total Players</span>
									<span className="text-gray-900 font-semibold">{playersPerTeam * 2 + (reserveCount || 0)}</span>
								</div>
							</div>
						</div>

						{/* Create Button */}
						<div className="mt-8">
							<button
								onClick={() => onSubmit({
									...gameData,
									max_members: playersPerTeam * 2 + (reserveCount || 0),
									reserve_count: reserveCount,
									cover_image: coverImage,
									scheduled_at: gameData.scheduled_at ?? new Date()
								})}
								className="w-full bg-indigo-600 text-white px-4 py-3 rounded-lg hover:bg-indigo-700 transition-colors flex items-center justify-center gap-2"
							>
								<FaUsers className="w-5 h-5" />
								<span>Create Game</span>
							</button>
						</div>
					</>
				)}
			</div>
		</>
	);
};

const CreateGameDesktop: React.FC<CreateGameProps> = ({ onSubmit, onCancel, step, onStepChange, coverImage, onCoverImageChange }) => {
	const [gameData, setGameData] = useState<Partial<Game>>({});
	const [playersPerTeam, setPlayersPerTeam] = useState(11);
	const [reserveCount, setReserveCount] = useState<number | null>(null);

	const handleInputChange = (field: keyof Game, value: any) => {
		setGameData((prevData) => ({ ...prevData, [field]: value }));
	};

	const handlePlayerCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === '') {
			setPlayersPerTeam(0);
			handleInputChange('max_members', 0 + (reserveCount || 0));
		} else {
			const count = Math.max(0, parseInt(value) || 0);
			setPlayersPerTeam(count);
			handleInputChange('max_members', count * 2 + (reserveCount || 0));
		}
	};

	const handleReserveCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value === '') {
			setReserveCount(0);
			handleInputChange('max_members', playersPerTeam * 2 + 0);
			handleInputChange('reserve_count', 0);
		} else {
			const count = Math.max(0, parseInt(value) || 0);
			setReserveCount(count);
			handleInputChange('max_members', playersPerTeam * 2 + count);
			handleInputChange('reserve_count', count);
		}
	};

	const handleNextStep = async (updatedGameData: Partial<Game>) => {
		setGameData((prevData) => ({
			...prevData,
			...updatedGameData,
		}));
		onStepChange('review');
		return Promise.resolve();
	};

	const handleCancel = () => {
		setReserveCount(0);
		onCancel();
	};

	return (
		<div className="max-w-4xl mx-auto pb-24">
			<div className="bg-white rounded-xl shadow-sm overflow-hidden">
				<style>{css}</style>
				{/* Step Navigation */}
				<div className="border-b border-gray-200 p-6 flex items-center">
					{step === 'review' && (
						<button 
							onClick={() => onStepChange('details')}
							className="text-gray-500 hover:text-gray-700 mr-4"
						>
							<FaChevronLeft className="w-5 h-5" />
						</button>
					)}
					{step === 'details' ? (
						<input
							type="text"
							className="flex-1 text-xl font-semibold text-gray-800 bg-transparent border-none focus:outline-none focus:ring-0"
							value={gameData.name || ''}
							onChange={(e) => handleInputChange('name', e.target.value)}
							placeholder="Enter game title..."
						/>
					) : (
						<h3 className="text-xl font-semibold text-gray-800">Review & Create</h3>
					)}
				</div>

				{/* Form Container */}
				<div className="p-8 space-y-8">
					{step === 'details' ? (
						<>
							{/* Basic Details */}
							<GameForm
								gameData={gameData}
								onInputChange={handleInputChange}
								onSubmit={handleNextStep}
								onCancel={handleCancel}
								hideSubmit
								hideTitle
								coverImage={coverImage}
								onCoverImageChange={onCoverImageChange}
							/>

							{/* Next Step Button */}
							<div className="pt-6">
								<button
									onClick={() => handleNextStep(gameData)}
									className="w-full bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition-colors"
								>
									Next: Configure Players
								</button>
							</div>
						</>
					) : (
						<>
							{/* Player Settings */}
							<div className="grid grid-cols-2 gap-8">
								<div className="space-y-6">
									<div>
										<label className="block text-sm font-medium text-gray-700 mb-2">Players per Team</label>
										<div className="flex items-center space-x-4">
											<input
												type="number"
												value={playersPerTeam || ''}
												onChange={handlePlayerCountChange}
												className="w-24 px-3 py-2 border border-gray-300 rounded-md"
												min="0"
											/>
											<span className="text-gray-500">×2 teams</span>
											<span className="text-gray-700 font-medium">{playersPerTeam * 2} players total</span>
										</div>
									</div>

									<div>
										<label className="block text-sm font-medium text-gray-700 mb-2">Reserve Players</label>
										<div className="flex items-center space-x-4">
											<input
												type="number"
												value={reserveCount || ''}
												onChange={handleReserveCountChange}
												className="w-24 px-3 py-2 border border-gray-300 rounded-md"
												min="0"
												placeholder="0"
											/>
											<span className="text-gray-700 font-medium">+{reserveCount || 0} reserves</span>
										</div>
									</div>

									<div className="pt-4 border-t border-gray-200">
										<div className="flex items-center justify-between text-sm">
											<span className="text-gray-500">Total Players</span>
											<span className="text-gray-900 font-semibold">{playersPerTeam * 2 + (reserveCount || 0)}</span>
										</div>
									</div>
								</div>

								<div className="bg-gray-50 p-6 rounded-lg">
									<h4 className="font-medium text-gray-900 mb-4">Game Summary</h4>
									<dl className="space-y-3">
										<div>
											<dt className="text-sm text-gray-500">Game Name</dt>
											<dd className="text-sm font-medium text-gray-900">{gameData.name || '-'}</dd>
										</div>
										<div>
											<dt className="text-sm text-gray-500">Location</dt>
											<dd className="text-sm font-medium text-gray-900">{gameData.location || '-'}</dd>
										</div>
										<div>
											<dt className="text-sm text-gray-500">Date & Time</dt>
											<dd className="text-sm font-medium text-gray-900">
												{gameData.scheduled_at ? new Date(gameData.scheduled_at).toLocaleString() : '-'}
											</dd>
										</div>
									</dl>
								</div>
							</div>

							{/* Create Button */}
							<div className="pt-6">
								<button
									onClick={() => onSubmit({
										...gameData,
										max_members: playersPerTeam * 2 + (reserveCount || 0),
										reserve_count: reserveCount,
										cover_image: coverImage,
										scheduled_at: gameData.scheduled_at ?? new Date()
									})}
									className="w-full bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition-colors flex items-center justify-center gap-2"
								>
									<FaUsers className="w-5 h-5" />
									<span>Create Game</span>
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const CreateGamePage: React.FC = () => {
	const navigate = useNavigate();
	const { isMobile } = useViewMode();
	const [step, setStep] = useState<CreationStep>('details');
	const [coverImage, setCoverImage] = useState<string>();

	const handleCreateGame = async (gameData: Partial<Game>) => {
		try {
			const finalGameData = {
				...gameData,
				max_members: gameData.max_members,
				reserve_count: gameData.reserve_count,
			};
			const newGame = new Game(finalGameData);
			await newGame.create();
			navigate(`/dashboard`);
		} catch (error) {
			console.error('Failed to create game:', error);
		}
	};

	return (
		<div className="min-h-screen flex flex-col sm:bg-gray-50 bg-white">
			<Helmet>
				<title>Create Game | Kickoff.game</title>
				<meta name="description" content="Create a new game on Kickoff.game!" />
			</Helmet>

			<TopNavBar />
			
			<div className="flex-1 flex flex-col sm:bg-gray-50 bg-white">
				{/* Hero Section with Image */}
				<div className="relative h-48 sm:h-64 w-full overflow-hidden bg-gradient-to-b from-indigo-600/90 to-indigo-800/90">
					<div className="absolute inset-0">
						<FrostedImageContainer
							src={coverImage || "https://imagedelivery.net/60EBju9pR70EGeFkQ4RoMw/1c690c6c-e593-4328-22a9-d9fb30ec4700/public"}
							alt="Create Game"
						/>
					</div>
					<div className="absolute inset-0 bg-black/30" />
					<div className="relative h-full flex flex-col justify-center items-center text-center px-4 sm:px-6 text-white">
						<h1 className="text-2xl sm:text-3xl font-bold mb-2">Create Your Game</h1>
						<p className="text-sm sm:text-base text-gray-100 max-w-xl">
							Set up your match details and invite players to join
						</p>
					</div>
				</div>

				{/* Main Content */}
				<div className="flex-1 px-2 py-4 sm:px-4 sm:py-6 md:px-6 md:py-8 lg:px-8 lg:py-10">
					<div className="max-w-7xl mx-auto">
						{/* Progress Steps */}
						<div className="mb-8 hidden sm:block">
							<div className="flex justify-center items-center space-x-4">
								<div className="flex items-center">
									<div className={`w-8 h-8 rounded-full flex items-center justify-center ${
										step === 'details' ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-indigo-600'
									}`}>1</div>
									<div className={`ml-2 text-sm font-medium ${
										step === 'details' ? 'text-gray-900' : 'text-gray-500'
									}`}>Game Details</div>
								</div>
								<div className="w-16 h-0.5 bg-gray-200" />
								<div className="flex items-center">
									<div className={`w-8 h-8 rounded-full flex items-center justify-center ${
										step === 'review' ? 'bg-indigo-600 text-white' : 'bg-indigo-100 text-indigo-600'
									}`}>2</div>
									<div className={`ml-2 text-sm font-medium ${
										step === 'review' ? 'text-gray-900' : 'text-gray-500'
									}`}>Configure Players</div>
								</div>
							</div>
						</div>

						{/* Form Container */}
						<div className="mt-4 sm:mt-8">
							{isMobile ? (
								<CreateGameMobile 
									onSubmit={handleCreateGame} 
									onCancel={() => navigate('/dashboard')}
									step={step}
									onStepChange={setStep}
									coverImage={coverImage}
									onCoverImageChange={setCoverImage}
								/>
							) : (
								<CreateGameDesktop 
									onSubmit={handleCreateGame} 
									onCancel={() => navigate('/dashboard')}
									step={step}
									onStepChange={setStep}
									coverImage={coverImage}
									onCoverImageChange={setCoverImage}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateGamePage;
