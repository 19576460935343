import React, { useState, useEffect, useMemo, useCallback } from 'react';
import TopNavBar from '../components/TopNavBar';
import GamesList from '../components/GamesList';
import { useUserGames, gamesCache } from '../hooks/useUserGames';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../hooks/useUserProfile';
import '../styles/scrollbar.css';
import { Game } from 'models/games';

const Dashboard: React.FC = () => {
	const { userGames, isGamesLoading, error: gamesError, refreshGames, refreshGame } = useUserGames();
	const navigate = useNavigate();
	const { userProfile } = useUserProfile();

	const [lastRefresh, setLastRefresh] = useState(Date.now());
	const [scrollPosition, setScrollPosition] = useState(() => {
		const saved = sessionStorage.getItem('dashboardScrollPosition');
		return saved ? parseInt(saved, 10) : 0;
	});

	// State for joining and leaving games
	const [isJoining, setIsJoining] = useState(false);
	const [isLeaving, setIsLeaving] = useState(false);

	// Function to join a game
	const handleJoinGame = async (gameId: string) => {
		if (!userGames) return;
		setIsJoining(true);
		try {
			const game = userGames.find(game => game.game_id === gameId);
			if (!game) return;
			await game.joinGame();
			await refreshGame(gameId);
			await pollGameStatus(gameId, (game) => !!game.members.get(userProfile?.user_id));
		} catch (error) {
			console.error('Failed to join game:', error);
		} finally {
			setIsJoining(false);
		}
	};

	// Function to leave a game
	const handleLeaveGame = async (gameId: string) => {
		if (!userGames) return;
		setIsLeaving(true);
		try {
			const game = userGames.find(game => game.game_id === gameId);
			if (!game) return;
			await game.leaveGame();
			await refreshGame(gameId);
			await pollGameStatus(gameId, (game) => !game.members.get(userProfile?.user_id));
		} catch (error) {
			console.error('Failed to leave game:', error);
		} finally {
			setIsLeaving(false);
		}
	};

	// Function to poll game status
	const pollGameStatus = async (gameId: string, checkCondition: (game: Game) => boolean) => {
		let attempts = 0;
		const maxAttempts = 30;
		while (attempts < maxAttempts) {
			try {
				const latestGame = await Game.fetchById(gameId);
				if (checkCondition(latestGame)) {
					break;
				}
			} catch (error) {
				console.error('Poll attempt failed:', error);
			}
			attempts++;
			await new Promise(resolve => setTimeout(resolve, 1000));
		}
	};

	// Save scroll position
	useEffect(() => {
		const handleScroll = () => {
			const position = window.scrollY;
			setScrollPosition(position);
			sessionStorage.setItem('dashboardScrollPosition', position.toString());
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	// Restore scroll position
	useEffect(() => {
		if (scrollPosition > 0) {
			window.scrollTo(0, scrollPosition);
		}
	}, []);

	// Handle pull-to-refresh
	useEffect(() => {
		let startY = 0;
		let isRefreshing = false;

		const handleTouchStart = (e: TouchEvent) => {
			startY = e.touches[0].pageY;
		};

		const handleTouchMove = (e: TouchEvent) => {
			const y = e.touches[0].pageY;
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

			if (scrollTop <= 0 && y - startY > 50 && !isRefreshing) {
				isRefreshing = true;
				refreshGames();
				setLastRefresh(Date.now());
			}
		};

		window.addEventListener('touchstart', handleTouchStart);
		window.addEventListener('touchmove', handleTouchMove);

		return () => {
			window.removeEventListener('touchstart', handleTouchStart);
			window.removeEventListener('touchmove', handleTouchMove);
		};
	}, [refreshGames]);

	// Refresh games when scrolling up past the top
	useEffect(() => {
		let lastScrollY = window.scrollY;

		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			if (currentScrollY < -50 && currentScrollY < lastScrollY) {
				if (Date.now() - lastRefresh > 30000) {
					refreshGames();
					setLastRefresh(Date.now());
				}
			}
			lastScrollY = currentScrollY;
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [refreshGames, lastRefresh]);

	// Filter games based on the current filter
	const filteredGames = useMemo(() => {
		if (!userGames) return [];
		
		const oneHourAgo = new Date(Date.now() - 7200000);
		
		const fg = userGames
			.filter(game => new Date(game.scheduled_at) > oneHourAgo)
			.sort((a, b) => new Date(a.scheduled_at).getTime() - new Date(b.scheduled_at).getTime());

		return fg;
	}, [userGames]);

	return (
		<div className="flex flex-col min-h-screen bg-gray-100">
			{/* Top Navigation Bar */}
			<TopNavBar showMobileSearch={true} />

			{/* Main Content */}
			<div className="flex-1 container mx-auto mt-4 pb-20 overflow-y-auto hide-scrollbar">
				<div className="md:flex md:gap-8 h-full">
					{/* // Left Column
					<div className="md:w-1/3 lg:w-1/4 hidden md:block h-full px-4 pointer-events-auto">
						<UserProfileCard />
						{userProfile && <UserFriendsCard userId={userProfile.user_id!} />}
					</div> */}

					{/* Right Column */}
					<div className="w-full lg:px-8 md:px-4 pointer-events-auto flex flex-col">
						{/* Show skeleton loader only when loading and no cached data */}
						{isGamesLoading && !gamesCache.userGames && (
							<div className="bg-gray-100 sm:rounded-lg">
								<div className="space-y-6">
									{[1, 2, 3].map((item) => (
										<div key={item} className="block bg-white rounded-lg shadow-sm transition-shadow duration-200">
											{/* Header with host info and game name */}
											<div className="px-4 py-3 sm:px-6 flex items-center border-b border-gray-200">
												<div className="w-12 h-12 rounded-full bg-gray-200 animate-pulse mr-3" />
												<div className="flex-1">
													<div className="h-6 bg-gray-200 rounded w-48 mb-2 animate-pulse" />
													<div className="h-4 bg-gray-200 rounded w-32 animate-pulse" />
												</div>
												<div className="flex items-center gap-2">
													<div className="h-6 w-16 bg-gray-200 rounded-full animate-pulse" />
												</div>
											</div>

											{/* Game image */}
											<div className="relative h-64 w-full overflow-hidden">
												<div className="absolute inset-0 bg-gray-200 animate-pulse" />
											</div>

											{/* Central game details */}
											<div className="px-4 py-4 sm:px-6 space-y-3 sm:space-y-4 border-b border-gray-200">
												<div className="flex flex-col space-y-3">
													{/* Date and Time */}
													<div className="flex items-start gap-3">
														<div className="w-5 h-5 bg-gray-200 rounded-full animate-pulse mt-1" />
														<div className="flex-1">
															<div className="h-5 bg-gray-200 rounded w-40 mb-1 animate-pulse" />
															<div className="h-4 bg-gray-200 rounded w-32 animate-pulse" />
														</div>
													</div>
													{/* Location */}
													<div className="flex items-start gap-3">
														<div className="w-5 h-5 bg-gray-200 rounded-full animate-pulse mt-1" />
														<div className="flex-1">
															<div className="h-5 bg-gray-200 rounded w-48 mb-1 animate-pulse" />
															<div className="h-4 bg-gray-200 rounded w-40 animate-pulse" />
														</div>
													</div>
												</div>
												{/* Description */}
												<div className="px-2 pt-4">
													<div className="h-4 bg-gray-200 rounded w-full mb-2 animate-pulse" />
													<div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse" />
												</div>
											</div>

											{/* Action buttons */}
											<div className="px-4 py-4 sm:px-6 flex items-center justify-between">
												<div className="flex-1 pr-2">
													<div className="w-full h-10 bg-gray-200 rounded-lg animate-pulse" />
												</div>
												<div className="flex-shrink-0 w-24 h-10 bg-gray-200 rounded-lg animate-pulse" />
											</div>
										</div>
									))}
								</div>
							</div>
						)}

						{/* Handle Games List - only show if not loading or has cached data */}
						{(!isGamesLoading || gamesCache.userGames) && filteredGames && filteredGames.length > 0 && (
							<div className="bg-gray-100 sm:rounded-lg">
								<div className="space-y-6">
									<GamesList 
										games={filteredGames} 
										userID={userProfile?.user_id || ''} 
										onJoin={handleJoinGame}
										onLeave={handleLeaveGame}
									/>
								</div>
							</div>
						)}

						{/* Handle No Games Found - only show after backend fetch completes */}
						{!isGamesLoading && !gamesCache.isFetching && filteredGames && filteredGames.length === 0 && (
							<div className="flex flex-col items-center justify-center py-20 px-4">
								<div
									className="flex flex-col items-center justify-center cursor-pointer transition-transform transform hover:scale-105 hover:bg-gray-100 p-4 rounded-xl"
									onClick={() => navigate('/create-game')}
								>
									<svg
										className="w-16 h-16 text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
									</svg>
								</div>
								<h2 className="text-xl font-semibold text-gray-700 mb-2 mt-2">No Games Found</h2>
								<p className="text-gray-500">Create a game to get started</p>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* <BottomNavBar />
			<FloatingActionButtons /> */}
		</div>
	);
};

export default Dashboard;

