import React from 'react';

const UserFriendsCardSkeleton: React.FC = () => {
  return (
    <div className="sm:bg-white mg:bg-gray-50 lg:bg-gray-50 sm:overflow-hidden">
      {/* Header with count skeleton */}
      <div className="flex items-center mb-4">
        <div className="h-6 bg-gray-200 rounded w-20 animate-pulse" />
        <div className="ml-2 h-5 bg-gray-200 rounded-full w-8 animate-pulse" />
      </div>

      {/* Search bar skeleton */}
      <div className="space-y-1 sm:space-y-4">
        <div className="relative py-1">
          <div className="w-full h-9 bg-gray-200 rounded-xl sm:rounded-md animate-pulse" />
        </div>
      </div>

      {/* Friends list skeleton */}
      <div className="space-y-2">
        <ul className="py-2">
          {[1, 2, 3].map((i) => (
            <li key={i} className="space-x-4 space-y-2">
              <div className="flex items-center py-2 rounded-lg w-full min-h-[72px] space-x-4">
                <div className="w-12 h-12 bg-gray-200 rounded-full animate-pulse" />
                <div className="flex-1">
                  <div className="h-5 bg-gray-200 rounded w-3/4 animate-pulse" />
                  <div className="h-4 bg-gray-200 rounded w-1/2 mt-2 animate-pulse" />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserFriendsCardSkeleton;
